import { ButtonBlue, DarkHeading, Input } from "components/common/CommonStyles";

import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLock,
  AiOutlineUser,
} from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "../common/ToastStyle";
import { LoginAPI, getProfile } from "helper/api";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { setUser } from "helper/redux/Slice/UserSlice";

export const Login = () => {
  const dispatch = useDispatch();
  const [showpassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    role: "",
    invitecode: "",
  });
  const inputHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = data;
    const formdata = {
      email,
      password,
    };
    try {
      const res = await LoginAPI(formdata);
      console.log(res);
      dispatch(setUser(res?.data?.user));
      console.log(res?.data?.user);
      const obj = {
        name: res?.data?.user?.name,
        email: res?.data?.user?.email,
        number: res?.data?.user?.phoneNumber,
        _id: res?.data?.user?._id,
      };
      // Cookies.set("user", JSON.stringify(obj));
      Cookies.set("token", res?.data?.token);
      // Cookies.set("user", JSON.stringify(res?.data?.user))
      localStorage.setItem("user2", JSON.stringify(res?.data?.user));
      navigate("/");
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { response } = error;
      console.log(error);
      alert(response?.data?.error);
    }
  };
  const params = useParams();
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const id = url.searchParams.get("id");

  const checklogin = async () => {
    try {
      if (!!id) {
        const response = await getProfile(id);
        // console.log(response)
        // console.log(JSON.stringify(response.data.data))
        // Cookies.set("prashant", "oiuygtfrg")
        // local
        const userprofile = JSON.stringify(response.data.data);
        // debugger
        const obj = {
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          number: response?.data?.data?.phoneNumber,
          _id: response?.data?.data?._id,
        };
        console.log(obj);
        Cookies.set("user", JSON.stringify(obj));
        localStorage.setItem("user2", userprofile);
        dispatch(setUser(response.data.data));
        // navigate("/");
        window.location.replace("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!token) {
      Cookies.set("token", token);
      checklogin();
    }
  }, [token]);
  return (
    <>
      <Toaster />
      <Form onSubmit={handleLogin}>
        <div className="mb-3 mt-3">
          {data.role === 1 && (
            <div className="d-flex align-items-center">
              <MdOutlineQrCodeScanner
                className="input-icon"
                color="#ffa303"
                size={28}
              />
              <Input
                placeholder="Enter College Invite Code"
                name="invitecode"
                className="mb-3 px-5 br-5"
                onChange={(e) => inputHandler(e)}
              ></Input>
            </div>
          )}
          <div className="d-flex align-items-center">
            <AiOutlineUser className="input-icon" color="#ffa303" size={28} />
            <Input
              placeholder="Email or Phone number"
              name="email"
              type="text"
              required
              autoComplete="email"
              className="mb-3 px-5 br-5"
              onChange={(e) => inputHandler(e)}
            ></Input>
          </div>
          <div className="d-flex align-items-center ">
            <AiFillLock className="input-icon" color="#ffa303" size={28} />
            <Input
              placeholder="Password"
              name="password"
              autoComplete="password"
              minLength={8}
              className="mb-3 px-5 br-5 password"
              type={showpassword ? "text" : "password"}
              required
              onChange={(e) => inputHandler(e)}
            ></Input>
            {showpassword ? (
              <AiFillEyeInvisible
                onClick={() => setShowPassword(false)}
                className="password-eye pe-1"
                color="#ffa303"
                size={28}
              />
            ) : (
              <AiFillEye
                onClick={() => setShowPassword(true)}
                className="password-eye pe-1"
                color="#ffa303"
                size={28}
              />
            )}
          </div>
          {/* <DarkHeading className="fs-12 mb-0 inline-block">
            <input type="checkbox" className="me-2 " />
            Remember Me ?{" "}
          </DarkHeading> */}

          <button
            className="w-100  box-shadow text-white py-2 rounded border-none"
            type="submit"
            style={{ backgroundColor: "#521986" }}
          >
            Sign In
            {loading && (
              <Spinner className="mx-2" animation="border" size="sm" />
            )}
          </button>

          {/* <div className="d-flex justify-content-end my-2">
        <ButtonBlue
          color="white"
          className="w-10 h-40 box-shadow px-3"
          onClick={() => setSelect(1)}
        >
          Sign Up
        </ButtonBlue>
      </div> */}
        </div>
      </Form>
    </>
  );
};
