import React from "react";

function BasicSelect({
  index,
  handleChange,
  label,
  name,
  value,
  options,
  required,
  submitAttempted,
}) {
  const isValid = !required || value.trim() !== "";

  return (
    <>
      <div className="d-flex flex-column form-input mb-4 w-100">
        <label for="first_name" className="text-sm ">
          {label} {required && <span className="text-danger">*</span>}
        </label>
        <select
          name={name}
          value={value}
          onChange={(e) => handleChange(index, e)}
          required
          style={{
            border:
              submitAttempted && !isValid
                ? "1.5px solid red"
                : "1.5px solid #602a94",
          }}
        >
          <option value="">Select </option>
          {options?.map((item, index) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>
    </>
  );
}

export default BasicSelect;
