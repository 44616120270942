import React, { useRef, useState, useEffect } from "react";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
// import res1 from "../../assets/image/res1.png";
// import res2 from "../../assets/image/res2.png";
// import res3 from "../../assets/image/res3.png";
// import scrollbar from "../../assets/image/scrollbar.png";
// import progress from "../../assets/image/progress.png";
// import rankbar from "../../assets/image/rankbar.png";
import toggler from "../../assets/image/toggler.png";
import meter from "../../assets/image/meter.png";
import Img from "../../assets/image/resume1.png";
import ResumeTemplate1 from "components/ResumeTemplate/ResumeTemplate1";
import ResumeTemplate2 from "components/ResumeTemplate/ResumeTemplate2";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
// import TypeSlice from "helper/redux/Slice/TypeSlice";
// import CoverletterTemplate from "components/CoverLetterTemplate/CoverTemplate1/CoverletterTemplate";
// import jsPDF from "jspdf";
// import ReactToPrint, { useReactToPrint } from "react-to-print";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
// import { pdfFromReact } from "generate-pdf-from-react-html";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import {
  GettingResumeStrongSummary,
  SetAnalysis,
  SetAnalysisResume,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
// import { IoMdInformationCircleOutline } from "react-icons/io";
import { Modal } from "react-bootstrap";
import AnalysisFeature from "common/AnalysisFeature";
import {
  GettingSummary,
  SetAnalysisCover,
  setCoverFields,
} from "helper/redux/Slice/CoverSlice";
import CT2editable from "components/CoverLetterTemplate/CT2/CT2editable";
import RT3editable from "components/ResumeTemplate/T1/RT1editable";
import RT4editable from "components/ResumeTemplate/T1/RT1editable";
import RT5editable from "components/ResumeTemplate/T3/RT3editable";
import RT6editable from "components/ResumeTemplate/T4/RT4editable";
import RT7editable from "components/ResumeTemplate/T2/RT2editable";
import RT8editable from "components/ResumeTemplate/T5/RT5editable";
import RT9editable from "components/ResumeTemplate/T6/RT6editable";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import {
  setTemplateNo,
  setfinishUpOption,
} from "helper/redux/Slice/UtilsSlice";
import CoverLetterTemplateJson from "contants/CoverLettertemplate";
// import { type } from "@testing-library/user-event/dist/type";
// import html2pdf from "html2pdf.js";

import LoadingComponent from "common/LoadingComponent";
// import html2canvas from "html2canvas";
// import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
// import { jsPDF } from "jspdf";
import {
  ChatGPTAPI,
  GetExperienceCoverLetterById,
  GetFresherCoverLetterById,
  generatePDF,
  GetTemplateNoByCollegeId,
} from "helper/api";
// import { GettingCompleteness, GettingFormatting, GettingNotypo, GettingSummary, GettingWordChoice } from "helper/redux/Slice/CoverSlice";
// import { useParams } from "react-router";
// import RT2non from "components/ResumeTemplate/T2/RT2non";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import Cookies from "js-cookie";

export const Finishup = ({ page, data }) => {
  // const doc = new jsPDF();
  let reportTemplateRef = useRef();

  const container = React.useRef(null);
  const [Template, setTemplate] = useState({});
  const [completeness, setcompleteness] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [adjustments, setAdjustments] = useState(false);
  const [downloads, setDownloads] = useState(false);
  const [analytics, setAnalytics] = useState(true);
  const [color, setColor] = useState(false);
  const [templates, setTemplates] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState(Img);
  const [dropDownMenu, setDropDownMenu] = useState(false);

  const selectedStyle = { backgroundColor: "#521986", color: "white" };

  const percentage = 66;
  function handleDropDownMenu() {
    setAdjustments(false);
    setColor(false);

    setDropDownMenu(!dropDownMenu);
  }

  function handleIsModal(action) {
    if (action == "open") {
      setIsModal(true);
    } else {
      setIsModal(false);
    }
  }

  function handleColor() {
    setDropDownMenu(false);
    setAdjustments(false);
    setColor(!color);
  }
  function handleAdjustments() {
    setDropDownMenu(false);
    setColor(false);
    setAdjustments(!adjustments);
  }

  function handleAnalytics() {
    setDropDownMenu(false);
    setAdjustments(false);
    setColor(false);
    setTemplates(false);
    setAnalytics(true);
  }

  function handleTemplates() {
    setDropDownMenu(false);
    setAdjustments(false);
    setAnalytics(false);
    setColor(false);
    setTemplates(true);
  }

  const UserData = useSelector((item) => item.user.user);
  const types = useSelector((item) => item.types);
  const handleGenerateDOCX = async () => {
    // await HTMLtoDOCX(reportTemplateRef.current)
  };

  const CoverRedux = useSelector((item) => item.cover);

  const Resumeref = useRef();
  const Coverref = useRef();
  const dispatch = useDispatch();

  const ResumeRedux = useSelector((item) => item?.resume);

  useEffect(() => {
    if (types?.doctype === "resume") {
      const ResumeHeight = Resumeref?.current?.clientHeight;
      dispatch(
        SetAnalysisResume({
          type: "length",
          value: Number(
            ResumeHeight / 925 >= 1 ? (ResumeHeight / 925).toFixed(0) : 0
          ),
        })
      );
    } else {
      const coverHeight = Coverref?.current?.clientHeight;
      dispatch(
        SetAnalysisCover({
          type: "length",
          value: Number(
            coverHeight / 925 >= 1 ? (coverHeight / 925).toFixed(0) : 0
          ),
        })
      );
    }
  }, [ResumeRedux?.length, CoverRedux?.finishUp?.length]);

  useEffect(() => {
    if (types?.doctype === "resume") {
      setTemplate(ResumeRedux?.template);
    } else {
      setTemplate(CoverRedux?.template);
    }
  }, []);

  const finishupOption = useSelector((item) => item.utils.finishupOption);

  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }
  const CoverData = useSelector((item) => item.cover);
  const pdfExportComponent = React.useRef(null);
  const [loading, setLoading] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null); // New state to store PDF URL
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handlePdfModalClose = () => setShowPdfModal(false);
  const handlePdfModalShow = () => setShowPdfModal(true);
  const exportPDFWithComponent = async () => {
    try {
      setLoading(true);
      const data = {
        ResumeData,
        Template,
        doctype: types?.doctype,
        CoverData,
      };

      // Call the API to generate the PDF
      const response = await generatePDF(data);
      console.log("response", response);

      if (response.status === 200) {
        // const pdfBlob = await response.blob();
        // // setPdfBlob(pdfBlob);
        // // handlePdfModalShow();
        // // Save the blob as a file using FileSaver.js
        // saveAs(pdfBlob, "document.pdf");

        // const { pdfUrl } = await response.json();
        const { pdfUrl } = await response.data;
        console.log("PDFURL", pdfUrl);
        const fullpdfurl = process.env.REACT_APP_API_URL + pdfUrl;
        console.log("Full PDFURL", fullpdfurl);

        const downloadLink = document.createElement("a");
        downloadLink.href = fullpdfurl;
        downloadLink.download = "document.pdf";
        downloadLink.type = "application/pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        console.log("PDF downloaded successfully");
      } else {
        // Handle error
        const errorMessage = await response.text();
        console.error("Failed to generate PDF,", errorMessage);
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log(types.doctype);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const type = useSelector((item) => item.types);
  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON.parse(localStorage.getItem("user2"));
  } else {
    cookiedata = JSON.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  // Assuming collegeid is available in cookiedata
  useEffect(() => {
    const collegeid = cookiedata.collegeid;
    console.log("College ID:", collegeid);

    const fetchCollegeTemplates = async () => {
      try {
        // Call the API to get template numbers based on the college ID
        const response = await GetTemplateNoByCollegeId(collegeid);

        const collegeData = response.data; // Assuming the response contains the data object
        console.log("College Data:", collegeData);

        // Extract templateNos from the response
        const { templateNos } = collegeData;

        if (templateNos && templateNos.length > 0) {
          // Filter ResumeTemplateJson based on the templateNos from the database
          const matchedTemplates = ResumeTemplateJson.filter((template) =>
            templateNos.includes(Number(template.templateNo))
          );
          console.log("Matched Templates:", matchedTemplates);
          setFilteredTemplates(matchedTemplates);
        } else {
          // If no templateNos are found or empty, show all templates
          setFilteredTemplates(ResumeTemplateJson);
          console.log("No template numbers found, showing all templates");
        }
      } catch (error) {
        console.error("Error fetching college templates:", error);
        // In case of an error, show all templates
        setFilteredTemplates(ResumeTemplateJson);
      }
    };

    // Fetch templates if collegeid exists
    if (collegeid) {
      fetchCollegeTemplates();
    }
  }, [cookiedata.collegeid]);
  return (
    <>
      {/* PDF Modal */}
      {/* <Modal
        show={showPdfModal}
        onHide={handlePdfModalClose}
        size="lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Resume PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {pdfBlob && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
            </Worker>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePdfModalClose}>
            Close
          </Button>
          {pdfBlob && (
            <Button
              variant="primary"
              download="resume.pdf"
              href={URL.createObjectURL(pdfBlob)}
            >
              Download PDF
            </Button>
          )}
        </Modal.Footer>
      </Modal> */}
      {/* <p onClick={() => pdfFromReact(".element-to-print", "My-file", "p", true, false)}>EXPORT.PDF</p> */}

      <div className="create-resume-main w-100  h-100">
        <div className="sub-navbar-main overflow-lg-hidden overflow-auto">
          <div className="sub-navbar-options">
            <div align="center">
              <Button
                variant="primary"
                className="sub-navbar-button"
                style={finishupOption === 1 ? selectedStyle : {}}
                onClick={() => dispatch(setfinishUpOption(1))}
              >
                Analytics
              </Button>
            </div>
            {/* <Col align="center"><Button variant="primary" className="sub-navbar-button" style={color ? selectedStyle : {}} onClick={handleColor}>Color</Button></Col> */}
            {/* <Col align="center"><Button variant="primary" className="sub-navbar-button" style={adjustments ? selectedStyle : {}} onClick={handleAdjustments}>Adjustment</Button></Col> */}
            <div align="center">
              <Button
                variant="primary"
                className="sub-navbar-button"
                style={finishupOption === 2 ? selectedStyle : {}}
                onClick={() => dispatch(setfinishUpOption(2))}
              >
                Templates
              </Button>
            </div>

            <div className="finish-download-dropdown-container">
              {/* <button className="sub-navbar-button" style={dropDownMenu ? selectedStyle : {}} onClick={handleDropDownMenu}>Download</button> */}
              <button
                className="sub-navbar-button cursor-pointer mr-2"
                onClick={exportPDFWithComponent}
              >
                EXPORT.PDF
                {loading && (
                  <div className="loading-spinner-container d-flex mx-2">
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                  </div>
                )}{" "}
              </button>
              {/* Add this line to show the loader */}
              {/* <ReactToPrint
                                trigger={() => {

                                    return <button className="sub-navbar-button cursor-pointer">EXPORT.PDF</button>
                                }}
                                content={() => types?.doctype === "resume" ? Resumeref.current : Coverref.current}
                            /> */}
              {/* <button onClick={() => downloadPdfDocument("divToDownload")}>download</button> */}
              {/* <p onClick={handleGenerateDOCX}>EXPORT.DOCX</p>
                                <p>SAVE TO DRIVE</p> */}
            </div>
          </div>

          <div className="sub-navbar-toggleMenu">
            {/* {color && <div className="color-tray">


                            <span style={{ backgroundColor: "#facd48" }}></span>
                            <span style={{ backgroundColor: "#0462f6" }}></span>
                            <span style={{ backgroundColor: "#335c52" }}></span>
                            <span style={{ backgroundColor: "#ffbebe" }}></span>
                            <span style={{ backgroundColor: "#facd48" }}></span>
                        </div>} */}

            {adjustments && (
              <div className="adjustment-tray">
                {/* <span>
                                    <p>SHOW PHOTOS</p>
                                    <div className="adjustment-tray-toggler"><img src={toggler}></img></div>
                                </span> */}
                <span>
                  <p>SHOW ICONS</p>
                  <div className="adjustment-tray-toggler">
                    <img src={toggler}></img>
                  </div>
                </span>
                <span>
                  <p>LINE HEIGHT :</p>
                  <div className="adjustment-tray-meter">
                    <img src={meter}></img>
                  </div>
                </span>
                <span>
                  <p>FONT SIZE :</p>
                  <div className="adjustment-tray-meter">
                    <img src={meter}></img>
                  </div>
                </span>
                <span>
                  <p>ZOOM :</p>
                  <div className="adjustment-tray-meter">
                    <img src={meter}></img>
                  </div>
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="sub-content-main flex-lg-row flex-column">
          <div className="overflow-auto">
            <div
              className="template-preview-main align-self-start position-relative "
              style={{ width: "600px" }}
            >
              {/* <div className="preview-header">
                            <p className="preview-header-name ">[{UserData?.name}]</p>
                            <p className="preview-header-options">More Options</p>
                        </div> */}
              <div className="">
                {types.doctype === "resume" ? (
                  <div className="position-relative">
                    {/* <PDFExport
                      paperSize="A4"
                      margin={12}
                      fileName={`Resume`}
                      author="KendoReact Team"
                      ref={pdfExportComponent}
                    > */}
                    <div
                      ref={container}
                      className="element-to-print resume-final  rounded"
                      style={{ objectFit: "cover" }}
                    >
                      {/* {ResumeRedux.template === 0 ? (
                          <RT2editable />
                        ) : (
                          <RT3editable />
                        )} */}
                      {ResumeRedux.template === 0 ? (
                        <RT2editable />
                      ) : ResumeRedux.template === 1 ? (
                        <RT4editable />
                      ) : ResumeRedux.template === 2 ? (
                        <RT5editable />
                      ) : ResumeRedux.template === 3 ? (
                        <RT6editable />
                      ) : ResumeRedux.template === 4 ? (
                        <RT7editable />
                      ) : ResumeRedux.template === 5 ? (
                        <RT8editable />
                      ) : ResumeRedux.template === 6 ? (
                        <RT9editable />
                      ) : null}
                    </div>
                    {Resumeref?.current?.clientHeight > 920 && (
                      <span className="page-end-border-low text-center">
                        Page Break
                      </span>
                    )}
                    {Resumeref?.current?.clientHeight > 2 * 925 && (
                      <span className="page-end-border-mid text-end">
                        Page Break
                      </span>
                    )}
                    {Resumeref?.current?.clientHeight > 3 * 925 && (
                      <span className="page-end-border-high text-end">
                        Page Break
                      </span>
                    )}
                    {/* </PDFExport> */}
                  </div>
                ) : (
                  <div className="position-relative">
                    {/* <PDFExport
                      paperSize="A4"
                      margin={12}
                      fileName={`Cover-Letter`}
                      author="KendoReact Team"
                      ref={pdfExportComponent}
                    > */}
                    <div
                      ref={container}
                      style={{ objectFit: "cover", minHeight: "100vh" }}
                      className="bg-white rounded px-3 element-to-print position-relative "
                    >
                      {CoverRedux.template === 1 ? (
                        <CT2editable />
                      ) : CoverRedux.template === 0 ? (
                        <CT1editable />
                      ) : null}
                    </div>
                    {Coverref?.current?.clientHeight > 925 && (
                      <span className="page-end-border-low text-end">
                        Page Break
                      </span>
                    )}
                    {Coverref?.current?.clientHeight > 2 * 925 && (
                      <span className="page-end-border-mid text-end">
                        Page Break
                      </span>
                    )}
                    {Coverref?.current?.clientHeight > 3 * 925 && (
                      <span className="page-end-border-high text-end">
                        Page Break
                      </span>
                    )}
                    {/* </PDFExport> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          {types?.doctype === "cover" ? (
            <></>
          ) : (
            finishupOption === 1 && (
              <div className="analytics-card-container d-flex col-12 flex-wrap gap-3 h-50">
                {!ResumeRedux?.isAnalysisData && !CoverRedux?.isAnalysisData ? (
                  <AnalysisFeature />
                ) : (
                  <LoadingComponent width="80%" />
                )}
              </div>
            )
          )}

          {finishupOption === 2 && (
            <div
              className="template-container px-2 col-12 col-lg-3 m-0"
              style={{ height: "fit-content" }}
            >
              <div className="template-header">
                <span>TEMPLATES</span>
                <span>X</span>
              </div>
              <div className="d-flex flex-column gap-4    ">
                {types.doctype === "resume" ? (
                  <div className="d-flex flex-column gap-4">
                    {/* {ResumeTemplateJson?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="border rounded"
                          onClick={() => {
                            dispatch(setTemplateNo(index));
                            dispatch(
                              setCreateResume({
                                type: "template",
                                value: index,
                              })
                            );
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })} */}
                    {filteredTemplates.map((item, index) => (
                      <div
                        key={index}
                        className="border rounded"
                        onClick={() => {
                          dispatch(setTemplateNo(item.templateNo));
                          dispatch(
                            setCreateResume({
                              type: "template",
                              value: item.templateNo,
                            })
                          );
                        }}
                      >
                        <item.template />
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {CoverLetterTemplateJson?.map((item, index) => {
                      return (
                        <div
                          className="border rounded mb-3"
                          key={index}
                          onClick={() => {
                            dispatch(setTemplateNo(index));
                            dispatch(
                              setCoverFields({ type: "template", value: index })
                            );
                          }}
                        >
                          <item.template />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}

          {/* <div className="rank-sidebar">
                        <p>Rank</p>
                        <p>10/100</p>
                        <div className="image-container"><img src={rankbar}></img></div>

                        <p>Expand</p>
                    </div> */}
        </div>
      </div>
    </>
  );
};
