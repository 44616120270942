import React, { useRef, useState, useEffect } from "react";
import sidelogo from "../../assets/image/sidelogo.mp4";
import { SidePanelData } from "contants/SidepanelData";
import { NavLink } from "react-router-dom";
import coin from "../../assets/image/dollar.png";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";
import {
  GettingAiCredits,
  setSidebarExpand,
} from "helper/redux/Slice/UtilsSlice";

const ResponsiveSIdebar = () => {
  const UtilsData = useSelector((item) => item?.utils);
  const sidebarExpand = useSelector((item) => item.utils.sidebarExpand);

  const [sidebarOpen, setsidebarOpen] = useState(true);
  const handelSidebarOpen = () => {
    sidebarOpen ? setsidebarOpen(false) : setsidebarOpen(true);
  };

  const dispatch = useDispatch();
  const handleSidebarExpand = () => {
    if (sidebarExpand) {
      dispatch(setSidebarExpand(false));
    } else {
      dispatch(setSidebarExpand(true));
    }
  };
  return (
    <>
      <nav
        className={`  ${
          sidebarOpen ? "w-70" : "w-24"
        }  nav-wrapper position-relative`}
      >
        <div
          className="w-100 sidebar-wrapper   position-relative"
          // style={{ background: "#602a94", borderRadius: "0 6px 0 0" }}
        >
          <div className="resume-steps-nav">
            <div className="d-flex  align-items-center flex-column gap-y-7">
              {SidePanelData.map((item) => (
                <>
                  <NavLink
                    to={item.path}
                    className="Common-sidebar-progress d-flex gap-4 w-100 align-items-center"
                    key={item.id}
                  >
                    <div
                      className={`Common-sidebar-circles d-flex justify-content-center align-items-center ${
                        sidebarExpand && `justify-content-center`
                      }`}
                    >
                      {item.icon}
                    </div>
                    {sidebarOpen ? (
                      <h5 className="font-semibold activeStepName">
                        {item.name}
                      </h5>
                    ) : (
                      ""
                    )}
                  </NavLink>
                  {item?.name === "My Profile" && (
                    <hr className="border  border-light" />
                  )}
                </>
              ))}
              <div className="sidebar-Pointsdropdown-container">
                <button
                  className="sub-navbar-button"
                  // onClick={handleShow}
                  // style={{ borderRadius: dropDownMenu ? "10px 10px 0 0" : "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img alt="coin" src={coin} />
                      AI Credits
                    </div>
                    <span style={{ fontSize: "0.9rem" }}>
                      {UtilsData?.AICreditsLoadingState ? (
                        <Spinner size="sm" style={{ color: "indigo" }} />
                      ) : (
                        UtilsData?.AICredits
                      )}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ResponsiveSIdebar;
