import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ChatGPTAPI,
  GetAiResumeById,
  GetAiResumeId,
  GetAutomatedResumeById,
  GetTrailResumeById,
} from "helper/api";
import { useSelector } from "react-redux";

export const GetTrailResumeEditData = createAsyncThunk(
  "resume/GetTrailResumeEditData",
  async (id) => {
    try {
      const response = await GetTrailResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAiResumeEditData = createAsyncThunk(
  "resume/GetAiResumeEditData",
  async (id) => {
    try {
      const response = await GetAiResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAutomatedResumeEditData = createAsyncThunk(
  "resume/GetAutomatedResumeEditData",
  async (id) => {
    try {
      const response = await GetAutomatedResumeById(id); // call your async API function
      console.log(response);
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAiResponseData = createAsyncThunk(
  "resume/GetAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // console.log(response);
      // const res = await Promise.resolve();
      return response.data.text; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetExperienceAiResponseData = createAsyncThunk(
  "resume/GetExperienceAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // console.log(response);
      // const res = await Promise.resolve();
      return response.data; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetAchievementAiResponseData = createAsyncThunk(
  "resume/GetAchievementAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // console.log(response);
      // const res = await Promise.resolve();
      return response.data; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetActivityAiResponseData = createAsyncThunk(
  "resume/GetActivityAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // console.log(response);
      // const res = await Promise.resolve();
      return response.data; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetProjectAiResponseData = createAsyncThunk(
  "resume/GetProjectAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // console.log(response);
      // const res = await Promise.resolve();
      return response.data; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GetSummaryAiResponseData = createAsyncThunk(
  "resume/GetSummaryAiResponseData",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      // const res = await Promise.resolve();
      return response.data; // return the data from the API response
      // return res; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

export const GettingResumeStrongSummary = createAsyncThunk(
  "resume/GettingResumeStrongSummary",
  async (payload) => {
    console.log(payload);
    try {
      const response = await ChatGPTAPI(
        payload?.AiCredit,
        payload?.ResumeStrongSummary
      ); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

const initialState = {
  name: "",
  template: "",
  resumeId: "",
  color: "",
  type: "",
  profession: "",
  jobProfile: "",
  desiredCompany: "",
  jobDescription: "",
  jobSpecificToggle: false,
  EditData: false,
  contact: {
    picture: "",
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    title: "",
    city: "",
    address: "",
    pincode: "",
    country: "",
  },
  SkillChatGpt: [],
  experiences: [],
  internships: [],
  researchPapers: [],
  achievements: [],
  languagesKnown: [],
  links: [],
  projects: [],
  education: [],
  certification: [],
  involvement: [],
  hobby: [],
  skills: [],
  summary: "",
  completeness: 0,
  formatting: 0,
  wordChoice: 0,
  clearContactInfo: 0,
  measurableResult: 0,
  strongSummary: 0,
  atsCompliance: 0,
  noTypos: 0,
  length: 0,
  rank: 0,
  optimization: 0,
  isResumeEdit: false,
  isAnalysisData: false,
  isLoadingAiData: false,
  pendingRequests: 0,
  completedCalls: 0,
  selectedTemplateComponent: null, // This will hold the React component for the selected template
};

let ResumeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    setIsLoadingAiData(state, action) {
      state.isLoadingAiData = action.payload;
    },
    setPendingRequests(state, action) {
      state.pendingRequests = action.payload;
    },
    resetCompletedCalls: (state) => {
      state.completedCalls = 0; // Reset completed calls counter
    },
    incrementPendingRequests(state) {
      state.pendingRequests += 1;
      state.isLoadingAiData = state.pendingRequests > 0;
    },
    decrementPendingRequests(state) {
      state.pendingRequests -= 1;
      state.isLoadingAiData = state.pendingRequests > 0;
    },
    setCreateResume(state, action) {
      state[action.payload.type] = action.payload.value;
    },
    setTemplate(state, action) {
      state.selectedTemplateComponent = action.payload.component; // Set the selected template component
    },
    setContactRedux: (state, action) => {
      state.contact = action.payload;
    },
    setJobSpecificToggleRedux: (state, action) => {
      state.jobSpecificToggle = action?.payload.jobSpecificToggle
      state.jobDescription = action.payload.jobDescription
    },
    UpdateContactRedux: (state, action) => {
      state.contact = {
        ...state.contact,
        [action.payload.type]: action.payload.value,
      };
    },
    // experiences
    setExperienceFields(state, action) {
      // state.experiences.push(action.payload);
      state.experiences = action.payload;
    },
    DeleteExperienceField(state, action) {
      state.experiences = state?.experiences?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateExperienceField(state, action) {
      let index = action?.payload?.index;
      state.experiences[index] = action.payload;
    },

    // Internships
    setInternshipFields(state, action) {
      state.internships.push(action.payload);
    },
    DeleteInternshipField(state, action) {
      state.internships = state?.internships?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateInternshipField(state, action) {
      let index = action?.payload?.index;
      state.internships[index] = action.payload;
    },

    // Project
    setProjectFields(state, action) {
      state.projects = action.payload;
    },
    DeleteProjectFields(state, action) {
      state.projects = state?.projects?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateProjectFields(state, action) {
      let index = action?.payload?.index;
      state.projects[index] = action.payload;
    },

    // Reasearch Paper
    setResearchPapersFields(state, action) {
      state.researchPapers = action.payload;
    },
    DeleteResearchPapersFields(state, action) {
      state.researchPapers = state?.researchPapers?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateResearchPapersFields(state, action) {
      let index = action?.payload?.index;
      state.researchPapers[index] = action.payload;
    },

    // Education
    setEducationFields(state, action) {
      state.education = action.payload;
    },
    DeleteEducationFields(state, action) {
      state.education = state?.education?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateEducationFields(state, action) {
      let index = action?.payload?.index;
      state.education[index] = action.payload;
    },

    // Certificate
    setCertificateFields(state, action) {
      console.log(action);
      state.certification = action.payload;
    },
    DeleteCertificateFields(state, action) {
      state.certification = state?.certification?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCertificateFields(state, action) {
      let index = action?.payload?.index;
      state.certification[index] = action.payload;
    },

    // Involvement
    setInvolvementFields(state, action) {
      state.involvement = action.payload;
    },
    DeleteInvolvementFields(state, action) {
      state.involvement = state?.involvement?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateInvolvementFields(state, action) {
      let index = action?.payload?.index;
      state.involvement[index] = action.payload;
    },

    // Hobbies
    setHobbyFields(state, action) {
      state.hobby = action.payload;
    },
    DeleteHobbyFields(state, action) {
      state.hobby = state?.hobby?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateHobbyFields(state, action) {
      let index = action?.payload?.index;
      state.hobby[index] = action.payload;
    },

    // achievements
    setAchievementsFields(state, action) {
      state.achievements = action.payload;
    },
    DeleteAchievementsFields(state, action) {
      state.achievements = state?.achievements?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateAchievementsFields(state, action) {
      let index = action?.payload?.index;
      state.achievements[index] = action.payload;
    },

    // Languages
    setlanguagesKnown(state, action) {
      state.languagesKnown = action.payload;
    },
    DeletelanguagesKnown(state, action) {
      state.languagesKnown = state?.languagesKnown?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdatelanguagesKnown(state, action) {
      let index = action?.payload?.index;
      state.languagesKnown[index] = action.payload;
    },

    // Links
    setAllLinks(state, action) {
      state.links = action.payload;
    },
    DeleteAllLinks(state, action) {
      state.links = state?.links?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateAllLinks(state, action) {
      let index = action?.payload?.index;
      state.links[index] = action.payload;
    },
    // skills
    setSkillFields(state, action) {
      state.skills = action.payload;
    },
    DeleteSkillFields(state, action) {
      state.skills = state?.skills?.filter(
        (item, index) => index !== action.payload
      );
    },

    //jobspecific
    setJobSpecificFields: (state, action) => {
      const {
        jobProfile,
        profession,
        desiredCompany,
        jobDescription,
        jobDescriptionToggle,
      } = action.payload;
      state.jobProfile = jobProfile;
      state.profession = profession;
      state.desiredCompany = desiredCompany;
      state.jobDescription = jobDescription;
      state.jobDescriptionToggle = jobDescriptionToggle;
    },
    //summary
    setSummaryFields(state, action) {
      state.summary = action.payload;
    },
    setIsResumeEdit(state, action) {
      state.isResumeEdit = action.payload;
    },
    setSkillChatGpt(state, action) {
      state.SkillChatGpt = action.payload;
    },
    SetAnalysisResume: (state, action) => {
      if (action.payload?.type === "length") {
        if (action.payload.value === 0) {
          state.length = 100;
        } else if (action.payload.value === 1) {
          state.length = 75;
        } else if (action.payload?.value === 2) {
          state.length = 50;
        } else if (action.payload.value === 3) {
          state.length = 25;
        }
      } else {
        state[action.payload.type] = action.payload.value;
      }
    },
    ResetResumeTemplate: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(GetTrailResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetTrailResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        console.log(action.payload);
        const {
          contact,
          skills,
          template,
          atsCompliance,
          clearContactInfo,
          completeness,
          optimization,
          strongSummary,
          summary,
          profession,
          desiredCompany,
          jobProfile,
          hobby,
          education,
          internships,
          experiences,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
          researchPapers,
        } = action?.payload?.data;
        state.contact = contact;
        state.skills = skills;
        state.profession = profession;
        state.jobProfile = jobProfile;
        state.desiredCompany = desiredCompany;
        state.summary = summary;
        state.education = education;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state.achievements = achievements;
        state.hobby = hobby;
        state.experiences = experiences;
        state.internships = internships;
        state.projects = projects;
        state.researchPapers = researchPapers;
        state.involvement = involvement;
        state.certification = certification;
        state.template = Number(template);
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
        // state = { ...state, content, from, to, targetJob, education, experiences, certification, projects, involvement }
      })
      .addCase(GetTrailResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })

      .addCase(GetAiResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetAiResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        const {
          contact,
          skills,
          atsCompliance,
          completeness,
          clearContactInfo,
          optimization,
          strongSummary,
          template,
          profession,
          jobProfile,
          desiredCompany,
          summary,
          hobby,
          education,
          experiences,
          internships,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
          researchPapers,
        } = action?.payload?.data;
        state.contact = contact;
        state.skills = skills;
        state.summary = summary;
        state.profession = profession;
        state.jobProfile = jobProfile;
        state.achievements = achievements;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state = desiredCompany;
        state.hobby = hobby;
        state.education = education;
        state.experiences = experiences;
        state.internships = internships;
        state.projects = projects;
        state.researchPapers = researchPapers;
        state.involvement = involvement;
        state.certification = certification;
        state.template = Number(template);
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
        // state = { ...state, content, from, to, targetJob, education, experiences, certification, projects, involvement }
      })
      .addCase(GetAiResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })
      .addCase(GetAutomatedResumeEditData?.pending, (state) => {
        state.EditData = true;
      })
      .addCase(GetAutomatedResumeEditData?.fulfilled, (state, action) => {
        state.EditData = false;
        let {
          contact,
          skills,
          template,
          profession,
          atsCompliance,
          clearContactInfo,
          completeness,
          optimization,
          strongSummary,
          jobProfile,
          desiredCompany,
          summary,
          hobby,
          education,
          experiences,
          certification,
          projects,
          involvement,
          achievements,
          languagesKnown,
          links,
        } = action?.payload?.data;

        state.contact = contact;
        state.skills = skills;
        state.profession = profession;
        state.jobProfile = jobProfile;
        // state = desiredCompany
        state.hobby = hobby?.length === 0 ? [] : hobby;
        state.education = education?.length === 0 ? [] : education;
        state.experiences = experiences;
        state.projects = projects;
        state.achievements = achievements;
        state.languagesKnown = languagesKnown;
        state.links = links;
        state.involvement = involvement;
        state.certification = certification;
        state.template = template;
        state.optimization = optimization;
        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.atsCompliance = atsCompliance;
        state.clearContactInfo = clearContactInfo;
      })
      .addCase(GetAutomatedResumeEditData?.rejected, (state) => {
        state.EditData = false;
      })
      .addCase(GettingResumeStrongSummary?.pending, (state, action) => {
        state.isAnalysisData = true;
      })
      .addCase(GettingResumeStrongSummary?.fulfilled, (state, action) => {
        state.isAnalysisData = false;
        const match = action.payload?.text?.match(/(\d+)/);
        console.log(action.payload);
        state.strongSummary = match[0];
      })
      .addCase(GettingResumeStrongSummary?.rejected, (state, action) => {
        state.isAnalysisData = false;
      })
      .addCase(GetAiResponseData?.pending, (state, action) => {
        state.isAnalysisData = true;
      })
      .addCase(GetAiResponseData?.fulfilled, (state, action) => {
        console.log(action.payload);
        // function correctAndParseJSON(inputString) {
        //   // Step 1: Replace single quotes around keys and string values with double quotes
        //   let correctedString = inputString.replace(
        //     /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
        //     '"$2":'
        //   );

        //   // Step 2: Replace single quotes used within arrays or for values to double quotes
        //   correctedString = correctedString.replace(/:\s*'([^']+)'/g, ': "$1"');

        //   // Step 3: Ensure no trailing commas exist at the end of arrays or objects
        //   correctedString = correctedString.replace(/,\s*([\]}])/g, "$1");

        //   // Optional: Minimize issues with unescaped characters by escaping necessary characters
        //   correctedString = correctedString.replace(/\\(['"])/g, "\\$1");

        //   // Step 4: Parse the JSON
        //   try {
        //     const jsonObject = JSON.parse(correctedString);
        //     console.log("Successfully parsed JSON:", jsonObject);
        //     return jsonObject;
        //   } catch (error) {
        //     console.error("Failed to parse JSON:", error);
        //     return null; // or handle the error as needed
        //   }
        // }

        // const resultString = action.payload
        //   .replace(/([\{\s,])(\w+)(:)/g, '$1"$2"$3')
        //   .replace(/'/g, '"');
        // console.log("Result String", resultString);

        // const resultJson = correctAndParseJSON(resultString);

        // if (resultJson && resultJson.length > 0) {
        //   const updatedExperiences = state.experiences.map((exp) => {
        //     const matchingDescription = resultJson[0]?.descriptionArr?.find(
        //       (parsedExp) =>
        //         parsedExp.role === exp.role && parsedExp.company === exp.company
        //     );
        //     return matchingDescription
        //       ? {
        //           ...exp,
        //           description: matchingDescription.description,
        //         }
        //       : exp;
        //   });

        //   const updatedProject = state.projects.map((project) => {
        //     const matchingDescription = resultJson[1]?.descriptionArr?.find(
        //       (parsedProject) =>
        //         parsedProject.title === project.title &&
        //         parsedProject.company === project.company
        //     );
        //     return matchingDescription
        //       ? {
        //           ...project,
        //           description: matchingDescription.description,
        //         }
        //       : project;
        //   });

        //   state.experiences = updatedExperiences;
        //   state.projects = updatedProject;
        //   // state.summary = resultJson[2].descriptionArr; // Check if needed
        // } else {
        //   console.error("No valid JSON data available to update state.");
        // }
        // state.isAnalysisData = false;
      })
      .addCase(GetAiResponseData?.rejected, (state, action) => {
        state.isAnalysisData = false;
      })

      .addCase(GetExperienceAiResponseData?.pending, (state, action) => {
        state.isLoadingAiData = true;
      })
      .addCase(GetExperienceAiResponseData?.fulfilled, (state, action) => {
        function safeParseJSON(jsonString) {
          try {
            const correctedJSONString = jsonString.replace(
              /,\s*([}\]])/g,
              "$1"
            ); // Remove trailing commas
            return JSON.parse(correctedJSONString);
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            return null;
          }
        }

        const gptResponse = safeParseJSON(action.payload);
        console.log(gptResponse);

        if (gptResponse && gptResponse.length) {
          let responseIndex = 0; // Start with the first description in the response

          state.experiences.forEach((experience, index) => {
            // Proceed only if this experience lacks a description and there is a pending GPT response to use
            if (
              (!experience.description || !experience.description.length) &&
              responseIndex < gptResponse.length
            ) {
              console.log(
                `Updating experience at index ${index} with response from index ${responseIndex}`
              );
              experience.description = gptResponse[responseIndex].description; // Assign the description from GPT
              responseIndex++; // Move to the next item in the GPT response
            }
          });
        }
        state.isLoadingAiData = false;
      })
      .addCase(GetExperienceAiResponseData?.rejected, (state, action) => {
        state.isLoadingAiData = false;
      })
      .addCase(GetProjectAiResponseData?.pending, (state, action) => {
        state.isLoadingAiData = true;
      })
      .addCase(GetProjectAiResponseData?.fulfilled, (state, action) => {
        function safeParseJSON(jsonString) {
          try {
            const correctedJSONString = jsonString.replace(
              /,\s*([}\]])/g,
              "$1"
            ); // Remove trailing commas
            return JSON.parse(correctedJSONString);
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            return null;
          }
        }

        const gptResponse = safeParseJSON(action.payload);
        console.log("project", gptResponse);

        if (gptResponse && gptResponse.length) {
          let responseIndex = 0; // Start with the first description in the response

          state.projects.forEach((project, index) => {
            // Check if the project lacks a description and there is a pending GPT response to use
            if (
              (!project.description || !project.description.length) &&
              responseIndex < gptResponse.length
            ) {
              console.log(
                `Updating project at index ${index} with response from index ${responseIndex}`
              );
              project.description = gptResponse[responseIndex].description; // Assign the description from GPT
              responseIndex++; // Move to the next item in the GPT response
            }
          });
        }
        state.isLoadingAiData = false;
      })
      .addCase(GetProjectAiResponseData?.rejected, (state, action) => {
        state.isLoadingAiData = false;
      })
      .addCase(GetAchievementAiResponseData?.pending, (state, action) => {
        state.isLoadingAiData = true;
      })
      .addCase(GetAchievementAiResponseData?.fulfilled, (state, action) => {
        function safeParseJSON(jsonString) {
          try {
            const correctedJSONString = jsonString.replace(
              /,\s*([}\]])/g,
              "$1"
            ); // Remove trailing commas
            return JSON.parse(correctedJSONString);
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            return null;
          }
        }

        const gptResponse = safeParseJSON(action.payload);
        console.log("achievement", gptResponse);
        gptResponse?.map((item, index) => {
          state.achievements[index].description = item.description;
        });
        state.isLoadingAiData = false;
      })
      .addCase(GetAchievementAiResponseData?.rejected, (state, action) => {
        state.isLoadingAiData = false;
      })
      .addCase(GetActivityAiResponseData?.pending, (state, action) => {
        state.isLoadingAiData = true;
      })
      .addCase(GetActivityAiResponseData?.fulfilled, (state, action) => {
        function safeParseJSON(jsonString) {
          try {
            const correctedJSONString = jsonString.replace(
              /,\s*([}\]])/g,
              "$1"
            ); // Remove trailing commas
            return JSON.parse(correctedJSONString);
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            return null;
          }
        }

        const gptResponse = safeParseJSON(action.payload);
        console.log("activity", gptResponse);

        if (gptResponse && gptResponse.length) {
          let responseIndex = 0; // Start with the first description in the response

          state.involvement.forEach((activity, index) => {
            // Check if the activity lacks a description and there is a pending GPT response to use
            if (
              (!activity.description || !activity.description.length) &&
              responseIndex < gptResponse.length
            ) {
              console.log(
                `Updating activity at index ${index} with response from index ${responseIndex}`
              );
              activity.description = gptResponse[responseIndex].description; // Assign the description from GPT
              responseIndex++; // Move to the next item in the GPT response
            }
          });
        }
        state.isLoadingAiData = false; // Restore state to normal after processing
      })
      .addCase(GetActivityAiResponseData?.rejected, (state, action) => {
        state.isLoadingAiData = false;
      })
      .addCase(GetSummaryAiResponseData?.pending, (state, action) => {
        state.isLoadingAiData = true;
      })
      .addCase(GetSummaryAiResponseData?.fulfilled, (state, action) => {
        state.summary = action.payload.trim();
        state.isLoadingAiData = false;
      })
      .addCase(GetSummaryAiResponseData?.rejected, (state, action) => {
        state.isLoadingAiData = false;
      });
  },
});

export const {
  setCreateResume,
  SetAnalysisResume,
  setJobSpecificFields,
  setSkillChatGpt,
  ResetResumeTemplate,
  UpdateContactRedux,
  setContactRedux,
  setExperienceFields,
  DeleteExperienceField,
  UpdateExperienceField,
  setProjectFields,
  DeleteProjectFields,
  UpdateProjectFields,
  setEducationFields,
  DeleteEducationFields,
  UpdateEducationFields,
  setCertificateFields,
  DeleteCertificateFields,
  UpdateCertificateFields,
  setInvolvementFields,
  DeleteInvolvementFields,
  UpdateInvolvementFields,
  setHobbyFields,
  DeleteHobbyFields,
  UpdateHobbyFields,
  setSummaryFields,
  DeleteAchievementsFields,
  setSkillFields,
  UpdateSkillFields,
  UpdateAchievementsFields,
  resetCompletedCalls,
  setAchievementsFields,
  setlanguagesKnown,
  DeletelanguagesKnown,
  DeleteSkillFields,
  UpdatelanguagesKnown,
  setIsResumeEdit,
  setAllLinks,
  DeleteAllLinks,
  UpdateAllLinks,
  setInternshipFields,
  DeleteInternshipField,
  UpdateInternshipField,
  setResearchPapersFields,
  UpdateResearchPapersFields,
  setJobSpecificToggleRedux,
  DeleteResearchPapersFields,
  setTemplate,
  setIsLoadingAiData,
  setPendingRequests,
  incrementPendingRequests,
  decrementPendingRequests,
} = ResumeSlice.actions;
export default ResumeSlice.reducer;
