import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import moments from "moments";
import moment from "moment/moment";
import { ChatGPTAPI } from "helper/api";
import {
  GetAiResponseData,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline, MdOutlinePhotoCameraFront } from "react-icons/md";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import SummarySection from "./SummarySection";
import { RiDeleteBin5Line } from "react-icons/ri";
import FinishExperience from "./FinishExperience";
import FinishProject from "./FinishProject";
import FinishActivity from "./FinishActivity";
import FinishAchievement from "./FinishAchievement";

function FinishExpandedData() {
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [loadingSkills, setLoadingSkills] = useState(false);
  const experinceReduxData = useSelector((item) => item?.resume.experiences);
  const [experienceEditIndex, setexperienceEditIndex] = useState({
    experienceData: null,
    index: null,
  });

  const [editingKey, setEditingKey] = useState(null); // Track which AI box is being edited (e.g., "experience-0" or "project-1")
  const [acceptedBoxes, setAcceptedBoxes] = useState([]); // Track which AI boxes have been accepted
  const [editedDescription, setEditedDescription] = useState(""); // Store the edited description

  const handleEdit = (key, description) => {
    setEditingKey(key); // Set the unique key of the AI box being edited
    setEditedDescription(description.join("\n")); // Convert the description array to a string for the textarea
  };
  const handleAccept = (key) => {
    setAcceptedBoxes((prev) => [...prev, key]); // Add the unique key to the accepted list
    setEditingKey(null); // Reset editing state
  };
  const handleSave = (key) => {
    // Update the description in the Redux state or your data source
    // For now, we'll just log the updated description
    console.log("Updated Description for", key, ":", editedDescription);
    setEditingKey(null); // Reset editing state
  };

  const handleExperienceEditIndex = (item, index) => {
    setexperienceEditIndex({ experienceData: item, index: index });
  };
  const dispatch = useDispatch();

  //   const createPrompt = (experiences, projects) => {
  //     const experienceDescriptions = experiences.map((exp, index) =>
  //         `${index + 1}. Job Type: ${exp.jobtype}, Role: ${exp.role}, Company: ${exp.company}, Location: ${exp.location}, Start: ${exp.start}, End: ${exp.end || "Present"}, Total Experience: ${exp.totalexperience}, Description: ${exp.description}`
  //     ).join("\n");

  //     const projectDescriptions = projects.map((proj, index) =>
  //         `${index + 1}. Title: ${proj.title}, Company: ${proj.company}, AI Description: ${proj.aidescription}, Start: ${proj.start}, End: ${proj.end || "Present"}, Description: ${proj.description}`
  //     ).join("\n");

  //     return `Please provide concise and professional descriptions based on the information below. Each section should be clearly separated:

  // Experiences:
  // ${experienceDescriptions}

  // Projects:
  // ${projectDescriptions}

  // Summary:
  // Based on the provided experiences and projects, write a professional summary that encapsulates the skills, experiences, and suitability for roles in the industry.`;
  // };

  const handleEditResumeStep = (step) => {
    dispatch(setResumeStep(step));
  };
  // its value will be set to true , when user press edit button , if user than went to that page and save& next then its value will be set to false
  const handleIsResumeEdit = () => {
    dispatch(setIsResumeEdit(true));
  };

  return (
    <>
      <div className="d-flex flex-column gap-4 mb-5">
        <div className="primary-border rounded p-3">
          <h2 className="primary-text  text-3xl font-semibold d-flex justify-content-between">
            Contact
            <div>
              {/* <FaRegEdit
                className="primary-icon me-2 cursor-pointer"
                onClick={() => {
                  handleIsResumeEdit();
                  handleEditResumeStep(1);
                }}
              /> */}
              <button
                onClick={() => {
                  handleIsResumeEdit();
                  handleEditResumeStep(1);
                }}
                className="primary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center text-sm"
              >
                Edit Contact
              </button>
              {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
            </div>
          </h2>
          <div className="mt-3 d-flex gap-5">
            <div className=" ">
              <h1 className="text-2xl font-bold primary-text">
                Name :{" "}
                {`${ResumeReduxData?.contact?.title} ${ResumeReduxData?.contact?.firstName} ${ResumeReduxData?.contact?.lastName}`}
              </h1>
              <h6 className="text-lg font-semibold">
                Phone :{" "}
                {!!ResumeReduxData?.contact?.number
                  ? ResumeReduxData?.contact?.number
                  : ""}
              </h6>
              <h6 className="text-lg font-semibold">
                Email :{" "}
                {!!ResumeReduxData?.contact?.email
                  ? ResumeReduxData?.contact?.email
                  : ""}
              </h6>
              <h6 className="text-lg font-semibold">
                City :{" "}
                {!!ResumeReduxData?.contact?.city
                  ? ResumeReduxData?.contact?.city
                  : ""}
              </h6>
              <h6 className="text-lg font-semibold">
                Country :{" "}
                {!!ResumeReduxData?.contact?.country
                  ? ResumeReduxData?.contact?.country
                  : ""}
              </h6>
            </div>
            <div>
              {!!ResumeReduxData?.contact?.picture ? (
                typeof ResumeReduxData?.contact?.picture === "object" ? (
                  <div>
                    <img
                      src={URL.createObjectURL(
                        ResumeReduxData?.contact?.picture
                      )}
                      alt=""
                      className="contact-picture rounded"
                    />
                  </div>
                ) : (
                  <div className="d-flex">
                    <img
                      src={ResumeReduxData?.contact?.picture}
                      alt=""
                      className="contact-picture rounded"
                    />
                  </div>
                )
              ) : (
                <div
                  className="contact-picture p-4 rounded"
                  style={{ background: "#ebebeb" }}
                >
                  <MdOutlinePhotoCameraFront
                    fontSize={46}
                    className="primary-text"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <SummarySection />
        <FinishExperience />
        {ResumeReduxData?.education?.length > 0 && (
          <div className="primary-border rounded p-3">
            <h2 className="primary-text  text-3xl font-semibold d-flex justify-content-between">
              Education
              <div>
                <button
                  onClick={() => {
                    handleIsResumeEdit();
                    handleEditResumeStep(3);
                  }}
                  className="primary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center text-sm"
                >
                  Add/Edit Eduation
                </button>
                {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
              </div>
              {/* <div>
                <FaRegEdit
                  className="primary-icon me-2 cursor-pointer"
                  onClick={() => {
                    handleIsResumeEdit();
                    handleEditResumeStep(3);
                  }}
                />
              </div> */}
              {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
            </h2>

            {ResumeReduxData?.education?.map((item, index) => {
              return (
                <div className="mt-3" key={index}>
                  <h1 className="text-2xl font-semibold">
                    {item?.degree}
                    <small className="ms-2 text-sm">
                      ,{item?.specialization}
                    </small>
                  </h1>
                  <h6>{item?.institute} </h6>
                  <p>
                    {!!item?.academicScore &&
                    item?.academicScore === "Percentage"
                      ? `${item?.academicScore} - ${item?.percentage}`
                      : `${item?.academicScore} - ${item?.gpa}/${item?.gpaOutOf}`}
                  </p>

                  <p>{moment(item?.date).format("MMM-YYYY")}</p>
                  <ul></ul>
                </div>
              );
            })}
          </div>
        )}

        <FinishProject />
        <FinishActivity />

        {ResumeReduxData?.skills?.length > 0 && (
          <div className="primary-border rounded p-3">
            <h2 className="primary-text  text-3xl font-semibold d-flex justify-content-between">
              Skills
              <div>
                <button
                  onClick={() => {
                    handleIsResumeEdit();
                    handleEditResumeStep(6);
                  }}
                  className="primary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center text-sm"
                >
                  Add/Edit Skills
                </button>
                {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
              </div>
              {/* <div>
                <FaRegEdit
                  className="primary-icon me-2 cursor-pointer"
                  onClick={() => {
                    handleIsResumeEdit();
                    handleEditResumeStep(6);
                  }}
                />
              </div> */}
              {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
            </h2>
            <div className="mt-3">
              <ul>
                {ResumeReduxData?.skills?.map((item, index) => {
                  return (
                    <li className="" key={index}>
                      <span className="me-1">•</span>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}

        <div className="primary-border rounded p-3">
          <h2 className="primary-text  text-3xl font-semibold d-flex justify-content-between">
            Others
            <div>
              <FaRegEdit
                className="primary-icon me-2 cursor-pointer"
                onClick={() => {
                  handleIsResumeEdit();
                  handleEditResumeStep(7);
                }}
              />
              {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
            </div>
          </h2>
          {ResumeReduxData?.certification?.length > 0 && (
            <div className="mt-3">
              <h3 className="text-2xl font-bold">Certificates</h3>

              <div>
                {ResumeReduxData?.certification?.map((item, index) => {
                  return (
                    <div>
                      <h5>{item?.name}</h5>
                      <p>{item?.org}</p>
                      <p>{moment(item?.date).format("MMM-YYYY")}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {ResumeReduxData?.languagesKnown?.length > 0 && (
            <div className="mt-4">
              <h3 className="text-2xl font-bold">Languages</h3>

              <div>
                {ResumeReduxData?.languagesKnown?.map((item, index) => {
                  return (
                    <div>
                      <h5>{item}</h5>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {ResumeReduxData?.researchPapers?.length > 0 && (
            <div className="mt-4">
              <h3 className="text-2xl font-bold">Research Papers</h3>

              <div>
                {ResumeReduxData?.researchPapers?.map((item, index) => {
                  return (
                    <div>
                      <h5>Title - {item?.title}</h5>
                      <p>Authors - {item?.author}</p>
                      <p>
                        Published Date - {moment(item?.date).format("MMM-YYYY")}
                      </p>
                      <p>URL - {item?.url}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <FinishAchievement />
          {ResumeReduxData?.links?.length > 0 && (
            <div className="mt-4">
              <h3 className="text-2xl font-bold">Links</h3>

              <div>
                {ResumeReduxData?.links?.map((item, index) => {
                  return (
                    <div>
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {ResumeReduxData?.hobby?.length > 0 && (
            <div className="mt-4">
              <h3 className="text-2xl font-bold">Hobby</h3>

              <div>
                {ResumeReduxData?.hobby?.map((item, index) => {
                  return (
                    <div>
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default FinishExpandedData;

// Please generate concise descriptions for each item listed below, adhering strictly to the specified format and word limits. Use the provided details from experiences, projects, activities, education, and skills to craft a well-rounded summary.
// this is the format in which you've to provide information everytime stictly follor this format
// Experiences:
// For each experience, provide three key points, each not exceeding 20 words. provide output in pure json without any additional text take reference to below format without any extra text !!important also take care of role and company , those should strictly same as given in the payload as they are unique and i've to update the state using the role and company in experience and title and company in project :
// [
//   {
//     "key": "experience",
//     "descriptionArr": [
//       {
//         "role": "",
//         "company": "",
//         "description": [
//           "point1",
//           "point2",
//           "point3"
//         ]
//       }
//     ]
//   },
//   {
//     "key": "project",
//     "descriptionArr": [
//       {
//         "title": "",
//         "company": "",
//         "description": [
//           "point1",
//           "point2",
//           "point3"
//         ]
//       }
//     ]
//   },
//   {
//     "key": "summary",
//     "descriptionArr": ""
//   }
// ]
// Data provided:
// - Experiences: ${experienceDetails}   // Ensure these details are formatted for easy extraction
// - Projects: ${projectDetails}         // Ensure these details are formatted for easy extraction
// - Activities: ${activityDetails}
// - Education: ${educationDetails}
// - Skills: ${skillsDetails}

// Please strictly adhere to the word limits and formatting instructions to facilitate easy parsing of the output.
