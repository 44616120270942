import Accordion from "react-bootstrap/Accordion";
import BasicInput from "../../../Common/Input Fields/BasicInput";
import BasicSelect from "../../../Common/Input Fields/BasicSelect";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import { RiDeleteBin5Line } from "react-icons/ri";
import BasicTextArea from "New Ui/Common/Input Fields/BasicTextArea";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import toast from "react-hot-toast";
import { ChatGPTAPI } from "helper/api";
import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import { useSelector } from "react-redux";
function ProjectAccordian({
  ProjectArr,
  setProjectArr,
  handleRemoveProject,
  handleChange,
  submitAttempted,
}) {
  const handleDateChange = (index, name, value) => {
    const newArr = [...ProjectArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setProjectArr(newArr);
  };
  const [loadingDescription, setloadingDescription] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [loadingAi, setloadingAi] = useState(false);
  const postChatGPTData = async (e, index, project) => {
    setloadingAi(true);
    e.preventDefault();
    const payload = {
      question: `Give one ATS compliant work Project description point in past tense for the role of ${project?.role} at ${project?.company}, with the job type being ${project?.jobtype}. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing.give only text without any "" or '' or -`,
      temperature: 0.7,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const generatedDescription = res.data.trim().replaceAll(`"`, "");
      if (generatedDescription) {
        // Prepend bullet point if not already present
        const formattedDescription = `${generatedDescription}`;
        // Create a new array with the new description added
        const updatedDescriptions = [
          ...project.description,
          formattedDescription,
        ];
        // Create a new experience object with updated descriptions
        const updatedProject = {
          ...project,
          description: updatedDescriptions,
        };
        // Create a new array for the entire experiences, replacing the old experience with the updated one
        const newArr = [
          ...ProjectArr.slice(0, index),
          updatedProject,
          ...ProjectArr.slice(index + 1),
        ];
        setProjectArr(newArr); // Set the new array to the state
        toast.success("New point added ");
      }
      setloadingAi(false);
    } catch (error) {
      setloadingAi(false);
      console.error(error);
    }
  };

  const handleDescriptionChange = (index, e) => {
    const lines = e.target.value.split("\n");

    const newDescriptions = lines
      .map(
        (line) => line.replace(/^•\s*/, "") // Remove bullets for internal storage
      )
      .filter((line) => line.trim() !== ""); // Optionally filter out empty strings

    const newArr = [...ProjectArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setProjectArr(newArr);
  };

  const handleKeyDown = (index, e) => {
    const textarea = e.target;
    const value = textarea.value;
    const position = textarea.selectionStart;

    // Handling 'Enter' for new bullet points
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline behavior
      const beforeEnter = value.substring(0, position);
      const afterEnter = value.substring(position);
      const newValue = `${beforeEnter}\n• ${afterEnter}`;
      textarea.value = newValue;
      updateTextArea(index, newValue, position + 3); // Position cursor after the new bullet
    }

    // Handling 'Backspace' for bullet point removal
    else if (e.key === "Backspace") {
      if (position === 0) {
        e.preventDefault(); // Prevent backspace when at the start of the textarea
        return;
      }
      const charBeforeCursor = value.substring(position - 1, position);
      const twoCharsBeforeCursor = value.substring(position - 2, position);

      if (
        twoCharsBeforeCursor === "• " ||
        (charBeforeCursor === "\n" &&
          value.substring(position - 3, position - 1) === "•")
      ) {
        e.preventDefault();
        // Calculate new text removing the bullet or the newline directly before a bullet
        const newStartPos =
          charBeforeCursor === "\n" ? position - 3 : position - 2;
        const beforeBackspace = value.substring(0, newStartPos);
        const afterBackspace = value.substring(position);
        const newValue = `${beforeBackspace}${afterBackspace}`;
        textarea.value = newValue;
        updateTextArea(index, newValue, newStartPos);
      }
    }
  };

  const updateTextArea = (index, newValue, newCursorPos) => {
    // Update React state
    const newDescriptions = newValue.split("\n").map(
      (line) => (line.startsWith("• ") ? line.slice(2) : line) // Remove bullets for state storage
    );
    const newArr = [...ProjectArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setProjectArr(newArr);

    // Update cursor position
    setTimeout(() => {
      const textarea = document.querySelectorAll("textarea")[index]; // Target the correct textarea based on index
      textarea.selectionStart = textarea.selectionEnd = newCursorPos;
    }, 0);
  };
  const ResumeReduxData = useSelector((item) => item.resume);

  return (
    <Accordion defaultActiveKey={0}>
      {ProjectArr?.map((item, index) => {
        return (
          <div className="d-flex align-items-center">
            <Accordion.Item
              eventKey={index}
              className="mb-2  rounded w-100"
              style={{ border: "1px solid #602a94" }}
            >
              <Accordion.Header>
                <div className="d-flex justify-content-between w-100 pe-4">
                  <span> {!!item.title ? item?.title : "Title"}</span>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body style={{ visibility: "visible" }}>
                <div className="flex-row-responsive">
                  <BasicInput
                    name="title"
                    index={index}
                    handleChange={handleChange}
                    label="Title"
                    required={true}
                    submitAttempted={submitAttempted}
                    value={item?.title}
                    placeholder="Project title"
                  />
                  <BasicInput
                    name="company"
                    index={index}
                    label="Company Name (Optional)"
                    handleChange={handleChange}
                    value={item?.company}
                    placeholder="Eg. Viosa learning"
                  />
                </div>
                <div className="d-flex gap-5">
                  <BasicTextArea
                    name="role"
                    index={index}
                    label="Role"
                    value={item?.role}
                    handleChange={handleChange}
                    placeholder="write your contribution in project"
                  />
                </div>
                <div className="d-flex gap-5">
                  <BasicDate
                    handleChange={handleDateChange}
                    index={index}
                    data={item?.start}
                    label="Start Date"
                    name="start"
                    placeholder="Start Date"
                  />
                  <BasicDate
                    handleChange={handleDateChange}
                    index={index}
                    data={item?.end}
                    minDate={item?.start}
                    label="End Date"
                    name="end"
                    placeholder="End Date"
                  />

                  {/* <BasicDate
                  name="company"
                  label="Company Name"
                  value={ExperienceObj?.company}
                  placeholder="Eg Viosa Learning"
                />
                <BasicDate
                  name="name"
                  label="Full Name"
                  //   handleChange={handelChange}
                  value={ExperienceObj?.name}
                  placeholder="John Doe"
                /> */}
                </div>
                {ResumeReduxData?.isResumeEdit &&
                  ResumeReduxData?.projects?.length > index && (
                    <div className="">
                      <BasicTextAreaWithAi
                        name="description"
                        label="Description"
                        index={index}
                        handleChange={handleDescriptionChange}
                        postChatGPTData={postChatGPTData}
                        loadingAi={loadingAi}
                        data={item}
                        value={
                          Array.isArray(item.description)
                            ? item.description
                              .map((desc) => `• ${desc}`)
                              .join("\n")
                            : item.description
                        }
                        // onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                        placeholder="Type each point and press Enter for a new point"
                      />
                    </div>
                  )}
              </Accordion.Body>
            </Accordion.Item>
            <RiDeleteBin5Line
              onClick={() => {
                handleShowDelete();
                setdeleteIndex(index);
              }}
              fontSize={30}
              className="secondary-text align-self-start mt-2 ms-3 mr-0 cursor-pointer"
            />

            <Modal
              show={showDelete}
              onHide={handleCloseDelete}
              centered
              className="rounded"
            >
              <Modal.Header style={{ background: "#f0ddff" }} closeButton>
                <h4 className="text-lg">Are you sure?</h4>
              </Modal.Header>
              <Modal.Body>Do you want to delete this Project?</Modal.Body>
              <Modal.Footer>
                <button
                  className="primary-btn px-3 p-2 rounded"
                  onClick={handleCloseDelete}
                >
                  Cancel
                </button>
                <button
                  className="primary-btn  px-3 p-2 rounded"
                  onClick={() => {
                    toast.error("Project Deleted !");
                    handleCloseDelete();
                    handleRemoveProject(deleteIndex);
                  }}
                >
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      })}
    </Accordion>
  );
}

export default ProjectAccordian;
