import Accordion from "react-bootstrap/Accordion";
import BasicInput from "../../../Common/Input Fields/BasicInput";
import BasicSelect from "../../../Common/Input Fields/BasicSelect";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useState } from "react";
import CustomMonthPicker from "components/common/Calender";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Default styling
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import { ChatGPTAPI } from "helper/api";
import { IoIosAdd } from "react-icons/io";
import { useSelector } from "react-redux";

function ExperienceAccordian({
  handleChange,
  // activeIndex,
  submitAttempted,
  ExperienceArr,
  setExperienceArr,
  handleRemoveExperience,
  // setActiveIndex,
}) {
  const handleDateChange = (index, name, value) => {
    const newArr = [...ExperienceArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setExperienceArr(newArr);
  };
  const [showDelete, setShowDelete] = useState(false);
  const [loadingDescription, setloadingDescription] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);
  const handleCloseDelete = () => setShowDelete(false);
  const [loadingAi, setloadingAi] = useState(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleCheckbox = (index, item) => {
    const newArr = [...ExperienceArr];
    newArr[index] = {
      ...newArr[index],
      isWorking: !item.isWorking,
      // Only clear the 'end' date if 'isWorking' is being set to true
      end: !item.isWorking ? "" : item.end,
    };
    setExperienceArr(newArr);
  };
  const handleDescriptionChange = (index, e) => {
    const lines = e.target.value.split("\n");

    const newDescriptions = lines
      .map(
        (line) => line.replace(/^•\s*/, "") // Remove bullets for internal storage
      )
      .filter((line) => line.trim() !== ""); // Optionally filter out empty strings

    const newArr = [...ExperienceArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setExperienceArr(newArr);
  };

  const handleKeyDown = (index, e) => {
    const textarea = e.target;
    const value = textarea.value;
    const position = textarea.selectionStart;

    // Handling 'Enter' for new bullet points
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline behavior
      const beforeEnter = value.substring(0, position);
      const afterEnter = value.substring(position);
      const newValue = `${beforeEnter}\n• ${afterEnter}`;
      textarea.value = newValue;
      updateTextArea(index, newValue, position + 3); // Position cursor after the new bullet
    }

    // Handling 'Backspace' for bullet point removal
    else if (e.key === "Backspace") {
      if (position === 0) {
        e.preventDefault(); // Prevent backspace when at the start of the textarea
        return;
      }
      const charBeforeCursor = value.substring(position - 1, position);
      const twoCharsBeforeCursor = value.substring(position - 2, position);

      if (
        twoCharsBeforeCursor === "• " ||
        (charBeforeCursor === "\n" &&
          value.substring(position - 3, position - 1) === "•")
      ) {
        e.preventDefault();
        // Calculate new text removing the bullet or the newline directly before a bullet
        const newStartPos =
          charBeforeCursor === "\n" ? position - 3 : position - 2;
        const beforeBackspace = value.substring(0, newStartPos);
        const afterBackspace = value.substring(position);
        const newValue = `${beforeBackspace}${afterBackspace}`;
        textarea.value = newValue;
        updateTextArea(index, newValue, newStartPos);
      }
    }
  };

  const updateTextArea = (index, newValue, newCursorPos) => {
    // Update React state
    const newDescriptions = newValue.split("\n").map(
      (line) => (line.startsWith("• ") ? line.slice(2) : line) // Remove bullets for state storage
    );
    const newArr = [...ExperienceArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setExperienceArr(newArr);

    // Update cursor position
    setTimeout(() => {
      const textarea = document.querySelectorAll("textarea")[index]; // Target the correct textarea based on index
      textarea.selectionStart = textarea.selectionEnd = newCursorPos;
    }, 0);
  };

  // Give only one ATS compliant work experience description point in past tense for role of a ${
  //   experience?.role
  // } at ${experience?.company}, with the job type being ${
  //   experience?.jobtype
  // }.The description should highlight key responsibilities, accomplishments, or skills demonstrated in the role.The description should highlight a key responsibility, accomplishment, or skill demonstrated in the role. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing. Tailor the sentence based on the job type (internship, part-time, or full-time), focusing on relevant skills, achievements, or learning experiences suited to the specific role type. and generated pointer should be different than this pointers -  ${experience?.description.join(
  //   ","
  // )}
  //chatgpt
  const postChatGPTData = async (e, index, experience) => {
    e.preventDefault();
    setloadingAi(true);

    const payload = {
      question: `Give one ATS compliant work experience description point in past tense for the role of ${experience?.role} at ${experience?.company}, with the job type being ${experience?.jobtype}. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing.give only text without any "" or '' or -`,
      temperature: 0.7,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const generatedDescription = res.data.trim().replaceAll(`"`, "");
      if (generatedDescription) {
        // Prepend bullet point if not already present
        const formattedDescription = `${generatedDescription}`;
        // Create a new array with the new description added
        const updatedDescriptions = [
          ...experience.description,
          formattedDescription,
        ];
        // Create a new experience object with updated descriptions
        const updatedExperience = {
          ...experience,
          description: updatedDescriptions,
        };
        // Create a new array for the entire experiences, replacing the old experience with the updated one
        const newArr = [
          ...ExperienceArr.slice(0, index),
          updatedExperience,
          ...ExperienceArr.slice(index + 1),
        ];
        setExperienceArr(newArr); // Set the new array to the state
        toast.success("New point added ");
      }
      setloadingAi(false);
    } catch (error) {
      setloadingAi(false);
      console.error(error);
    }
  };

  const ResumeReduxData = useSelector((item) => item.resume);
  return (
    <Accordion defaultActiveKey={0}>
      {ExperienceArr?.map((item, index) => {
        return (
          <div className="d-flex align-items-center" key={index}>
            <Accordion.Item
              eventKey={index}
              className="mb-2  rounded w-100"
              style={{ border: "1px solid #602a94" }}
            >
              <Accordion.Header>
                <div className="d-flex justify-content-between w-100 pe-4">
                  <span>
                    {!!item.role || !!item.company
                      ? `${item?.role} - ${item?.company}`
                      : "Role - Company Name"}{" "}
                  </span>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body style={{ visibility: "visible" }}>
                <div className="flex-row-responsive">
                  <BasicSelect
                    index={index}
                    name="jobtype"
                    required={true}
                    label="Job Type"
                    submitAttempted={submitAttempted}
                    handleChange={handleChange}
                    options={[
                      "Internship",
                      "Part-Time",
                      "Full-Time",
                      "Consultant",
                    ]}
                    value={item?.jobtype}
                    placeholder=""
                  />
                  <BasicInput
                    name="role"
                    required={true}
                    label="Role"
                    index={index}
                    submitAttempted={submitAttempted}
                    handleChange={handleChange}
                    value={item?.role}
                    placeholder="Eg. Software Developer"
                  />
                </div>
                <div className="flex-row-responsive">
                  <BasicInput
                    name="company"
                    label="Company Name"
                    handleChange={handleChange}
                    required={true}
                    submitAttempted={submitAttempted}
                    index={index}
                    value={item?.company}
                    placeholder="Eg Viosa Learning"
                  />
                  <BasicInput
                    name="location"
                    index={index}
                    label="Location"
                    handleChange={handleChange}
                    value={item?.location}
                    placeholder="Mumbai"
                  />
                </div>
                <div className="flex-row-responsive">
                  <BasicDate
                    handleChange={handleDateChange}
                    index={index}
                    data={item?.start}
                    label="Start Date"
                    name="start"
                    submitAttempted={submitAttempted}
                    required={true}
                    placeholder="Start Date"
                  />
                  <div className="w-100 ">
                    <BasicDate
                      handleChange={handleDateChange}
                      index={index}
                      data={item?.end}
                      name="end"
                      EndDateDisabled={item?.isWorking}
                      minDate={item?.start}
                      label="End Date"
                    />
                    <div className="d-flex gap-3 ">
                      <input
                        type="checkbox"
                        style={{ height: "18px", width: "18px" }}
                        checked={item?.isWorking}
                        onChange={() => handleCheckbox(index, item)}
                      />
                      <p className="text-sm">I currently work here</p>
                    </div>
                  </div>
                </div>
                {ResumeReduxData?.isResumeEdit &&
                  ResumeReduxData?.experiences?.length > index && (
                    <div className="">
                      <BasicTextAreaWithAi
                        name="description"
                        index={index}
                        handleChange={handleDescriptionChange}
                        label="Description"
                        postChatGPTData={postChatGPTData}
                        data={item}
                        loadingAi={loadingAi}
                        value={  Array.isArray(item.description)
                          ? item.description
                              .map((desc) => `• ${desc}`)
                              .join("\n")
                          : item.description}
                        // onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                        placeholder="Type each point and press Enter for a new point"
                      />
                    </div>
                  )}
              </Accordion.Body>
            </Accordion.Item>

            {/* Delete Accordian Item */}
            <RiDeleteBin5Line
              // onClick={() => handleRemoveExperience(index)}
              fontSize={30}
              onClick={() => {
                handleShowDelete();
                setdeleteIndex(index);
              }}
              className="secondary-text align-self-start mt-2 ms-3 mr-0 cursor-pointer"
              // style={{ zIndex: 100000 }}
            />

            <Modal
              show={showDelete}
              onHide={handleCloseDelete}
              centered
              className="rounded"
            >
              <Modal.Header style={{ background: "#f0ddff" }} closeButton>
                <h4 className="text-lg">Are you sure?</h4>
              </Modal.Header>
              <Modal.Body>Do you want to delete this Experience?</Modal.Body>
              <Modal.Footer>
                <button
                  className="primary-btn px-3 p-2 rounded"
                  onClick={handleCloseDelete}
                >
                  Cancel
                </button>
                <button
                  className="primary-btn  px-3 p-2 rounded"
                  onClick={() => {
                    toast.error("Experience Deleted !");
                    handleRemoveExperience(deleteIndex);
                    handleCloseDelete();
                  }}
                >
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      })}
    </Accordion>
  );
}

export default ExperienceAccordian;
