import React from "react";

function BasicInput({
  index,
  handleChange,
  type,
  label,
  name,
  value,
  placeholder,
  onKeyDown,
  required,
  submitAttempted,
}) {
  const isValid = !required || value.trim() !== "";
  return (
    <>
      <div className="d-flex flex-column form-input mb-3 w-100">
        <label for="" className="text-sm ">
          {label} {required && <span className="text-danger">*</span>}
        </label>
        <input
          type={type}
          className=""
          id=""
          placeholder={placeholder}
          onChange={(e) => handleChange(index, e)}
          value={value}
          required={required}
          style={{
            border:
              submitAttempted && !isValid
                ? "1.5px solid red"
                : "1.5px solid #602a94",
          }}
          onKeyDown={onKeyDown}
          name={name}
        />
      </div>
    </>
  );
}

export default BasicInput;
