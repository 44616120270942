import { connectStorageEmulator } from "firebase/storage";
import { ChatGPTAPI } from "helper/api";
import { setJobSpecificFields } from "helper/redux/Slice/ResumeSlice";
import {
  GettingSkillSetJobSpecific,
  setjobSpecificOrGeneric,
} from "helper/redux/Slice/UtilsSlice";
import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import { Form } from 'react-router-dom';

const JobSpecificModal = ({ PostData }) => {
  const [isEntryModal, setisEntryModal] = useState(true);
  const [step, setstep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //choose your type resume

  const [JobSpecificDetails, setJobSpecificDetails] = useState({
    profession: "",
    jobProfile: "",
    desiredCompany: "",
    jobDescription: "",
    jobDescriptionToggle: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobSpecificDetails({ ...JobSpecificDetails, [name]: value });
  };
  console.log(JobSpecificDetails);

  const handleJobDescriptionToggle = (e) => {
    setJobSpecificDetails({
      ...JobSpecificDetails,
      jobDescriptionToggle: e.target.checked,
    });
  };
  const handleNextStep = () => {
    if (step === 2) {
      dispatch(setJobSpecificFields(JobSpecificDetails));
      dispatch(setjobSpecificOrGeneric("JOBSPECIFIC"));

      setisEntryModal(false);
      PostData();
    } else {
      setstep(step + 1);
    }
  };
  const handlePrevStep = () => {
    if (step === 1) {
      navigate("/");
      console.log("step");
    } else {
      setstep(step - 1);
    }
  };

  // const string = "Here is a list of keywords for the job profile of a software engineer to Check ATS compliance in JS:\n\n• JavaScript\n• ATS compliance\n• Software engineering\n• Coding\n• Debugging\n• Troubleshooting\n• Quality assurance\n• Testing\n• Agile methodology\n• Object-oriented programming\n• Design patterns\n• Web development\n• Database management\n• Cloud computing\n• Security protocols"

  // const string2 = `Here is a list of keywords for the job profile of Social Media Manager to Check ATS compliance in JavaScript: \n• ATS compliance \n• Social Media Manager \n1. JavaScript \n2. Digital marketing \n3. SEO \n4. Campaigns management \n5. Content creation \n6. Copywriting \n7. Analytics & reporting \n8. Client relations \n9. CRM experience \n10. Online advertising \n11. Web development \n12. Graphic design`

  // const string3 = `Frontend development, ATS compliance, JavaScript, HTML, CSS, React, Angular, Vue.js, web accessibility, browser compatibility, cross-platform development, responsive design, UI/UX design, debugging, bug fixing, unit testing, version control, agile methodology.`

  // const string4 = `Frontend Development Keywords: HTML, CSS, JavaScript, React, Angular, Vue, RESTful APIs, AJAX, JSON, jQuery, DOM manipulation, cross-browser compatibility, web components, responsive web design, web accessibility, debugging, Git`
  // function extractSkills(string) {
  //   const regex = /[•0-9]+\s?([^:\n]+)/g;
  //   const matches = string.match(regex);

  //   if (matches) {
  //     let skills = matches.map(match => match.replace(/[•0-9]+\s?/, '').trim());
  //     skills = skills.map((item, index) => item.includes(". ") ? item.replace(". ", "") : item.includes(`${index}.`) ? item.replace(`${index}.`, "") : item)
  //     return skills;
  //   }

  //   return [];
  // }

  return (
    <div>
      {isEntryModal && (
        <div
          className="preview-modal-container"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.794)",
            backdropFilter: "blur(8px)",
            overflow: "auto",
          }}
        >
          {step === 1 && (
            <div className="modal-resumeType-container">
              <h3>Choose type of your resume</h3>
              {/* <p>Update your account's email address and profile information</p> */}
              <div className="resumeType-button-container">
                <button
                  onClick={() => {
                    handleNextStep();
                  }}
                >
                  Job Specific Resume
                </button>
                <button
                  onClick={() => {
                    setisEntryModal(false);
                    PostData();
                  }}
                  // style={resumeType === "resume" ? selectedButtonStyle : {}}
                >
                  Generic Resume
                </button>
              </div>

              <div className="resumeType-controlButton-container">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="modal-resumeType-container">
              <h3>Choose type of your resume</h3>
              <p>Update your Job Title/Domain/Desired Company</p>

              <div className="resumeType-fields-container">
                <div className="create-resume-main-job ">
                  <Form className="form-main" style={{ border: "none" }}>
                    <FloatingLabel
                      label="Job Title"
                      className="mb-3 Job-specific-label "
                    >
                      <Form.Control
                        type="text"
                        name="profession"
                        required
                        placeholder="Enter Job profile"
                        value={JobSpecificDetails?.profession}
                        onChange={handleChange}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      label="Field/Domain"
                      className="mb-3 Job-specific-label"
                    >
                      <Form.Control
                        type="text"
                        key="email"
                        name="jobProfile"
                        id="email"
                        placeholder="Email"
                        value={JobSpecificDetails?.jobProfile}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    {/* <div className="d-flex align-items-center justify-content-between px-3 mb-3">
                      <span
                        className="Job-specific-label  mb-0"
                        style={{ fontSize: "12px", color: "grey" }}
                      >
                        Do you have job description?
                      </span>
                      <div className="d-flex gap-2 align-items-center">
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          No
                        </label>
                        <div class="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            onClick={handleJobDescriptionToggle}
                            checked={JobSpecificDetails?.jobDescriptionToggle}
                            style={{
                              width: "3rem",
                              padding: "0.6rem 1rem",
                              height: "1rem",
                              cursor: "pointer",
                            }}
                            id="flexSwitchCheckDefault"
                          />
                        </div>
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Yes
                        </label>
                      </div>
                    </div> */}
                    {/* {JobSpecificDetails?.jobDescriptionToggle && ( */}
                    <FloatingLabel
                      label="Job Description"
                      style={{ color: "black" }}
                      className="mb-3 Job-specific-label"
                    >
                      <Form.Control
                        as="textarea"
                        style={{ height: "auto" }}
                        rows={4}
                        placeholder="Job Description"
                        name="jobDescription"
                        // checked={JobSpecificDetails?.jobdescription}
                        value={JobSpecificDetails?.jobDescription}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                    {/* )} */}
                  </Form>
                </div>
              </div>

              <div className="resumeType-controlButton-container">
                <button
                  className="align-self-start"
                  onClick={() => {
                    handlePrevStep();
                  }}
                >
                  Back
                </button>

                {step > 1 && (
                  <button
                    disabled={!JobSpecificDetails?.profession}
                    onClick={() => {
                      handleNextStep();
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JobSpecificModal;
