import cardPreview from "../../assets/image/card preview.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import {
  AiOutlineFileAdd,
  AiOutlinePlusCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import {
  DeleteWishListVideos,
  GetPracticeVideos,
  GetWishListVideos,
  PostWishListVideos,
} from "helper/api";
import LoadingComponent from "common/LoadingComponent";

export const PracticeVideos = () => {
  const [type, setType] = useState("resume");
  const [Loading, setLoading] = useState(false);
  function Type(e) {
    setType(e);
  }

  const selectedStyle = {
    backgroundColor: "#521986",
    color: "rgba(255, 255, 255, 0.864)",
  };

  const [Videos, setVideos] = useState([]);

  const GetVideos = async () => {
    let data;
    try {
      setLoading(true);
      if (type === "fav") {
        data = await GetWishListVideos();
      } else {
        data = await GetPracticeVideos();
      }
      setLoading(false);
      console.log(data);
      if (type === "fav") {
        setVideos(data?.data?.data?.favs);
      } else {
        setVideos(data?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GetVideos();
  }, [type]);

  return (
    <div className="practice-videos-wrapper px-lg-5 px-3 ">
      <h2 className="text-3xl mb-3 primary-text font-bold">Practice Videos</h2>
      <p>
        Best way to learn how to write your resume and cover letter is to lear
        with our experts and take reference from top MNC resumes and cover
        letters
      </p>

      <div className="practice-videos-buttonTab d-flex w-100  px-0   gap-3">
        <button
          className=""
          onClick={() => Type("resume")}
          style={type === "resume" ? selectedStyle : {}}
        >
          <AiOutlineFileAdd size="20" className="icon" />
          Resume
        </button>
        <button
          className=" "
          onClick={() => Type("cover")}
          style={type === "cover" ? selectedStyle : {}}
        >
          <AiOutlineFilePdf size="20" className="icon" />
          Cover Letter
        </button>
        <button
          className="align-self-end"
          onClick={() => Type("fav")}
          style={type === "fav" ? selectedStyle : {}}
        >
          <AiOutlineHeart size="20" className="icon" />
          Show Wishlist
        </button>
      </div>
      {/* <div className="flex gap-3 w-100">
         <div className="bg-indigo-700 text-white p-3 rounded "><AiOutlineFileAdd size="20" className="icon" /> Resume</div>

         <div className="bg-indigo-700 text-white p-3 rounded">
            <AiOutlineFilePdf size="20" className="icon" />
            Cover Letter
         </div>

         <div className="bg-indigo-700 align-self-end text-white p-3 rounded"> <AiOutlineHeart size="20" className="icon" />
            Show Whitelisted</div>
      </div> */}

      <div className="practiceVideos-videos-container col-12 gap-5  d-flex align-items-stretch flex-wrap">
        {Loading ? (
          <div className="w-100 d-flex justify-content-center">
            <LoadingComponent />
          </div>
        ) : (
          <>
            {Videos?.length > 0
              ? Videos?.map((item, index) => {
                  return <PracticeCard Key={index} item={item} />;
                })
              : "No Videos Found"}
          </>
        )}

        {/* <div className="practiceVideos-video-preview-card" onClick={() => { navigate("/best-resume-practice/course") }}>
            <img alt="card-preview" src={cardPreview}></img>
            <h4>Cover Letter</h4>
            <p>Should have eye for clean and artful design and process super UI skills and translate</p>
            <span>READ MORE</span>
            <div className="practiceVideos-video-preview-card-button-container">
               <button>Watch Video</button>
               <span><AiOutlineHeart size="25" /></span>
            </div>
         </div>

         <div className="practiceVideos-video-preview-card" onClick={() => { navigate("/best-resume-practice/course") }}>
            <img alt="card-preview" src={cardPreview}></img>
            <h4>Cover Letter</h4>
            <p>Should have eye for clean and artful design and process super UI skills and translate</p>
            <span>READ MORE</span>
            <div className="practiceVideos-video-preview-card-button-container">
               <button>Watch Video</button>
               <span><AiOutlineHeart size="25" /></span>
            </div>
         </div> */}
      </div>
    </div>
  );
};

const PracticeCard = ({ item, Key }) => {
  const [Expand, setExpand] = useState(false);
  const [wishList, setwishList] = useState(false);
  const navigate = useNavigate();
  const HandleReadMore = () => {
    if (Expand) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };
  const handleWishList = () => {
    if (wishList) {
      setwishList(false);
    } else {
      setwishList(true);
    }
    PostWishList();
  };
  useEffect(() => {
    setwishList(item?.wishlist);
  }, [item?.wishlist]);

  const PostWishList = async () => {
    try {
      const Payload = {
        id: item._id,
      };
      if (wishList) {
        await DeleteWishListVideos(Payload);
      } else {
        await PostWishListVideos(Payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      key={Key}
      className="practiceVideos-video-preview-card col-12 col-lg-3 "
    >
      <img
        alt="card-preview "
        className="w-100"
        src={item?.thumbnail ?? cardPreview}
      ></img>
      <h4>{item?.title}</h4>
      <p>
        {Expand ? item?.description : `${item?.description?.slice(0, 125)}...`}
      </p>
      <span className="cursor-pointer" onClick={HandleReadMore}>
        {Expand ? "READ LESS" : "READ MORE"}
      </span>
      <div className="practiceVideos-video-preview-card-button-container">
        <button
          onClick={() => {
            navigate(`/best-resume-practice/${item?._id}`);
          }}
          className="cursor-pointer"
        >
          Watch Video
        </button>
        <span
          onClick={handleWishList}
          className={`${wishList ? `span-active` : null} cursor-pointer`}
        >
          {wishList ? <AiFillHeart size="25" /> : <AiOutlineHeart size="25" />}
        </span>
      </div>
    </div>
  );
};
