import RT3non from "components/ResumeTemplate/T1/RT1non";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import { setBaseStep } from "helper/redux/Slice/UtilsSlice";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";

function ResumeCardCover({ component, handleSelectedTemplate, data }) {
  const dispatch = useDispatch();
  const [ResumeCardData, setResumeCardData] = useState();
  const handleBaseStep = (step) => {
    dispatch(setBaseStep(step));
  };

  const matchResumeTemplate = () => {
    const templateObj = ResumeTemplateJson?.find(
      (item) => Number(item.templateNo) === Number(data?.template)
    );
    setResumeCardData(templateObj);
  };

  useEffect(() => {
    matchResumeTemplate();
  }, []);
  return (
    <>
      <Card
        onClick={() => {
          //   handleBaseStep(3);
          //   handleSelectedTemplate(data?.templateNo);
        }}
        className="resume-common-card rounded"
      >
        <Card.Body style={{ padding: "7px " }}>
          {!!ResumeCardData && <ResumeCardData.templateEdit data={data} />}
        </Card.Body>
      </Card>
    </>
  );
}

export default ResumeCardCover;
