import React from "react";
import { RiReactjsFill } from "react-icons/ri";

function AiButton({ handleClick }) {
  return (
    <>
      <button
        className="primary-btn px-2 rounded p-2 d-flex align-items-center gap-2"
        onClick={handleClick}
      >
        <span className="bg-light p-1 rounded primary-text ">21/200</span>
        <span>
          <RiReactjsFill fontSize={20} />
        </span>
        <span className="text-lg">AI</span>
      </button>
    </>
  );
}

export default AiButton;
