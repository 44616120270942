import { ChatGPTAPI } from "helper/api";
import { setAchievementsFields } from "helper/redux/Slice/ResumeSlice";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import BasicTextArea from "New Ui/Common/Input Fields/BasicTextArea";
import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

function Achievement({ achievementArr, setachievementArr }) {
  const dispatch = useDispatch();
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const handleAddAchievement = () => {
    setsubmitAttempted(true);
    const lastAchievement = achievementArr[achievementArr.length - 1];
    const errors = validateAchievement(lastAchievement);
    if (Object.keys(errors).length === 0) {
      const newAchievement = {
        title: "",
        description: "",
        date: "",
      };
      setachievementArr([...achievementArr, newAchievement]);
      dispatch(setAchievementsFields(achievementArr));
      setsubmitAttempted(false);
      toast.success("Achievement added ");
    } else {
      toast.error("Please fill Title");
    }
  };
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...achievementArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setachievementArr(newArr);
  };

  const handleRemoveAchivement = (index) => {
    const newArr = [...achievementArr];
    newArr.splice(index, 1);
    setachievementArr(newArr);
    if (index === 0) {
      setachievementArr([
        {
          title: "",
          description: "",
          date: "",
        },
      ]);
    }
  };
  const handleDateChange = (index, name, value) => {
    const newArr = [...achievementArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setachievementArr(newArr);
  };

  const validateAchievement = (achievement) => {
    const errors = {};
    if (!achievement.title) {
      errors.role = "title is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [loadingAi, setloadingAi] = useState(false);
  const postChatGPTData = async (e, index, achievement) => {
    setloadingAi(true);
    e.preventDefault();
    const payload = {
      question: `Give one ATS compliant achievement  point in past tense for the title of ${achievement?.title}. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing.give only text without any "" or '' or -`,
      temperature: 0.1,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const generatedDescription = res.data.trim().replaceAll(`"`, "");
      if (generatedDescription) {
        // Prepend bullet point if not already present
        const formattedDescription = `${generatedDescription}`;
        // Create a new array with the new description added
        const updatedDescriptions = [
          ...achievement.description,
          formattedDescription,
        ];
        // Create a new experience object with updated descriptions
        const updatedAchievement = {
          ...achievement,
          description: updatedDescriptions,
        };
        // Create a new array for the entire experiences, replacing the old experience with the updated one
        const newArr = [
          ...achievementArr.slice(0, index),
          updatedAchievement,
          ...achievementArr.slice(index + 1),
        ];
        setachievementArr(newArr); // Set the new array to the state
        toast.success("New point added ");
      }
      setloadingAi(false);
    } catch (error) {
      setloadingAi(false);
      console.error(error);
    }
  };

  const handleDescriptionChange = (index, e) => {
    const lines = e.target.value.split("\n");

    const newDescriptions = lines
      .map(
        (line) => line.replace(/^•\s*/, "") // Remove bullets for internal storage
      )
      .filter((line) => line.trim() !== ""); // Optionally filter out empty strings

    const newArr = [...achievementArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setachievementArr(newArr);
  };

  const handleKeyDown = (index, e) => {
    const textarea = e.target;
    const value = textarea.value;
    const position = textarea.selectionStart;

    // Handling 'Enter' for new bullet points
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline behavior
      const beforeEnter = value.substring(0, position);
      const afterEnter = value.substring(position);
      const newValue = `${beforeEnter}\n• ${afterEnter}`;
      textarea.value = newValue;
      updateTextArea(index, newValue, position + 3); // Position cursor after the new bullet
    }

    // Handling 'Backspace' for bullet point removal
    else if (e.key === "Backspace") {
      if (position === 0) {
        e.preventDefault(); // Prevent backspace when at the start of the textarea
        return;
      }
      const charBeforeCursor = value.substring(position - 1, position);
      const twoCharsBeforeCursor = value.substring(position - 2, position);

      if (
        twoCharsBeforeCursor === "• " ||
        (charBeforeCursor === "\n" &&
          value.substring(position - 3, position - 1) === "•")
      ) {
        e.preventDefault();
        // Calculate new text removing the bullet or the newline directly before a bullet
        const newStartPos =
          charBeforeCursor === "\n" ? position - 3 : position - 2;
        const beforeBackspace = value.substring(0, newStartPos);
        const afterBackspace = value.substring(position);
        const newValue = `${beforeBackspace}${afterBackspace}`;
        textarea.value = newValue;
        updateTextArea(index, newValue, newStartPos);
      }
    }
  };

  const updateTextArea = (index, newValue, newCursorPos) => {
    // Update React state
    const newDescriptions = newValue.split("\n").map(
      (line) => (line.startsWith("• ") ? line.slice(2) : line) // Remove bullets for state storage
    );
    const newArr = [...achievementArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setachievementArr(newArr);

    // Update cursor position
    setTimeout(() => {
      const textarea = document.querySelectorAll("textarea")[index]; // Target the correct textarea based on index
      textarea.selectionStart = textarea.selectionEnd = newCursorPos;
    }, 0);
  };

  const ResumeReduxData = useSelector((item) => item.resume);

  return (
    <>
      <Accordion.Item
        eventKey={5}
        className="mb-2  rounded w-100"
        style={{ border: "1px solid #602a94" }}
      >
        <Accordion.Header>
          <div className="d-flex justify-content-between w-100 pe-4">
            <span> Achievements</span>
            <div></div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ visibility: "visible" }}>
          <div>
            {achievementArr?.map((item, index) => {
              return (
                <>
                  <div className="d-flex mb-4">
                    <div
                      className="primary-border w-100 px-4 py-3 rounded"
                      style={{ borderWidth: "1px" }}
                    >
                      <div className="flex-row-responsive ">
                        <BasicInput
                          name="title"
                          label="Title"
                          handleChange={handleChange}
                          value={item?.title}
                          index={index}
                          placeholder="Write title"
                          submitAttempted={submitAttempted}
                          required={true}
                        />
                        <BasicDate
                          name="date"
                          label="Date"
                          handleChange={handleDateChange}
                          data={item?.date}
                          index={index}
                        />
                      </div>
                      {/* <div className="d-flex gap-5 ">
                        <BasicTextArea
                          name="description"
                          index={index}
                          label="Description"
                          handleChange={handleChange}
                          value={item?.description}
                          placeholder="write description"
                        />
                      </div> */}
                      {ResumeReduxData?.isResumeEdit &&
                        ResumeReduxData?.achievement?.length > index && (
                          <div className="">
                            <BasicTextAreaWithAi
                              name="description"
                              index={index}
                              handleChange={handleDescriptionChange}
                              label="Description"
                              postChatGPTData={postChatGPTData}
                              loadingAi={loadingAi}
                              data={item}
                              value={
                                Array.isArray(item.description)
                                  ? item.description
                                      .map((desc) => `• ${desc}`)
                                      .join("\n")
                                  : item.description
                              }
                              // onKeyPress={handleKeyPress}
                              onKeyDown={handleKeyDown}
                              placeholder="Type each point and press Enter for a new point"
                            />
                          </div>
                        )}
                    </div>
                    <RiDeleteBin5Line
                      onClick={() => {
                        handleShowDelete();
                        setdeleteIndex(index);
                      }}
                      fontSize={30}
                      className="secondary-text align-self-start  ms-3 cursor-pointer"
                    />
                  </div>
                </>
              );
            })}
          </div>

          {/* <button
            className="secondary-btn rounded px-3 p-1 mt-3"
            onClick={handleAddAchievement}
          >
            Add More
          </button> */}
          <div className="d-flex justify-content-end pe-5 ">
            <button
              className="primary-btn rounded px-3 p-2 mt-1 d-flex gap-1 justify-content-center align-items-center"
              onClick={handleAddAchievement}
            >
              <IoIosAdd fontSize={24} />
              Achievement
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        centered
        className="rounded"
      >
        <Modal.Header style={{ background: "#f0ddff" }} closeButton>
          <h4 className="text-lg">Are you sure?</h4>
        </Modal.Header>
        <Modal.Body>Do you want to delete this Achievement?</Modal.Body>
        <Modal.Footer>
          <button
            className="primary-btn px-3 p-2 rounded"
            onClick={handleCloseDelete}
          >
            Cancel
          </button>
          <button
            className="primary-btn  px-3 p-2 rounded"
            onClick={() => {
              toast.error("Achievement Deleted !");
              handleRemoveAchivement(deleteIndex);
              handleCloseDelete();
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Achievement;
