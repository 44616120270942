import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const UseOptimization = ({ data }) => {
  let Optimization = 0;
  let ResumeRedux;
  const resumeData = useSelector((state) => state.resume);
  if (!!data) {
    ResumeRedux = data;
  } else {
    ResumeRedux = resumeData;
  }
  const CoverRedux = useSelector((item) => item?.cover);
  const types = useSelector((item) => item?.types);

  const evaluateOptimization = () => {
    if (
      types?.doctype === "resume" &&
      types?.docsubtype !== "automatedResume"
    ) {
      // Normal Resume
      if (ResumeRedux?.experiences?.length > 0) {
        ResumeRedux?.experiences?.length === 1
          ? (Optimization += 4.16)
          : ResumeRedux?.experiences?.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.projects?.length > 0) {
        ResumeRedux?.projects.length === 1
          ? (Optimization += 7.25)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.education?.length > 0) {
        ResumeRedux?.education.length === 1
          ? (Optimization += 4.16)
          : ResumeRedux?.education.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.certification?.length > 0) {
        ResumeRedux?.certification.length === 1
          ? (Optimization += 7.25)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.involvement?.length > 0) {
        ResumeRedux?.involvement.length === 1
          ? (Optimization += 7.25)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.skills?.length > 0) {
        ResumeRedux?.skills.length === 1
          ? (Optimization += 4.16)
          : ResumeRedux?.skills.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
      if (ResumeRedux?.hobby?.length > 0) {
        ResumeRedux?.hobby.length === 1
          ? (Optimization += 4.16)
          : ResumeRedux?.hobby.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
    } else if (
      types?.doctype === "resume" &&
      types?.docsubtype === "automatedResume"
    ) {
      // automated resume
      if (ResumeRedux?.skills?.length > 0) {
        ResumeRedux?.skills.length === 1
          ? (Optimization += 7.1)
          : ResumeRedux?.skills.length === 2
          ? (Optimization += 14.2)
          : (Optimization += 20);
      }
      if (ResumeRedux?.hobby?.length > 0) {
        ResumeRedux?.hobby.length === 1
          ? (Optimization += 7.1)
          : ResumeRedux?.hobby.length === 2
          ? (Optimization += 14.2)
          : (Optimization += 20);
      }
      if (ResumeRedux?.experiences?.length > 0) {
        ResumeRedux?.experiences.length === 1
          ? (Optimization += 7.1)
          : ResumeRedux?.experiences.length === 2
          ? (Optimization += 14.2)
          : (Optimization += 20);
      }
      if (ResumeRedux?.projects?.length > 0) {
        console.log(ResumeRedux?.projects.length);
        ResumeRedux?.projects?.length === 1
          ? (Optimization += 14.2)
          : (Optimization += 20);
      }
      if (ResumeRedux?.education.length > 0) {
        ResumeRedux?.education?.length === 1
          ? (Optimization += 7.1)
          : ResumeRedux?.education?.length === 2
          ? (Optimization += 14.2)
          : (Optimization += 20);
      }
    } else if (types?.doctype === "cover" && types?.coverFor === "Fresher") {
      // fresher cover
      if (
        !!CoverRedux?.targetJob?.position &&
        !!CoverRedux?.targetJob?.company
      ) {
        Optimization += 12.5;
      }
      if (
        !!CoverRedux?.to?.name &&
        !!CoverRedux?.to?.email &&
        !!CoverRedux?.to?.position &&
        !!CoverRedux?.to?.companyName
      ) {
        Optimization += 12.5;
      }
      if (CoverRedux?.experiences?.length > 0) {
        CoverRedux?.experiences?.length === 1
          ? (Optimization += 4.16)
          : CoverRedux?.experiences?.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
      // if (CoverRedux?.projects?.length > 0) {
      //   CoverRedux?.projects?.length === 1 ? Optimization += 7.25 : Optimization += 12.5
      // }
      if (CoverRedux?.education?.length > 0) {
        CoverRedux?.education?.length === 1
          ? (Optimization += 4.16)
          : CoverRedux?.education?.length === 2
          ? (Optimization += 8.33)
          : (Optimization += 12.5);
      }
      if (CoverRedux?.certification.length > 0) {
        CoverRedux?.certification?.length === 1
          ? (Optimization += 7.25)
          : (Optimization += 12.5);
      }

      if (CoverRedux?.involvement.length > 0) {
        CoverRedux?.involvement?.length === 1
          ? (Optimization += 7.25)
          : (Optimization += 12.5);
      }
      if (!!CoverRedux?.content) {
        Optimization += 12.5;
      }
    } else if (
      types?.doctype === "cover" &&
      types?.coverFor === "Experienced"
    ) {
    }
  };

  evaluateOptimization();

  return Optimization;
};

export default UseOptimization;
