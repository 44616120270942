import React, { useState, useEffect } from "react";
import { NavbarStyled } from "./NavbarStyles";
import user from "../../assets/image/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { removedata, setUser } from "helper/redux/Slice/UserSlice";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { FaHamburger } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { RiMenu2Line } from "react-icons/ri";
import { Dropdown, Form, Offcanvas } from "react-bootstrap";
import { SidePanel } from "../Sidepanel/SidePanel";
import ResponsiveSIdebar from "./ResponsiveSIdebar";
import sidelogo from "../../assets/image/sidelogo.mp4";
import logo2 from "../../assets/image/logo.svg";
import StepsResponsive from "./StepsResponsive";
import { BiDownArrow } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import "./navbar.css";
export const Navbar = () => {
  const { profileImage } = useSelector((state) => state.user);
  const UtilsData = useSelector((state) => state.utils);
  const navigate = useNavigate();
  const [ShowMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const handlelogout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    localStorage.removeItem("user2");
    dispatch(removedata());
    // window.location.reload();
    // Redirect to https://viosa.in after logout
    window.location.href = "https://viosa.in";
  };

  const handleToggle = () => {
    if (ShowMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  //responsive sidebar
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let UserData = JSON.parse(localStorage.getItem("user2"));

  return (
    <div
      className="w-100 mb-4 position-relative px-0 px-lg-4 d-flex justify-content-lg-end justify-content-between container align-items-center"
      style={{ height: "10vh" }}
    >
      <div className="d-inline-block d-lg-none" onClick={handleShow}>
        <RiMenu2Line fontSize={32} />
      </div>
      <div className="nav-sec-1">
        {(window.location.pathname.includes("/create-resume") ||
          window.location.pathname.includes("/create-cover")) && (
          <StepsResponsive />
        )}
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center">
          <span className="me-2 primary-text" style={{ fontWeight: "bold" }}>
            Hi, {UserData?.firstname}!
          </span>

          <Dropdown>
            <Dropdown.Toggle
              split
              id="dropdown-custom-components"
              variant="light"
              style={{
                color: "#602a94",
                boxShadow: "none",
              }}
            ></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="d-flex gap-2 align-items-center custom-dropdown-item"
                onClick={() => navigate("/my-profile")}
              >
                <div style={{ height: 30, width: 30 }}>
                  <img
                    className="border rounded-circle"
                    src={UserData?.profileImage || user}
                    alt="user"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handlelogout}
                className="d-flex custom-dropdown-item gap-2 align-items-center"
              >
                <div
                  className="rounded-circle border d-flex justify-content-center align-items-center"
                  style={{ height: 30, width: 30 }}
                >
                  <IoLogOutOutline fontSize={20} />
                </div>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* 
      <div className="d-flex justify-content-end align-items-center ">
        <Dropdown>
        <div className="d-flex align-items-center gap-2">
            <div style={{ height: 60, width: 60 }}>
              <img
                className=" border rounded"
                src={!!UserData?.profileImage ? UserData?.profileImage : user}
                alt="user"
              />
            </div>

            <Dropdown.Toggle
              split
              id="dropdown-split-basic"
              variant="light "
              className="primary-icon"
              style={{ background: "none !important", color: "#602a94" }}
            />
          </div>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {ShowMenu && (
          <div
            className="border me-3 navbar-logout-popup bg-white "
            style={{ zIndex: "10" }}
          >
            <Link
              className=" cursor-pointer py-2 px-4 mb-0 profile-header"
              to={"/my-profile"}
            >
              My Profile
            </Link>
            <p
              className="text-dark cursor-pointer py-2 px-4 mb-0"
              onClick={handlelogout}
            >
              Logout
            </p>
          </div>
        )}
        <div className=" cursor-pointer" onClick={handleToggle}>
          <img
            className="user-img border"
            width={50}
            height={50}
            src={!!UserData?.profileImage ? UserData?.profileImage : user}
            alt="user"
          />
        </div>
      </div> */}

      <Offcanvas show={show} onHide={handleClose} style={{ width: "80%" }}>
        <Offcanvas.Header className="p-0 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center py-4 w-100 ">
            <img src={logo2} alt="logo" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 h-100">
          <ResponsiveSIdebar />
          {/* <SidePanel /> */}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
