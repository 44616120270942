import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import AiButton from "New Ui/Common/Buttons/AiButton";
import SkillsSkeleton from "New Ui/Common/Skeleton/SkillsSkeleton";
import { IoAddCircleOutline } from "react-icons/io5";
import { ChatGPTAPI } from "helper/api";
import {
  setIsResumeEdit,
  setSkillChatGpt,
  setSkillFields,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import toast from "react-hot-toast";
import { CiCircleMinus } from "react-icons/ci";

function Skill() {
  const dispatch = useDispatch();
  const [skillSearch, setskillSearch] = useState(null);
  const [selectedSkillText, setSelectedSkillText] = useState("• ");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [loadingSkills, setLoadingSkills] = useState(true);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const ResumeReduxData = useSelector((state) => state.resume);
  const SkillReduxData = useSelector((item) => item?.resume?.skills);
  const SuggestedSkillReduxData = useSelector(
    (item) => item?.resume?.SkillChatGpt
  );

  useEffect(() => {
    if (!skillSearch && SuggestedSkillReduxData?.length > 0) {
      setSuggestedSkills(SuggestedSkillReduxData);
      setLoadingSkills(false);
    } else {
      postChatGPTData();
    }
  }, []);

  useEffect(() => {
    // When SkillReduxData is available and has content
    if (SkillReduxData?.length > 0) {
      setSelectedSkills(SkillReduxData);
      const formattedText = SkillReduxData.map((skill) => `• ${skill}`).join(
        "\n"
      );
      setSelectedSkillText(formattedText);
    }
  }, [SkillReduxData]);
  const parseSkillList = (text) => {
    const lines = text.split("\n").filter((line) => line.trim() !== "");
    return lines.map((line) =>
      line
        .replace(/^\d+\.\s*/, "")
        .replace(/[\,\.]$/g, "")
        .trim()
    );
  };
  const postChatGPTData = async () => {
    setLoadingSkills(true);
    const experienceDetails = ResumeReduxData?.experiences
      ?.map((exp) => `${exp.role} at ${exp.company}`)
      .join("; ");
    const educationDetails = ResumeReduxData?.education
      ?.map(
        (edu) =>
          `${edu.degree} in ${edu.major} from ${edu.institute}, GPA: ${edu.gpa}`
      )
      .join("; ");
    const projectDetails = ResumeReduxData?.projects
      ?.map((proj) => `${proj.title} at ${proj.company}`)
      .join("; ");
    const activityDetails = ResumeReduxData?.involvement
      ?.map((act) => `${act.role} at ${act.company}`)
      .join("; ");

    const payload = {
      question: !!skillSearch
        ? `Given the job title ${skillSearch}" please list essential single-word skills and technologies typically required for this role in today's industry.`
        : `Given the following user profile details:\n\n- Experience: ${experienceDetails}\n- Education: ${educationDetails}\n- Projects: ${projectDetails}\n- Activities: ${activityDetails}\n\nPlease suggest fifteen skills that would be relevant for the user to include in their resume based on the information provided above.`,
      temperature: 0.1,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const parsedSkills = parseSkillList(res.data);
      setSuggestedSkills(parsedSkills);
      setLoadingSkills(false);
    } catch (error) {
      setLoadingSkills(false);
      console.error(error);
    }
  };
  const handleSelectedSkill = (item) => {
    // Check if the skill is already included in the selectedSkills
    const index = selectedSkills.indexOf(item);
    let updatedSkills = [...selectedSkills];

    if (index === -1) {
      // Skill not in selectedSkills, add it
      updatedSkills.push(item);
    } else {
      // Skill already in selectedSkills, remove it
      updatedSkills.splice(index, 1);
    }

    // Update state with the new list of selected skills
    setSelectedSkills(updatedSkills);
    // Update the displayed text accordingly
    const formattedText = updatedSkills.map((skill) => `• ${skill}`).join("\n");
    setSelectedSkillText(formattedText);
  };

  const handleTextareaChange = (e) => {
    const { value } = e.target;
    // Ensure that the input starts with a bullet if not empty
    const correctedValue = value.replace(
      /^/,
      value.length > 0 && !value.startsWith("• ") ? "• " : ""
    );
    setSelectedSkillText(correctedValue);
    const skillsArray = correctedValue
      .split("\n")
      .map((skill) => skill.replace(/^•\s*/, "").trim());
    setSelectedSkills(skillsArray);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      let newText = selectedSkillText;
      if (!newText.endsWith("\n") && !newText.endsWith("• ")) {
        newText += "\n";
      }
      newText += "• ";
      setSelectedSkillText(newText);
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = newText.length;
      }, 0);
    }
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setskillSearch(value);
  };
  const handleSubmitAndNext = () => {
    dispatch(setSkillFields(selectedSkills));
    dispatch(setSkillChatGpt(suggestedSkills));
    toast.success("Skills Saved Successfully", {
      duration: 5000, // Display the toast for 4 seconds
    });
    if (ResumeReduxData?.isResumeEdit) {
      dispatch(setResumeStep(9));
      dispatch(setIsResumeEdit(false));
    } else {
      dispatch(setResumeStep(7));
    }
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  return (
    <>
      <div className="mb-auto">
        <h1 className="primary-text text-3xl font-semibold mt-4">Add Skill</h1>
        <div className="mt-3 d-flex align-items-center gap-3 w-100">
          <BasicInput
            name="skill"
            label="Search Skills"
            value={skillSearch}
            handleChange={handleChange}
            placeholder="Search by Job title"
          />
          <AiButton handleClick={postChatGPTData} />
        </div>
        <div className="flex-row-responsive skill-container">
          <div className="w-100 primary-border rounded p-3">
            <h3 className="primary-text">Suggested (Select from below)</h3>
            <div className="mt-3 d-flex flex-wrap gap-2 ">
              {loadingSkills ? (
                <SkillsSkeleton />
              ) : (
                suggestedSkills.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelectedSkill(item)}
                    className={`skill-addBtn ${selectedSkillText?.includes(item)
                        ? "skill-addBtn-active"
                        : ""
                      } d-flex align-items-center rounded px-2 p-1`}
                  >
                    <small>{item}</small>
                    {selectedSkillText?.includes(item) ? (
                      <CiCircleMinus fontSize={20} className="ms-2" />
                    ) : (
                      <IoAddCircleOutline fontSize={20} className="ms-2" />
                    )}
                  </button>
                ))
              )}
            </div>
          </div>
          <div className="w-100 primary-border rounded p-3">
            <h3 className="primary-text mb-1">Selected</h3>
            <textarea
              id="message"
              rows="10"
              className="w-100 outline-none p-1"
              value={selectedSkillText}
              onChange={handleTextareaChange}
              onKeyPress={handleKeyPress}
              style={{ height: "90%" }}
              placeholder="Type here and hit Enter to add new skills..."
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between resume-content-footer-btn  mt-4">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          Save & Next
        </button>
      </div>
    </>
  );
}

export default Skill;
