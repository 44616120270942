import { ChatGPTAPI } from "helper/api";
import {
  setAchievementsFields,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import moment from "moment";
import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function FinishAchievement() {
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [achievementArr, setachievementArr] = useState([]);
  const [loadingAi, setloadingAi] = useState(false);
  const [editKey, seteditKey] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setachievementArr(ResumeReduxData?.achievements);
  }, [ResumeReduxData?.achievements]);

  const handleEditResumeStep = (step) => {
    dispatch(setResumeStep(step));
  };

  const handleIsResumeEdit = () => {
    dispatch(setIsResumeEdit(true));
  };
  const postChatGPTData = async (e, index, achievement) => {
    setloadingAi(true);
    e.preventDefault();
    const payload = {
      question: `Give  one ATS compliant achievement  point in past tense for the title of ${achievement?.title}. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing.give only text without any "" or '' or - `,
      temperature: 0.9,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const generatedDescription = res.data.trim().replaceAll(`"`, "");
      if (generatedDescription) {
        // Prepend bullet point if not already present
        const formattedDescription = `${generatedDescription}`;
        // Create a new array with the new description added
        const updatedDescriptions = [
          ...achievement.description,
          formattedDescription,
        ];
        // Create a new experience object with updated descriptions
        const updatedAchievement = {
          ...achievement,
          description: updatedDescriptions,
        };
        // Create a new array for the entire experiences, replacing the old experience with the updated one
        const newArr = [
          ...achievementArr.slice(0, index),
          updatedAchievement,
          ...achievementArr.slice(index + 1),
        ];
        setachievementArr(newArr); // Set the new array to the state
        toast.success("New point added ", {
          duration: 5000,
        });
      }
      setloadingAi(false);
    } catch (error) {
      setloadingAi(false);
      console.error(error);
    }
  };

  const handleDescriptionChange = (index, e) => {
    const lines = e.target.value.split("\n");

    const newDescriptions = lines
      .map(
        (line) => line.replace(/^•\s*/, "") // Remove bullets for internal storage
      )
      .filter((line) => line.trim() !== ""); // Optionally filter out empty strings

    const newArr = [...achievementArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setachievementArr(newArr);
  };

  const handleKeyDown = (index, e) => {
    const textarea = e.target;
    const value = textarea.value;
    const position = textarea.selectionStart;

    // Handling 'Enter' for new bullet points
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline behavior
      const beforeEnter = value.substring(0, position);
      const afterEnter = value.substring(position);
      const newValue = `${beforeEnter}\n• ${afterEnter}`;
      textarea.value = newValue;
      updateTextArea(index, newValue, position + 3); // Position cursor after the new bullet
    }

    // Handling 'Backspace' for bullet point removal
    else if (e.key === "Backspace") {
      if (position === 0) {
        e.preventDefault(); // Prevent backspace when at the start of the textarea
        return;
      }
      const charBeforeCursor = value.substring(position - 1, position);
      const twoCharsBeforeCursor = value.substring(position - 2, position);

      if (
        twoCharsBeforeCursor === "• " ||
        (charBeforeCursor === "\n" &&
          value.substring(position - 3, position - 1) === "•")
      ) {
        e.preventDefault();
        // Calculate new text removing the bullet or the newline directly before a bullet
        const newStartPos =
          charBeforeCursor === "\n" ? position - 3 : position - 2;
        const beforeBackspace = value.substring(0, newStartPos);
        const afterBackspace = value.substring(position);
        const newValue = `${beforeBackspace}${afterBackspace}`;
        textarea.value = newValue;
        updateTextArea(index, newValue, newStartPos);
      }
    }
  };

  const updateTextArea = (index, newValue, newCursorPos) => {
    // Update React state
    const newDescriptions = newValue.split("\n").map(
      (line) => (line.startsWith("• ") ? line.slice(2) : line) // Remove bullets for state storage
    );
    const newArr = [...achievementArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setachievementArr(newArr);

    // Update cursor position
    setTimeout(() => {
      const textarea = document.querySelectorAll("textarea")[index]; // Target the correct textarea based on index
      textarea.selectionStart = textarea.selectionEnd = newCursorPos;
    }, 0);
  };

  const handleSave = () => {
    dispatch(setAchievementsFields(achievementArr));
    seteditKey(null);
  };
  return (
    <>
      {achievementArr?.length > 0 && (
        <div className="mt-4">
          <h3 className="text-2xl font-bold">Achievements</h3>
          {/* <button
            onClick={() => {
              handleIsResumeEdit();
              handleEditResumeStep(2);
            }}
            className="primary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center text-sm"
          >
            Add/Edit Achivements
          </button> */}
          <div>
            {achievementArr?.map((item, index) => {
              return (
                <div>
                  <h5>Title - {item?.title}</h5>
                  <p>Date - {moment(item?.date).format("MMM-YYYY")}</p>
                  <div className="my-2">
                    {editKey === index ? (
                      <div className="">
                        <BasicTextAreaWithAi
                          name="description"
                          index={index}
                          handleChange={handleDescriptionChange}
                          label="Description"
                          postChatGPTData={postChatGPTData}
                          data={item}
                          loadingAi={loadingAi}
                          value={item.description
                            .map((desc) => `• ${desc}`)
                            .join("\n")}
                          // onKeyPress={handleKeyPress}
                          onKeyDown={handleKeyDown}
                          placeholder="Type each point and press Enter for a new point"
                        />
                      </div>
                    ) : (
                      <ul
                        style={{ borderLeft: "4px solid #602a94" }}
                        className="rounded p-3 position-relative d-flex bg-light ai-description-box"
                      >
                        <div className="position-relative w-100">
                          {item?.description?.map((description, idx) => (
                            <li key={idx}>
                              <span className="me-1">•</span> {description}
                            </li>
                          ))}
                        </div>
                        <span className="primary-text text-xl align-self-center px-2">
                          AI
                        </span>
                      </ul>
                    )}
                    <div className="d-flex justify-content-end gap-3 mt-3">
                      {editKey === index ? (
                        // Show Save button when editing
                        <button
                          className=" p-2 rounded text-sm secondary-btn"
                          onClick={() => handleSave()}
                        >
                          Save Description
                        </button>
                      ) : (
                        // Show Edit button when not editing
                        <button
                          className=" p-2 rounded text-sm secondary-btn"
                          onClick={() => seteditKey(index)}
                        >
                          Edit Description
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default FinishAchievement;
