import React from "react";
import "../../styles/Resume Template/resumeT2.css";
import { useDispatch, useSelector } from "react-redux";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";

const ResumeTemplate2 = () => {
  const data = useSelector((item) => item?.resume);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const monthYear = (date) => {
    if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `${day}-Jan-${year}`;
        case "2":
          return `${day}-Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };
  return (
    <div
      className="d-flex Resume2_wrapper "
      onClick={() => {
        dispatch(setTemplateNo(1));
        navigate("/create-resume");
      }}
    >
      <div className="Resume2_left ">
        <div className="d-flex Resumer2_left_head ">
          {data?.contact?.picture ? (
            <img
              src={data?.contact?.picture}
              alt="portfolio"
              height={40}
              width={40}
            />
          ) : (
            ""
          )}
          <h3>{`${data?.contact?.title ? data?.contact?.title : ""} ${data?.contact?.firstName ? data?.contact?.firstName : "john Doe"
            } ${data?.contact?.lastName ? data?.contact?.lastName : ""} `}</h3>
        </div>

        <div className="Resume2_left_feature">
          <span>Call</span> :{data?.contact?.number || 9898898898}
        </div>

        <div className="Resume2_left_feature">
          <span>Mail</span> : {data?.contact?.email || "example@gmail.com"}
        </div>

        <div className="Resume2_left_feature">
          <span>Home</span> :{" "}
          {`${data?.contact?.city ? data?.contact?.city : "CITY"} `} ,{" "}
          {`${data?.contact?.pincode ? data?.contact?.pincode : "XXXXX"} `} ,{" "}
          {`${data?.contact?.country ? data?.contact?.country : "country"} `}
        </div>

        <div className="Resume2_left_feature_summary">
          <p>
            {" "}
            <span>Summary </span>: {data?.summary ? data?.summary : ""}
          </p>
        </div>

        <div className="Resume2_left_education ">
          {data?.education?.length > 0 && <h5>Education</h5>}
          <div className="d-flex flex-column gap-2 justify-content-between">
            {data?.education?.map((item, index) => {
              return (
                <div className="d-flex flex-column Resume2_left_education w-100 ">
                  <h6>{item?.institute}</h6>
                  <p className="mb-0">{monthYear(item?.date)}</p>
                  <p className="mb-0">{item?.location}</p>
                  <p className="mb-0">{item?.name}</p>
                  <p className="mb-0">SGPA : {item?.gpa}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="Resume2_right ">
        {data?.experiences?.length > 0 && <h5>Experience</h5>}
        {data?.experiences?.map((item, index) => {
          return (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column Resume2_right_experience align-items-start">
                  <h6>{item?.company}</h6>
                  <p>
                    {monthYear(item?.start)} -{" "}
                    {!monthYear(item?.end) && item.isWorking
                      ? "Present"
                      : monthYear(item?.end)}
                  </p>
                </div>
                <div className="d-flex flex-column Resume2_right_experience align-items-end">
                  <h6>{item?.role}</h6>
                  <p className="text-end"> {item?.description}</p>
                </div>
              </div>
            </>
          );
        })}

        {data?.projects?.length > 0 && <h5>Projects</h5>}
        {data?.projects?.map((item, index) => {
          return (
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column Resume2_right_experience align-items-start">
                  <h6>{item?.title}</h6>
                  <p>
                    {monthYear(item?.start)} -{" "}
                    {!monthYear(item?.end) && item.isWorking
                      ? "Present"
                      : monthYear(item?.end)}
                  </p>
                </div>
                <div className="d-flex flex-column Resume2_right_experience align-items-end">
                  <h6>Organisation - {item?.company} </h6>
                  <p className="text-end">{item?.description}s </p>
                </div>
              </div>
            </>
          );
        })}

        <div className="d-flex">
          <div className="d-flex flex-column">
            {data?.skills?.length > 0 && <h5>Skills</h5>}

            {data?.skills?.map((skill, index) => (
              <li key={index} style={{ listStyle: "none" }}>
                <span className="me-1">•</span>{" "}
                {typeof skill === "object" ? skill.name : skill}
              </li>
            ))}
          </div>

          <div className="d-flex flex-column">
            {data?.hobby?.length > 0 && <h5>Hobbies</h5>}
            <div>
              <ul
                className="list-style-none px-0"
                style={{ listStyle: "none" }}
              >
                {data?.hobby?.map((hobby, index) => (
                  <li key={index}>
                    <span className="me-1">•</span>{" "}
                    {typeof hobby === "object" ? hobby.name : hobby}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeTemplate2;
