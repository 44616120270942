import React, { useEffect, useRef, useState } from "react";
import FinishExpandedData from "./FinishExpandedData";
// import OtherAccordian from "./OtherAccordian";
import { FaFileDownload } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  ChatGPTAPI,
  generatePDF,
  PostTrailResume,
  UpdateTrailResume,
} from "helper/api";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Statistics from "./Statistics";
import {
  decrementPendingRequests,
  GetAchievementAiResponseData,
  GetActivityAiResponseData,
  GetExperienceAiResponseData,
  GetProjectAiResponseData,
  GetSummaryAiResponseData,
  incrementPendingRequests,
  resetCompletedCalls,
  setIsLoadingAiData,
  setPendingRequests,
} from "helper/redux/Slice/ResumeSlice";
import AiLoader from "../../../../assets/image/AiLoader.svg";
import { useReactToPrint } from "react-to-print";
function Finish({ printResumeTemplate }) {
  let ResumeData = useSelector((item) => item?.resume);
  const dispatch = useDispatch();
  const CoverData = useSelector((item) => item.cover);
  const { id } = useParams();
  const types = useSelector((item) => item.types);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Template, setTemplate] = useState({});
  const [highlightAiData, sethighlightAiData] = useState(false);
  const navigate = useNavigate();
  const LoadingAi = ResumeData?.isLoadingAiData;

  const handleHighlightAiClose = () => sethighlightAiData(false);
  const handleHighlightAiShow = () => sethighlightAiData(true);

  // const exportPDFWithComponent = async () => {
  //   try {
  //     setLoading(true);
  //     const data = {
  //       ResumeData,
  //       Template: 1,
  //       doctype: types?.doctype,
  //       CoverData,
  //     };

  //     // Call the API to generate the PDF
  //     const response = await generatePDF(data);

  //     if (response.status === 200) {
  //       const { pdfUrl } = await response.data;
  //       const fullpdfurl = process.env.REACT_APP_API_URL + pdfUrl;

  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = fullpdfurl;
  //       document.body.appendChild(downloadLink);
  //       downloadLink.download = "document.pdf";
  //       downloadLink.type = "application/pdf";
  //       downloadLink.target = "_blank"; // Open the link in a new tab
  //       downloadLink.click();
  //       document.body.removeChild(downloadLink);
  //       // document.body.removeChild(downloadLink);
  //       console.log("PDF downloaded successfully");
  //     } else {
  //       // Handle error
  //       const errorMessage = await response.text();
  //       console.error("Failed to generate PDF,", errorMessage);
  //     }
  //   } catch (error) {
  //     console.error("Error during PDF generation:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const PostDataAndUpdate = async () => {
    setloadingSubmit(true);
    handleFinalResumeShow();
    const payload = {
      resumeId: !!id ? id : "",
      name: ResumeData.contact.firstName,
      template: ResumeData?.template,
      color: ResumeData.color,
      type: ResumeData.firstName,
      profession: ResumeData?.profession,
      jobProfile: ResumeData?.jobProfile,
      desiredCompany: ResumeData?.desiredCompany,
      contact: {
        picture: ResumeData?.contact?.picture,
        firstName: ResumeData?.contact?.firstName,
        lastName: ResumeData?.contact?.lastName,
        number: ResumeData?.contact?.number,
        email: ResumeData?.contact?.email,
        title: ResumeData?.contact?.title,
        city: ResumeData?.contact?.city,
        address: ResumeData?.contact?.address,
        pincode: ResumeData?.contact?.pincode,
        country: ResumeData?.contact?.country,
      },
      experiences: ResumeData?.experiences,
      achievements: ResumeData?.achievements,
      links: ResumeData?.links,
      languagesKnown: ResumeData?.languagesKnown,
      projects: ResumeData?.projects,
      researchPapers: ResumeData?.researchPapers,
      education: ResumeData?.education,
      certification: ResumeData?.certification,
      involvement: ResumeData?.involvement,
      hobby: ResumeData?.hobby,
      languagesKnown: ResumeData?.languagesKnown,
      skills: ResumeData?.skills,
      summary: ResumeData?.summary,
      formatting: 80,
      wordChoice: ResumeData?.wordChoice,
      noTypos: 80,
      completeness: ResumeData?.completeness,
      optimization: ResumeData?.optimization,
      atsCompliance: ResumeData?.atsCompliance,
      clearContactInfo: ResumeData?.clearContactInfo,
      strongSummary: ResumeData?.strongSummary,
      length: ResumeData?.length,
    };
    try {
      let res;
      if (!!id) {
        res = await UpdateTrailResume(payload);
      } else {
        res = await PostTrailResume(payload);
      }
      console.log(res);
      setloadingSubmit(false);
    } catch (error) {
      console.log(error);
      // setspinn(false);
    }
  };
  const [FinalResume, setFinalResume] = useState(false);

  const handleFinalResumeClose = () => setFinalResume(false);
  const handleFinalResumeShow = () => setFinalResume(true);

  const [showEdit, setshowEdit] = useState(true);

  // calling ai generated data
  useEffect(() => {
    const fetchAiData = () => {
      // Dispatch the three API calls
      postSummaryGPTData();
      if (ResumeData?.experiences?.length > 0) {
        postExperienceGptData();
      }
      if (ResumeData?.projects?.length > 0) {
        postProjectGPTData();
      }
      if (ResumeData?.achievements?.length > 0) {
        postAchievementGptData();
      }
      if (ResumeData?.involvement?.length > 0) {
        postActivityGptData();
      }
      sethighlightAiData(true);
    };
    // Check if AI-generated data is missing before fetching
    const needsAiData =
      ResumeData?.skills?.length > 0 || // Check if summary is missing
      ResumeData?.experiences?.length > 0 ||
      ResumeData?.projects?.length > 0 ||
      ResumeData?.achievements?.length > 0 ||
      ResumeData?.involvement?.length > 0; // Check if any experience is missing a description
    const requireAIData = ResumeData?.experiences?.some(
      (item) => item.description.length > 0
    );
    console.log(requireAIData);
    if (needsAiData && !id && !requireAIData) {
      console.log("chat gpt called");
      fetchAiData(); // Trigger ChatGPT API calls
    }
  }, []);

  // useEffect(() => {
  //   // Check if AI-generated data is missing before fetching
  //   const needsAiData =
  //     !id && // Only proceed if id is not present
  //     (!ResumeData?.summary || // Check if summary is missing
  //       !ResumeData?.experiences?.some((exp) => exp.description?.length > 0)); // Check if any experience is missing a description

  //   if (needsAiData) {
  //     setloadingAi(true);

  //     const timer = setTimeout(() => {
  //       setloadingAi(false);
  //       sethighlightAiData(true); // Show modal only if data was missing initially
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  // // **New Effect: Stop Loading Immediately When Data Updates**
  // useEffect(() => {
  //   const hasValidData =
  //     ResumeData?.summary &&
  //     ResumeData?.experiences?.some((exp) => exp.description?.length > 0);

  //   if (hasValidData) {
  //     setloadingAi(false);
  //   }
  // }, []);

  // ------------------------Activity-------------------------------

  const postActivityGptData = async (index, activity) => {
    const activityDetails = ResumeData?.involvement
      ?.map(
        (act) =>
          `Activity Type: ${act?.origin}, Role: ${act?.role}${act?.company ? `, Company: ${act?.company}` : ""
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_activity_details",
        description:
          "Format activity section into a JSON array where each object contains a 'description' field with exactly 3 bullet points.",
        parameters: {
          type: "object",
          properties: {
            involvement: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly 3 bullet points summarizing the activity.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point highlighting a key responsibility, accomplishment, or skill demonstrated in the role.",
                    },
                    minItems: 3,
                    maxItems: 3,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["activities"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each activity listed. Each activity should be summarized in exactly 3 bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must be between 30-50 words. Please format the responses as RFC8259-compliant JSON objects, where each object corresponds to an activity and contains a 'description' field with an array of 3 bullet points.

        Activity Details:
        ${activityDetails}

        Please create a JSON array where each element is a JSON object for a specific activity. Each object should contain a 'description' field with an array of 3 bullet points. Ensure the number of JSON objects matches the number of activities provided. 

        Expected JSON output format:
        [
            {
                "description": [
                    "Bullet point 1",
                    "Bullet point 2",
                    "Bullet point 3"
                ]
            }
        ]

        Please ensure the output strictly follows this format, and each activity is summarized in exactly 3 bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_activity_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetActivityAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };

  // ------------------------Achievement-------------------------------

  const postAchievementGptData = async (index, achievement) => {
    // Assuming ResumeData.achievements is an array of achievements with title and date
    const achievementDetails = ResumeData?.achievements
      ?.map((ach) => `Title: ${ach.title}, Date: ${ach.date}`)
      .join("; ");

    const function_descriptions = [
      {
        name: "format_achievement_details",
        description:
          "Format achievement into a JSON array where each object contains a 'description' field with exactly 2 bullet points.",
        parameters: {
          type: "object",
          properties: {
            achievements: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly 2 bullet points summarizing the achievement.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point highlighting a key responsibility, accomplishment, or skill demonstrated in the achievement.",
                    },
                    minItems: 2,
                    maxItems: 2,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["achievements"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each achievement listed. Each achievement should be summarized in exactly 2 bullet points, highlighting key responsibilities, accomplishments demonstrated in the role. Each bullet point must not exceed 50 words and have at least 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to an achievement and contains a 'description' field with an array of 2 bullet points.
        
        Achievement Details:
        ${achievementDetails}
        
        Please create a JSON array where each element is a JSON object for a specific achievement. Each object should contain a 'description' field with an array of 2 bullet points. Ensure the number of JSON objects matches the number of achievements provided. For example:
        
        - If there is only one achievement, the output should be a JSON array with one object.
        - If there are multiple achievements, the output should be a JSON array with multiple objects, each corresponding to an achievement.
        
        Expected JSON output format:
        [
            {
                "description": [
                    "",
                    ""
                ]
            }
        ]
        
        Please ensure the output strictly follows this format and each achievement is summarized in exactly 2 bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_achievement_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetAchievementAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };

  // ------------------------Experience-------------------------------

  const postExperienceGptData = async (index, experience) => {
    const experienceDetails = ResumeData?.experiences
      ?.map(
        (exp) =>
          `Role: ${exp.role}, Company: ${exp.company}, Job Type: ${exp.jobType
          }, Location: ${exp.location}, Start: ${exp.start}, End: ${exp.end || "Present"
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_experience_details",
        description:
          "Format professional experiences into a JSON array where each object contains a 'description' field with exactly five bullet points.",
        parameters: {
          type: "object",
          properties: {
            experiences: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly three bullet points summarizing the experience.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point  highlighting a key responsibility, accomplishment, or skill demonstrated in the role.",
                    },
                    minItems: 5,
                    maxItems: 5,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["experiences"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each professional experience listed. Each experience should be summarized in exactly five bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must not exceed 50 words and have atleast 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to an experience and contains a 'description' field with an array of five bullet points.
  
  Experience Details:
  ${experienceDetails}
  ${ResumeData?.jobSpecificToggle ? `      here is the job description provided by the company , tailor the pointers according to this Job Description 
  Job Description :${ResumeData?.jobDescription} ` : ""}
  
  Please create a JSON array where each element is a JSON object for a specific experience. Each object should contain a 'description' field with an array of five bullet points. Ensure the number of JSON objects matches the number of experiences provided. For example:
  
  - If there is only one experience, the output should be a JSON array with one object.
  - If there are multiple experiences, the output should be a JSON array with multiple objects, each corresponding to an experience.
  
  Expected JSON output format:
  [
    {
      "description": [
        "",
        "",
        "",
        "",
        ""
      ]
    }
  ]
  
  Please ensure the output strictly follows this format and each experience is summarized in exactly five bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_experience_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetExperienceAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };

  // ------------------------Project-------------------------------

  const postProjectGPTData = async () => {
    const projectDetails = ResumeData?.projects
      ?.map(
        (project) =>
          `  Title: ${project?.title},Role:${project?.role} Company: ${project?.company
          }, Job Type: ${project?.jobType}, Start: ${project?.start}, End: ${project?.end || "Present"
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_project_details",
        description:
          "Format project details into a JSON array where each object contains a 'description' field with exactly five bullet points summarizing the project.",
        parameters: {
          type: "object",
          properties: {
            projects: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly three bullet points summarizing the project.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point  highlighting a key responsibility, accomplishment, or skill demonstrated in the project.",
                    },
                    minItems: 5,
                    maxItems: 5,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["projects"],
        },
      },
    ];

    const payload = {
      question: `Please generate concise bullet points for each project listed. Each project should be summarized in exactly five bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the project. Each bullet point must not exceed 50 words and atleast have 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to a project and contains a 'description' field with an array of five bullet points.

Project Details:
${projectDetails}

Please create a JSON array where each element is a JSON object for a specific project. Each object should contain a 'description' field with an array of five bullet points. Ensure the number of JSON objects matches the number of projects provided.

Expected JSON output format:
[
  {
    "description": ["Bullet point 1", "Bullet point 2", "Bullet point 3","Bullet point 4","Bullet point 5"]
  }
]

Please ensure the output strictly follows this format and each project is summarized in exactly five bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_project_details",
    };
    try {
      // const result = await ChatGPTAPI(payload); // Assuming ChatGPTAPI is your method to call the API
      // console.log(result);
      dispatch(GetProjectAiResponseData(payload));
    } catch (error) {
      console.error(error);
    }
  };
  function calculateTotalExperience(experiences) {
    let totalMonths = 0;

    experiences?.forEach((exp) => {
      const start = new Date(exp.start);
      const end = exp.end ? new Date(exp.end) : new Date(); // assuming current date if end is not defined
      let months = (end.getFullYear() - start.getFullYear()) * 12;
      months -= start.getMonth();
      months += end.getMonth();
      totalMonths += months;
    });

    if (totalMonths < 12) {
      return `${totalMonths} month${totalMonths === 1 ? "" : "s"}`;
    } else {
      const years = Math.floor(totalMonths / 12);
      const remainingMonths = totalMonths % 12;
      return `${years} year${years === 1 ? "" : "s"
        } and ${remainingMonths} month${remainingMonths === 1 ? "" : "s"}`;
    }
  }
  const totalExperience = calculateTotalExperience(ResumeData?.experiences);

  //   summary prompt

  let EducationForPrompt = "";
  ResumeData?.education?.map(
    (item) =>
      (EducationForPrompt += item?.degree + ", " + item?.specialization + ";")
  );
  let ExperienceForPrompt = "";
  ResumeData?.experiences?.map(
    (item) =>
    (ExperienceForPrompt +=
      item?.jobtype + ", " + item?.role + ", " + item?.company + ";")
  );
  let CertificationForPrompt = "";
  ResumeData?.certification?.map(
    (item) => (CertificationForPrompt += item?.name + ", ")
  );

  let skillsForPrompt = "";
  ResumeData?.skills?.map((item) => (skillsForPrompt += item + ", "));

  const isFresher =
    !ResumeData?.experiences?.length || totalExperience === "0 months";
  //   isFresher
  //     ? `following resume details:
  //       Personal Information: ${ResumeData?.contact?.firstName}, ${ResumeData?.contact?.lastName}
  //       Education: ${EducationForPrompt}
  //       Skills: ${skillsForPrompt}
  //       Certifications: ${CertificationForPrompt}

  //       Note (important):
  //       write a short summary of 70 words for resume as fresher for this resume . giving you the refrence for the output . provide summary similar to below . give the summary in the format below , and without "" or ''. just give one paragraph of at max 70 words .

  //       Example summary:
  //       Aspiring Management Trainee with a keen interest in organizational management. Eager to apply academic knowledge in real-world scenarios. Strong analytical, problem-solving, and communication skills combined with a proactive attitude and a willingness to learn and grow within a dynamic and fast-paced environment.
  //       `
  //     : `You've been provided with a sample resume in the following format:
  //           1. Personal Information: ${ResumeData?.contact?.firstName}, ${ResumeData?.contact?.lastName}
  //           2. Work Experience: ${ExperienceForPrompt}
  //           3. Education: ${EducationForPrompt}
  //           6. Skills: ${skillsForPrompt}
  //           7. Certifications: ${CertificationForPrompt}
  //           8. total experience : ${totalExperience}

  //           Note :
  //         write a short summary of 70 words for resume with ${totalExperience} of Experience. giving you the refrence for the output . provide summary similar to below .
  //         give the summary in the format below , and without "" or ''. just give one paragraph of at max 70 words .
  // Referrence for experienced:
  //       Experienced Management Trainee with expertise in applying leadership and technical skills in dynamic business environments. Skilled in data analysis, problem-solving, and strategic planning. Proven ability to lead cross-functional teams and deliver business growth through innovative solutions. Committed to continuous learning and professional development.`;
  const prompt = `Create a concise resume summary tailored for a job application. Limit the summary to no more than 30 words and write in a professional tone, without using quotes or special formatting, give response only in text(important) .

Resume Details:
- Name: ${ResumeData?.contact?.firstName} ${ResumeData?.contact?.lastName}
- Education: ${EducationForPrompt}
- Key Skills: ${skillsForPrompt}
- Certifications: ${CertificationForPrompt}
- Work Experience Summary: ${ExperienceForPrompt}
- Total Professional Experience: ${totalExperience}
Guidance:
- For a Fresher: Emphasize academic achievements, relevant skills, and a strong eagerness to learn and contribute to organizational success.
- For an Experienced Candidate: Highlight significant career accomplishments, specialized skills, and a commitment to advancing organizational objectives.

Please tailor the summary directly for potential employers and ensure it succinctly encapsulates the candidate’s qualifications and career aspirations within the 30-word limit.`;

  const postSummaryGPTData = async (e) => {
    const payload = {
      question: prompt,
      temperature: 0.3,
      max_tokens: 1500,
    };

    try {
      dispatch(GetSummaryAiResponseData(payload));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {LoadingAi ? (
        <div
          className="d-flex justify-content-center w-100 h-100 align-items-center"
        // style={{ height: "100vh" }}
        >
          <img src={AiLoader} alt="" style={{ height: "10%", width: "10%" }} />
          <p>Generating AI Resume...</p>
        </div>
      ) : (
        <>
          <h1 className="primary-text text-3xl font-semibold mt-4 d-flex justify-content-between">
            Finish Up
          </h1>
          <div className="primary-border w-100 mt-4 p-3 rounded d-flex justify-content-between">
            <div className="d-flex gap-3 align-items-center">
              <button
                onClick={() => setshowEdit(true)}
                className={`secondary-border p-2 rounded text-sm primary-text align-self-end d-flex align-items-center ${showEdit ? "secondary-btn" : ""
                  } `}
              >
                Edit
              </button>
              <button
                onClick={() => setshowEdit(false)}
                className={`secondary-border p-2 rounded text-sm primary-text align-self-end d-flex align-items-center ${!showEdit ? "secondary-btn" : ""
                  } `}
              >
                Statistics
              </button>
            </div>
            <div className="d-flex gap-3 align-items-center">
              <button
                onClick={printResumeTemplate}
                className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
              >
                Export <FaFileDownload className="ms-2" fontSize={16} />
              </button>
              {!!id ? (
                <button
                  className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
                  onClick={PostDataAndUpdate}
                >
                  Update
                  {loadingSubmit ? (
                    <Spinner size="sm" className="ms-2" />
                  ) : (
                    <IoCheckmarkCircle className="ms-2" fontSize={18} />
                  )}
                </button>
              ) : (
                <button
                  className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
                  onClick={PostDataAndUpdate}
                >
                  Submit
                  {loadingSubmit ? (
                    <Spinner size="sm" className="ms-2" />
                  ) : (
                    <IoCheckmarkCircle className="ms-2" fontSize={18} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="mb-5 mt-4 d-flex  flex-column  w-100 gap-x-14  resume-step-content">
            {showEdit ? <FinishExpandedData /> : <Statistics />}
          </div>
          {/* <div className=" d-flex justify-content-end ">
        <button className="primary-btn rounded px-3 p-2">Next</button>
      </div> */}
          <Modal
            show={FinalResume}
            onHide={handleFinalResumeClose}
            centered
            keyboard={false}
            backdrop="static"
          >
            <Modal.Body className="experience_modal rounded">
              <div className="experience_modal_container">
                {/* <img src={firework} alt="crown" /> */}
                <h6 className="my-4 text-center">
                  Congratulations !{" "}
                  {!!id ? "Resume updated" : "Your resume is ready"}
                </h6>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center ">
              <button
                className="primary-btn p-2 rounded"
                onClick={() => {
                  navigate("/");
                  handleFinalResumeClose();
                }}
                variant="light"
              >
                Go to dashboard{" "}
              </button>
            </Modal.Footer>
          </Modal>

          {/* highlight ai data  */}

          <Modal
            show={highlightAiData}
            onHide={handleHighlightAiClose}
            centered
            keyboard={false}
            backdrop="static"
            size="lg"
          >
            <Modal.Body className="">
              <div className="">
                {/* <img src={firework} alt="crown" /> */}
                <h6 className="my-2 px-3 primary-text text-2xl ">
                  Take a look at your AI powered resume
                </h6>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center ">
              <button
                className="primary-btn p-2 rounded"
                onClick={() => {
                  handleHighlightAiClose();
                }}
                variant="light"
              >
                Review{" "}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Finish;

// Given the list of Projects, generate exactly 3 (important) concise bullet points for each experience. These bullet points should highlight key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must not exceed 30 words.

// Project Details:
// ${projectDetails}

// Please ensure that the output is a single JSON object containing exactly 3 (important) bullet points within a 'description' field. The number of description objects should exactly match the number of experiences provided.

// Example JSON output format for a single experience:
// [
//   {
//     "description": ["Point 1", "Point 2", "Point 3"]
//   },....
// ]

// Given the list of professional experiences, generate exactly 3  concise bullet points for each experience. These bullet points should highlight key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must not exceed 30 words.

// Experience Details:
// ${experienceDetails}

// Please ensure that the output is a  JSON object containing exactly 3 bullet points within 'description' field. The number of description objects should exactly match the number of experiences provided.

// Example JSON output format for a single experience:
// [
//   {
//     "description": ["Point 1", "Point 2", "Point 3"]
//   } ,....
// ]
