import UserSidebar from "New Ui/Common/UserSidebar";
import React, { useRef, useState } from "react";
import Contact from "./Steps to resume/Contact";
import { useDispatch, useSelector } from "react-redux";
import Experience from "./Steps to resume/Experience/Experience";
import { IoMdArrowRoundBack } from "react-icons/io";
import Education from "./Steps to resume/Education/Education";
import Project from "./Steps to resume/Project/Project";
import Activity from "./Steps to resume/Activity/Activity";
import Skill from "./Steps to resume/Skill/Skill";
import Other from "./Steps to resume/Other/Other";
import Finish from "./Steps to resume/Finish/Finish";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  GetTrailResumeEditData,
  setContactRedux,
  setCreateResume,
} from "helper/redux/Slice/ResumeSlice";
import ResumeTemplateJson from "contants/ResumeTemplateJson";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import RT2non from "components/ResumeTemplate/T2/RT2non";
import ResumeCard from "New Ui/Common/Cards/ResumeCard";
import {
  GetTemplateNoByCollegeId,
  PostTrailResume,
  UpdateTrailResume,
} from "helper/api";
import Cookies from "js-cookie";
import { RxCross2 } from "react-icons/rx";
import MobileDropdownSidebar from "New Ui/Common/MobileSidebar";
import { AiOutlineEye } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import JobSpecific from "./Steps to resume/JobSpecific/JobSpecific";

function StepsWrapper() {
  const param = useParams();
  const dispatch = useDispatch();
  const [ResumeTemplateSideView, setResumeTemplateSideView] = useState();
  const ResumeRedux = useSelector((item) => item.resume);
  const stepResume = useSelector((item) => item.utils.stepResume);
  const Types = useSelector((state) => state.types);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplate, setselectedTemplate] = useState({});

  useEffect(() => {
    if (!!param?.id && Types?.docsubtype === "trailResume") {
      dispatch(GetTrailResumeEditData(param?.id));
    }
  }, [param?.id]);

  useEffect(() => {
    setResumeTemplateSideView(
      ResumeTemplateJson?.filter(
        (item) => Number(item?.templateNo) === Number(ResumeRedux?.template)
      )[0]
    );
  }, [ResumeRedux.template]);

  const OtherTemplates = [
    { templateNo: 0, component: <RT1non />, templateEdit: <RT1editable /> },
    { templateNo: 1, component: <RT2non />, templateEdit: <RT2editable /> },
  ];

  const handleSelectedTemplate = (templateNo, component) => {
    dispatch(setCreateResume({ type: "template", value: templateNo }));
    // dispatch(setTemplate({ component })); // Dispatch the selected template component
    // handleResumeStep(3); // Move to the next step
  };
  console.log(ResumeTemplateSideView);
  let cookiedata;

  if (!!localStorage.getItem("user2")) {
    cookiedata = JSON.parse(localStorage.getItem("user2"));
  } else {
    cookiedata = JSON.parse(Cookies.get("user"));
  }
  console.log(cookiedata);
  const collegeid = cookiedata.collegeid._id;
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        // const response = await axios.get(`/api/user-templates/${collegeid}`);
        const response = await GetTemplateNoByCollegeId(collegeid);
        console.log(response);
        const { templateNos } = response.data;
        console.log("Fetched Template Nos:", templateNos);

        const matchedTemplates = ResumeTemplateJson.filter((template) =>
          templateNos.includes(template.templateNo)
        );
        console.log("matchTemplate Nos:", matchedTemplates);
        setFilteredTemplates(matchedTemplates);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchTemplates();
  }, []);

  // modals
  // change template
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Preview
  const [showPreview, setshowPreview] = useState(false);

  const handlePreviewClose = () => setshowPreview(false);
  const handlePreviewShow = () => setshowPreview(true);

  const { id } = useParams();
  const PostDataAndUpdate = async () => {
    const payload = {
      resumeId: !!id
        ? id
        : !!localStorage?.getItem("resumeId")
        ? localStorage?.getItem("resumeId")
        : "",
      name: ResumeRedux.contact.firstName,
      template: ResumeRedux?.template,
      color: ResumeRedux.color,
      type: ResumeRedux.firstName,
      profession: ResumeRedux?.profession,
      jobProfile: ResumeRedux?.jobProfile,
      desiredCompany: ResumeRedux?.desiredCompany,
      contact: {
        picture: ResumeRedux?.contact?.picture,
        firstName: ResumeRedux?.contact?.firstName,
        lastName: ResumeRedux?.contact?.lastName,
        number: ResumeRedux?.contact?.number,
        email: ResumeRedux?.contact?.email,
        title: ResumeRedux?.contact?.title,
        city: ResumeRedux?.contact?.city,
        address: ResumeRedux?.contact?.address,
        pincode: ResumeRedux?.contact?.pincode,
        country: ResumeRedux?.contact?.country,
      },
      experiences: ResumeRedux?.experiences,
      achievements: ResumeRedux?.achievements,
      links: ResumeRedux?.links,
      languagesKnown: ResumeRedux?.languagesKnown,
      projects: ResumeRedux?.projects,
      researchPapers: ResumeRedux?.researchPapers,
      education: ResumeRedux?.education,
      certification: ResumeRedux?.certification,
      involvement: ResumeRedux?.involvement,
      hobby: ResumeRedux?.hobby,
      languagesKnown: ResumeRedux?.languagesKnown,
      skills: ResumeRedux?.skills,
      summary: ResumeRedux?.summary,
      formatting: 80,
      wordChoice: ResumeRedux?.wordChoice,
      noTypos: 80,
      completeness: ResumeRedux?.completeness,
      optimization: ResumeRedux?.optimization,
      atsCompliance: ResumeRedux?.atsCompliance,
      clearContactInfo: ResumeRedux?.clearContactInfo,
      strongSummary: ResumeRedux?.strongSummary,
      length: ResumeRedux?.length,
    };
    try {
      let res;
      if (!!id) {
        res = await UpdateTrailResume(payload);
      } else {
        res = await PostTrailResume(payload);
        localStorage.setItem("resumeId", res?.data?.data?._id);
      }
    } catch (error) {
      console.log(error);
      // setspinn(false);
    }
  };

  const contentRef = useRef();
  const printResumeTemplate = useReactToPrint({
    content: () => contentRef.current, // ✅ Ensure correct reference
    documentTitle: "Resume", // Optional: Set document title
    onAfterPrint: () => console.log("Printed Successfully!"), // Optional: Callback after print
    removeAfterPrint: true,
  });
  return (
    <>
      <div
        className="preview-btn"
        onClick={handlePreviewShow}
        style={{
          border: "none",
          background: "none",
          position: "absolute",
          top: "98px",
          left: "85%",
        }}
      >
        <AiOutlineEye className="mobile-only-icon" fontSize={28} />
      </div>
      <div className="resume-steps-wrapper col-12 d-flex">
        <UserSidebar />
        <div className="w-100 d-flex resume-step-preview-wrapper">
          <div className="w-100 pt-0 px-lg-5 px-3 resume-step-content-wrapper">
            <div className="back-btn-container pt-4">
              <MobileDropdownSidebar />
              {/* {stepResume > 1 && (
              <button
                className="primary-btn  p-2 px-3 rounded  d-flex "
                onClick={handleBackBtn}
              >
                <IoMdArrowRoundBack />
              </button>
            )} */}
            </div>
            {stepResume === 1 ? <Contact /> : ""}
            {stepResume === 2 ? <Experience /> : ""}
            {stepResume === 3 ? <Education /> : ""}
            {stepResume === 4 ? <Project /> : ""}
            {stepResume === 5 ? <Activity /> : ""}
            {stepResume === 6 ? <Skill /> : ""}
            {stepResume === 7 ? <Other /> : ""}
            {stepResume === 8 ? <JobSpecific /> : ""}
            {stepResume === 9 ? (
              <Finish printResumeTemplate={printResumeTemplate} />
            ) : (
              ""
            )}
          </div>
          <div className="preview  mt-5 ps-3 pe-4">
            <div className="d-flex mt-5 justify-content-between">
              <h4
                className="primary-text rounded font-semibold  mb-3   cursor-pointer"
                onClick={handleShow}
              >
                Change Template
              </h4>
              <h4
                className="primary-text rounded font-semibold mb-3 cursor-pointer"
                onClick={handlePreviewShow}
              >
                Preview
              </h4>
            </div>
            <div
              className="preview-card primary-border rounded overflow-hidden p-1"
              style={{ cursor: "zoom-in" }}
              onClick={handlePreviewShow}
            >
              <div className="h-100 w-100" ref={contentRef}>
                <style>
                  {`
                @page {
                  size: A4;
                  margin:6mm
                }
                @media print {
                 
                  html, body {
                    margin: 0 !important;
                    padding: 0 !important;
                    height: auto;
                  }
                  .resume-template {
                    width: 100%;
                    margin: auto;
                    padding: 10mm;
                    page-break-after: avoid;
                  }
                  .resume-section {
                    page-break-before: always;
                  }
                }
              `}
                </style>
                {!!ResumeTemplateSideView?.templateEdit && (
                  <ResumeTemplateSideView.templateEdit />
                )}
              </div>

              {/* Update based on default state */}
            </div>
          </div>
        </div>
        <Modal show={show} centered size="xl" onHide={handleClose}>
          <Modal.Body className="position-relative">
            <button className="modal-closing-btn" onClick={handleClose}>
              <RxCross2 fontSize={28} />
            </button>
            <div className="change-template-section   d-flex gap-4">
              <div className="change-template-left w-75 ps-4">
                <h2 className="text-center primary-text text-2xl pb-3 font-semibold">
                  Change Template
                </h2>
                <div className="change-template-alltemplates">
                  <h5 className="my-3 primary-text text-md font-semibold ">
                    College Template
                  </h5>
                  <div className="d-flex  gap-8 flex-wrap">
                    {filteredTemplates.map((item, index) => (
                      <ResumeCard
                        key={index}
                        component={item.template}
                        data={item}
                        handleSelectedTemplate={() =>
                          handleSelectedTemplate(
                            item.templateNo,
                            item.templateEdit
                          )
                        }
                      />
                    ))}
                  </div>
                  <h5 className="mb-3 mt-4 primary-text text-md font-semibold">
                    Other Template
                  </h5>
                  <div className="d-flex  gap-8 flex-wrap">
                    {OtherTemplates?.map((item, index) => (
                      <div
                        className="mb-3"
                        key={index}
                        onClick={() =>
                          handleSelectedTemplate(
                            item.templateNo,
                            item.templateEdit
                          )
                        }
                      >
                        <ResumeCard component={item.component} data={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-50 primary-bg p-2 h-100 ">
                <h2 className="text-center primary-text text-2xl pb-3 font-semibold">
                  Preview
                </h2>
                {/* {selectedTemplate} */}
                <div className="preview-card  primary-border rounded overflow-hidden p-1">
                  {!!ResumeTemplateSideView?.templateEdit && (
                    <ResumeTemplateSideView.templateEdit />
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size="lg"
          show={showPreview}
          onHide={handlePreviewClose}
        >
          <Modal.Body className="position-relative">
            <button
              className="modal-closing-btn"
              style={{ top: "-2%" }}
              onClick={handlePreviewClose}
            >
              <RxCross2 fontSize={28} />
            </button>
            <div>
              <h2 className="text-center primary-text text-2xl pb-3 font-semibold">
                Resume preview
              </h2>
              <div className="  primary-border rounded overflow -hidden p-1">
                {!!ResumeTemplateSideView?.templateEdit && (
                  <ResumeTemplateSideView.templateEdit />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>{" "}
      </div>
    </>
  );
}

export default StepsWrapper;
