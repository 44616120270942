import React, { useState, useEffect, useRef } from "react";
import CommonDashboardCard from "common/CommonDashboardCard";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAiResume,
  DeleteAutomatedResume,
  DeleteTrailResume,
  GetAiResume,
  GetAutomatedResume,
  GetTrailResume,
} from "helper/api";
import rankbar from "../../../assets/image/rankbar.png";
import LoadingComponent from "common/LoadingComponent";
import { setDocSubType } from "helper/redux/Slice/TypeSlice";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { setBaseStep, setResumeStep } from "helper/redux/Slice/UtilsSlice";
import { useNavigate } from "react-router";
import MainLoader from "New Ui/Common/Loader/MainLoader";

const ResumeSection = ({ handleStep }) => {
  const Type = useSelector((item) => item.types);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const [datanotPresent, setdatanotPresent] = useState(false);
  const [ResumeCardData, setResumeCardData] = useState([]);

  //crud apis
  //getting resume data subdoctype wise
  const getResumeData = async () => {
    setLoading(true);
    try {
      let data;
      if (Type?.docsubtype === "trailResume") {
        data = await GetTrailResume();
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        }
        setResumeCardData(data?.data?.data);
      } else if (Type?.docsubtype === "automatedResume") {
        data = await GetAutomatedResume();
        console.log(data);
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        } else {
          setResumeCardData(data?.data?.data);
        }
      } else {
        data = await GetAiResume();
        if (data?.data?.data?.length === 0) {
          setdatanotPresent(true);
        }
        setResumeCardData(data?.data?.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getResumeData();
  }, [Type?.docsubtype]);
  useEffect(() => {
    dispatch(setResumeStep(1));
  }, []);

  if (Type?.docsubtype === "") {
    dispatch(setDocSubType("trailResume"));
  }

  const handleDeleteResume = async (id) => {
    try {
      const payload = {
        id: id,
      };
      setResumeCardData(() =>
        ResumeCardData?.filter((item) => item._id !== id)
      );
      if (Type?.docsubtype === "trailResume") {
        await DeleteTrailResume(payload);
        toast.success("Resume is successfully deleted.");
      } else if (Type?.docsubtype === "aiResume") {
        await DeleteAiResume(payload);
      } else {
        await DeleteAutomatedResume(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const handleCreateResume = () => {
    dispatch(setBaseStep(2));
    navigate("/create");
  };
  console.log();
  return (
    <>
      <div className="d-flex  col-12  justify-content-between w-100">
        <div className="w-100   ">
          <div className="col-12  d-flex justify-content-center position-relative my-4">
            <div
              className={` flex-wrap w-100 ${
                ResumeCardData?.length > 0
                  ? "justify-content-evenly"
                  : "justify-content-start"
              }   px-lg-5 px-3 d-flex  gap-4 `}
              style={{ minHeight: "60vh" }}
            >
              {!Loading ? (
                <>
                  <div
                    onClick={handleCreateResume}
                    className="resume-card-responsive"
                  >
                    <CommonDashboardCard cardFor={"create"} />
                  </div>
                  {/* <CommonDashboardCard cardFor={"upload"} /> */}
                  {ResumeCardData.length > 0
                    ? ResumeCardData?.map((data, index) => (
                        <>
                          <CommonDashboardCard
                            data={data}
                            handleDeleteResume={handleDeleteResume}
                          />
                        </>
                      ))
                    : datanotPresent && (
                        <div className="mt-4" style={{ fontWeight: "700" }}>
                          No history Found
                        </div>
                      )}
                </>
              ) : (
                <div className="h-100 d-flex w-100 flex-column justify-content-center">
                  <MainLoader text="Ai Resume..." />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeSection;
