import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/image/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { SetAnalysis, SetAnalysisResume } from "helper/redux/Slice/ResumeSlice";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const RT1editable = ({ data }) => {
  const [fontSize, setfontSize] = useState(0.7);
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "4%",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#2e959c",
    },
    title: {
      fontSize: `${fontSize * 5}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#2e959c",
      fontWeight: "700",
      // textTransform: "uppercase",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      padding: "0px 5px",
      lineHeight: `${fontSize * 5}cqw`,
    },
    profession: {
      color: "#000",
      fontFamily: "'Open Sans-Light', Helvetica",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
      margin: 0,
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
      listStyle: "none",
    },
    headings: {
      fontfamily: "'Open Sans', Helvetica",
      fontSize: `${fontSize * 5}cqw`,
      color: "#2e959c",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
    },
    eduheads: {
      fontSize: `${fontSize * 5}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 5px",
      width: "65%",
    },
    marginHR: {
      margin: `${fontSize * 0}cqw 0`,
      color: "#2e959c",
      borderTop: "3px solid",
      opacity: 1.25,
    },
    marginHRTop: {
      margin: `${fontSize * 0}cqw 0`,
      color: "#2e959c",
      borderTop: "1px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: `${fontSize * 0}cqw 0`,
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      lineHeight: `${fontSize * 5}cqw`,
    },
  };
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }

  // const monthYear = (date) => {
  //   if (!date) {
  //     // Handle the case when date is undefined
  //     return "Unknown Date";
  //   }
  //   const [year, month, day] = date?.split("-");
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   if (day) {
  //     // Format: YYYY-MM-DD
  //     return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
  //   } else if (date.length === 7) {
  //     let month = date.slice(5, 7);
  //     let year = date.slice(0, 4);
  //     switch (month) {
  //       case "1":
  //         return `Jan-${year}`;
  //       case "2":
  //         return `Feb-${year}`;
  //       case "3":
  //         return `March-${year}`;
  //       case "4":
  //         return `April-${year}`;
  //       case "5":
  //         return `May-${year}`;
  //       case "6":
  //         return `June-${year}`;
  //       case "7":
  //         return `July-${year}`;
  //       case "8":
  //         return `Aug-${year}`;
  //       case "9":
  //         return `Sep-${year}`;
  //       case "10":
  //         return `Oct-${year}`;
  //       case "11":
  //         return `Nov-${year}`;
  //       case "12":
  //         return `Dec-${year}`;
  //       // break;
  //       default:
  //         // return date;
  //         return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
  //       // break;
  //     }
  //   } else {
  //     let day = date.slice(8, 10);
  //     let month = date.slice(5, 7);
  //     let year = date.slice(0, 4);
  //     switch (month) {
  //       case "1":
  //         return `${day}-Jan-${year}`;
  //       case "2":
  //         return `${day}-Feb-${year}`;
  //       case "3":
  //         return `${day}-March-${year}`;
  //       case "4":
  //         return `${day}-April-${year}`;
  //       case "5":
  //         return `${day}-May-${year}`;
  //       case "6":
  //         return `${day}-June-${year}`;
  //       case "7":
  //         return `${day}-July-${year}`;
  //       case "8":
  //         return `${day}-Aug-${year}`;
  //       case "9":
  //         return `${day}-Sep-${year}`;
  //       case "10":
  //         return `${day}-Oct-${year}`;
  //       case "11":
  //         return `${day}-Nov-${year}`;
  //       case "12":
  //         return `${day}-Dec-${year}`;
  //       // break;
  //       default:
  //         return date;
  //       // break;
  //     }
  //   }
  // };

  return (
    <>
      <div style={Style.resumeWrapper}>
        <div id="resume-container" className="resume-container">
          <div className="resume-head d-flex justify-content-between align-items-center">
            <div className="">
              {/* <img src={logo} width={100} alt="profile" /> */}
              <h1 style={Style.title}>
                {`${ResumeData?.contact?.title} ${ResumeData?.contact?.firstName} ${ResumeData?.contact?.lastName}`}{" "}
              </h1>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                {/* <h2 style={Style.profession}>{ResumeData?.profession}</h2> */}
              </div>
            </div>
          </div>
          {/* <div style={{ flex: 1, backgroundColor: "#2e959c", height: "3px" }} /> */}
          <div style={Style.marginHRTop}></div>
          <div className="resume-head d-flex justify-content-between">
            <div className="">
              <h4 style={Style.common}>
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                {ResumeData?.contact?.email}
              </h4>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  {ResumeData?.contact?.number}
                </h4>
              </div>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                  {!!ResumeData?.contact?.address
                    ? ResumeData?.contact?.address
                    : `${ResumeData?.contact?.city} ${ResumeData?.contact?.country}`}
                </h4>
              </div>
            </div>
          </div>
          <div className="mb-2" style={Style.marginHRTop}></div>

          {/* <hr className="w-100" style={Style?.marginHR} /> */}

          <div>
            <div>
              <div>
                {!!ResumeData?.summary && (
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center justify-content-between">
                      <h2 style={Style.headings}>Summary</h2>
                      <div
                        style={{
                          width: "75%",
                          height: "3px",
                          backgroundColor: "#2e959c",
                        }}
                      />
                    </div>
                    <div class="yui-u">
                      <p style={Style.common}>{ResumeData?.summary}</p>
                    </div>
                  </div>
                )}

                {/* {ResumeData?.hobby?.length > 0 && (
                  <>
                    <hr className="w-100" style={Style?.marginHR} />
                    <div class="yui-gf ">
                      <div class="yui-u first ">
                        <h2 style={Style.headings} className="mb-0">
                          Hobby
                        </h2>
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.hobby?.map((item, index) => {
                            return (
                              <span key={index} style={Style?.common}>
                                {" "}
                                {item?.name}{" "}
                                {index < ResumeData?.hobby?.length - 1 && ","}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )} */}
                {ResumeData?.skills?.length > 0 && (
                  <>
                    <div class="yui-gf  mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Skills
                        </h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <ul style={Style.marginUl}>
                          {ResumeData?.skills?.map((skill, index) => (
                            <li key={index} style={Style?.li}>
                              <span className="me-1">•</span>{" "}
                              {typeof skill === "object" ? skill.name : skill}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.experiences?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex justify-content-between align-items-center">
                        <h2 style={Style.headings}>Experience</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u">
                        {[...ResumeData?.experiences]
                          ?.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          )
                          .map((item, index) => {
                            return (
                              <div class="job mb-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p style={Style.eduheads}>{item?.role}</p>
                                  <p style={Style.common}>
                                    {moment(item?.start).format("MMM-YYYY")} -{" "}
                                    {item?.isWorking
                                      ? "Present"
                                      : moment(item?.end).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 style={Style?.subHeading}>
                                    {item?.company},{" "}
                                    <small>{item?.location}</small>
                                  </h2>
                                  <p style={Style.common}>{item?.jobtype}</p>
                                </div>

                                {Array.isArray(item?.description) ? (
                                  <ul style={Style.common}>
                                    {item.description.map((desc, i) => (
                                      <li key={i} style={Style.li}>
                                        <span className="me-1">•</span> {desc}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  item?.description && (
                                    <p style={Style.common}>
                                      {item.description}
                                    </p>
                                  )
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.projects?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Projects</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u ">
                        {/* {ResumeData?.projects?.map((item) => { */}
                        {[...ResumeData?.projects]
                          ?.sort((a, b) => {
                            // Convert date strings to Date objects for comparison
                            const dateA = new Date(a.start);
                            const dateB = new Date(b.start);
                            // Sort in descending order (latest first)
                            return dateB - dateA;
                          })
                          .map((item) => {
                            return (
                              <div class="job">
                                <div className="d-flex gap-4 justify-content-between">
                                  <h2 style={Style.subHeadings}>
                                    {item?.title}{" "}
                                  </h2>

                                  <p style={Style.common}>
                                    {moment(item?.start).format("MMM-YYYY")} -
                                    {moment(item?.end).format("MMM-YYYY")}
                                  </p>
                                </div>
                                {!!item?.company && (
                                  <p style={Style.common}>{item?.company}</p>
                                )}
                                <p style={Style.common}>{item?.role}</p>
                                {Array.isArray(item?.description) ? (
                                  <ul style={Style.common}>
                                    {item?.description.map((desc, i) => (
                                      <li key={i} style={Style.li}>
                                        <span className="me-1">•</span> {desc}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  item?.description && (
                                    <p style={Style.common}>
                                      {item.description}
                                    </p>
                                  )
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.researchPapers?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Research Papers</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u ">
                        {[...ResumeData?.researchPapers]
                          ?.sort((a, b) => {
                            // Convert date strings to Date objects for comparison
                            const dateA = new Date(a.start);
                            const dateB = new Date(b.start);
                            // Sort in descending order (latest first)
                            return dateB - dateA;
                          })
                          .map((item) => {
                            return (
                              <div class="job">
                                <div className="d-flex gap-4 justify-content-between">
                                  <h2 style={Style.subHeadings}>
                                    {item?.title}{" "}
                                  </h2>
                                  <p style={Style.common}>
                                    {!!item?.date &&
                                      moment(item?.date).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <p style={Style.common}>{item?.author}</p>
                                <p style={Style.common}>{item?.url}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.education?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Education</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      {[...ResumeData?.education]
                        ?.sort((a, b) => {
                          // Convert date strings to Date objects for comparison
                          const dateA = new Date(a.date);
                          const dateB = new Date(b.date);
                          // Sort in descending order (latest first)
                          return dateB - dateA;
                        })
                        .map((item) => {
                          return (
                            <div className="">
                              <div class="yui-u d-flex justify-content-between">
                                <h2 style={Style.subHeadings}>
                                  {item?.degree} {item?.specialization}
                                </h2>
                                <p style={Style.common}>
                                  {moment(item?.date).format("MMM-YYYY")}{" "}
                                </p>
                              </div>
                              <div class="yui-u d-flex justify-content-between mb-2">
                                <p style={Style.common}>{item?.institute}</p>
                                <p style={Style.common}>
                                  {!!item?.academicScore
                                    ? item?.academicScore === "Percentage"
                                      ? ` Percentage - ${item?.percentage}%`
                                      : ` ${item?.academicScore} -  ${item?.gpa}/${item?.gpaOutOf}`
                                    : ""}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}

                {ResumeData?.certification?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Certificates/Workshops</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.certification?.map((item) => { */}
                        {[...ResumeData.certification]
                          // ?.filter((item) => item && item.date) // Filter out null or undefined items and items without a valid date
                          .sort((a, b) => new Date(b?.date) - new Date(a?.date))
                          .map((item, index) => {
                            return (
                              <>
                                <div class="yui-u d-flex justify-content-between">
                                  <h2 style={Style.subHeadings}>
                                    {item?.name}
                                  </h2>
                                  <p style={Style.common}>
                                    {!!item?.date &&
                                      moment(item?.date).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <div class="yui-u">
                                  <p style={Style.common}>{item?.org}</p>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.achievements?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center">
                        <h2 style={Style.headings}>Achievements</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.achievements?.map((item) => { */}
                        {[...ResumeData?.achievements]
                          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((item, index) => {
                            return (
                              <>
                                <div class="yui-u d-flex justify-content-between">
                                  <div>
                                    <h2 style={Style.subHeadings}>
                                      {item?.title}{" "}
                                    </h2>
                                  </div>
                                  <div>
                                    <p style={Style.common}>
                                      {moment(item?.date).format("MMM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  {Array.isArray(item?.description) ? (
                                    <ul style={Style.common}>
                                      {item.description.map((desc, i) => (
                                        <li key={i} style={Style.li}>
                                          <span className="me-1">•</span> {desc}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    item?.description && (
                                      <p style={Style.common}>
                                        {item.description}
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.involvement?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Voluntary</h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.involvement?.map((item) => { */}
                        {[...ResumeData.involvement]
                          ?.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          )
                          .map((item, index) => {
                            return (
                              <>
                                <div class="yui-u  justify-content-between">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h2 style={Style.subHeading}>
                                      {item?.role}{" "}
                                    </h2>
                                    <p style={Style.common} className="">
                                      {moment(item?.start).format("MMM-YYYY")} -{" "}
                                      {item?.isWorking
                                        ? "Present"
                                        : moment(item?.end).format("MMM-YYYY")}
                                    </p>
                                  </div>
                                  <h2 style={Style.common}>{item?.company}</h2>
                                </div>
                                <div>
                                  {Array.isArray(item?.description) ? (
                                    <ul style={Style.common}>
                                      {item.description.map((desc, i) => (
                                        <li key={i} style={Style.li}>
                                          <span className="me-1">•</span> {desc}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    item?.description && (
                                      <p style={Style.common}>
                                        {item.description}
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.hobby?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Hobby
                        </h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.hobby?.map((hobby, index) => (
                            <li key={index} style={Style?.li}>
                              <span className="me-1">•</span>{" "}
                              {typeof hobby === "object" ? hobby.name : hobby}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.languagesKnown?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Languages
                        </h2>
                        <div
                          style={{
                            backgroundColor: "#2e959c",
                            width: "75%",
                            height: "3px",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.languagesKnown?.map(
                            (language, index) => (
                              <li key={index} style={Style?.li}>
                                <span className="me-1">•</span>{" "}
                                {typeof language === "object"
                                  ? language.name
                                  : language}
                              </li>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.links?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Links
                        </h2>
                        <div
                          style={{
                            width: "75%",
                            height: "3px",
                            backgroundColor: "#2e959c",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          {ResumeData?.links?.map((link, index) => (
                            <li key={index} style={Style?.li}>
                              <span className="me-1">•</span>{" "}
                              {typeof link === "object" ? link.name : link}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RT1editable;
