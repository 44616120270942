import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ChatGPTAPI,
  GetCoverLetter,
  GetExperienceCoverLetterById,
  GetFresherCoverLetterById,
} from "helper/api";
import { useSelector } from "react-redux";

export const GetCoverEditData = createAsyncThunk(
  "cover/GettingEditData",
  async (id) => {
    try {
      let response;
      if (window.location?.pathname.includes("experience")) {
        response = await GetExperienceCoverLetterById(id);
        return response?.data;
      } else {
        response = await GetFresherCoverLetterById(id); // call your async API function
        return response.data; // return the data from the API response
      }
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingCompleteness = createAsyncThunk(
  "cover/GettingCompleteness",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingFormatting = createAsyncThunk(
  "cover/GettingFormatting",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingWordChoice = createAsyncThunk(
  "cover/GettingWordChoice",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(payload); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingSummary = createAsyncThunk(
  "cover/GettingSummary",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(
        payload?.AiCredit,
        payload?.CoverLetterStrongSummary
      ); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingATSCompliance = createAsyncThunk(
  "cover/GettingATSCompliance",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(
        payload?.AiCredit,
        payload?.CoverLetterStrongSummary
      ); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
export const GettingCoverLetterContent = createAsyncThunk(
  "cover/GettingCoverLetterContent",
  async (payload) => {
    try {
      const response = await ChatGPTAPI(
        payload?.AiCredit,
        payload?.CoverLetterStrongSummary
      ); // call your async API function
      return response.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

const initialState = {
  name: "",
  template: "",
  coverId: "",
  color: "",
  type: "",
  Field: "",
  EditData: false,
  targetJob: {
    position: "",
    company: "",
  },
  from: {
    name: "",
    email: "",
    position: "",
    address: "",
    companyName: "",
  },
  to: {
    name: "",
    email: "",
    position: "",
    address: "",
    companyName: "",
  },
  experiences: [],
  experiencesWithGap: [],
  education: [],
  projects: [],
  certification: [],
  involvement: [],
  skills: [],
  content: "",
  totalExperience: "",
  gap: "",
  Reason: "",
  isAnalysisData: false,

  completeness: 0,
  clearContactInfo: 0,
  optimization: 0,
  atsCompliance: 0,
  strongSummary: 0,
  length: 0,

  measurableResult: 0,
  wordChoice: 0,
  noTypos: 0,
  formatting: 0,
  rank: 0,
  coverStep: 1,
};
let CoverSlice = createSlice({
  name: "cover",
  initialState,
  reducers: {
    setFieldFocus(state, action) {
      state.Field = action.payload;
    },
    setTargetJob(state, action) {
      state.targetJob = action.payload;
    },
    setTo(state, action) {
      state.to = action.payload;
    },
    setFrom(state, action) {
      state.from = {
        ...state.from,
        [action.payload.type]: action.payload.value,
      };
    },
    setCoverFor(state, action) {
      state.coverFor = action.payload;
    },
    setCoverEducationFields(state, action) {
      state.education = [...state?.education, action.payload];
    },
    DeleteCoverEducation(state, action) {
      state.education = state?.education?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCoverEducation(state, action) {
      let index = action?.payload?.index;
      state.education[index] = action.payload;
    },
    setCoverExperienceFields(state, action) {
      state.experiences.push(action.payload);
    },
    DeleteCoverExperience(state, action) {
      state.experiences = state?.experiences?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCoverExperience(state, action) {
      let index = action?.payload?.index;
      state.experiences[index] = action.payload;
    },
    setCoverCertificateFields(state, action) {
      state.certification.push(action.payload);
    },
    DeleteCoverCertificate(state, action) {
      state.certification = state?.certification?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCoverCertificate(state, action) {
      let index = action?.payload?.index;
      state.certification[index] = action.payload;
    },
    setCoverProjectFields(state, action) {
      state.projects.push(action.payload);
    },
    DeleteCoverProject(state, action) {
      state.projects = state?.projects?.filter(
        (item, index) => index !== action.payload
      );
    },
    setCoverCreateStep(state, action) {
      state.coverStep = action.payload;
    },
    UpdateCoverProject(state, action) {
      let index = action?.payload?.index;
      console.log(index);
      state.projects[index] = action.payload;
    },
    setCoverInvolvementFields(state, action) {
      console.log(action.payload);
      state.involvement.push(action.payload);
    },
    DeleteCoverInvolvement(state, action) {
      state.involvement = state?.involvement?.filter(
        (item, index) => index !== action.payload
      );
    },
    UpdateCoverInvolvement(state, action) {
      let index = action?.payload?.index;
      state.involvement[index] = action.payload;
    },
    setCoverSkillFields(state, action) {
      state.skills.push(action.payload);
    },
    DeleteCoverSkill(state, action) {
      state.skills = state?.skills?.filter(
        (item, index) => index !== action.payload
      );
    },
    setCoverFields: (state, action) => {
      state[action.payload.type] = action.payload.value;
      // const obj = { ...state }
      // obj[action.payload.type] = action.payload.value

      // return { ...state, [action.payload.type]: action.payload.value }
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    ResetCoverLetter: (state, action) => {
      Object.assign(state, initialState);
    },
    EditCoverLetter: (state, action) => {
      const {
        content,
        from,
        to,
        targetJob,
        education,
        experiences,
        certification,
        projects,
        involvement,
      } = action?.payload;
      return {
        ...state,
        content,
        from,
        to,
        targetJob,
        education,
        experiences,
        certification,
        projects,
        involvement,
      };
    },
    EditCoverLetter: (state, action) => {
      const {
        content,
        from,
        to,
        targetJob,
        education,
        experiences,
        certification,
        projects,
        involvement,
      } = action?.payload;
      return {
        ...state,
        content,
        from,
        to,
        targetJob,
        education,
        experiences,
        certification,
        projects,
        involvement,
      };
    },
    SetAnalysisCover: (state, action) => {
      if (action.payload?.type === "length") {
        if (action.payload.value === 0) {
          state.length = 100;
        } else if (action.payload.value === 1) {
          state.length = 75;
        } else if (action.payload?.value === 2) {
          state.length = 50;
        } else if (action.payload.value === 3) {
          state.length = 25;
        }
      } else {
        state[action.payload.type] = action.payload.value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCoverEditData?.pending, (state, action) => {
        state.EditData = true;
      })
      .addCase(GetCoverEditData?.fulfilled, (state, action) => {
        console.log(action.payload);
        state.EditData = false;
        const {
          content,
          strongSummary,
          completeness,
          clearContactInfo,
          length,
          optimization,
          atsCompliance,
          from,
          to,
          targetJob,
          education,
          experiences,
          certification,
          projects,
          involvement,
          _id,
          template,
        } = action?.payload?.data;
        state.content = content;
        state.from = from;
        state.to = to;
        state.targetJob = targetJob;
        state.education = education;
        state.experiences = experiences;
        state.projects = projects;
        state.involvement = involvement;
        state.certification = certification;
        state.coverId = _id;
        state.template = Number(template);

        state.strongSummary = strongSummary;
        state.completeness = completeness;
        state.clearContactInfo = clearContactInfo;
        state.length = length;
        state.optimization = optimization;
        state.atsCompliance = atsCompliance;

        // state = { ...state, content, from, to, targetJob, education, experiences, certification, projects, involvement }
      })
      .addCase(GetCoverEditData?.rejected, (state) => {
        state.EditData = false;
      })

      .addCase(GettingCompleteness?.fulfilled, (state, action) => {
        state.isAnalysisData = true;
        const match = action.payload?.text?.match(/(\d+)/);
        state.completeness = match[0];
      })
      .addCase(GettingFormatting?.fulfilled, (state, action) => {
        state.isAnalysisData = true;
        const match = action.payload?.text?.match(/(\d+)/);
        state.formatting = match[0];
      })
      .addCase(GettingWordChoice?.fulfilled, (state, action) => {
        state.isAnalysisData = true;
        const match = action.payload?.text?.match(/(\d+)/);
        state.wordChoice = match[0];
      })
      .addCase(GettingSummary?.pending, (state, action) => {
        state.isAnalysisData = true;
      })
      .addCase(GettingSummary?.fulfilled, (state, action) => {
        state.isAnalysisData = false;
        const match = action.payload?.text?.match(/(\d+)/);
        state.strongSummary = match[0];
      })
      .addCase(GettingSummary?.rejected, (state, action) => {
        state.isAnalysisData = false;
      })
      .addCase(GettingATSCompliance?.fulfilled, (state, action) => {
        state.isAnalysisData = true;
        console.log(action.payload);
        const match = action.payload?.text?.match(/(\d+)/);
        state.atsCompliance = match[0];
      })
      .addCase(GettingCoverLetterContent?.pending, (state, action) => {})
      .addCase(GettingCoverLetterContent?.fulfilled, (state, action) => {
        console.log(action.payload.text);
      })
      .addCase(GettingCoverLetterContent?.rejected, (state, action) => {});
  },
});
export const {
  setTargetJob,
  setTo,
  setFrom,
  setContent,
  DeleteCoverEducation,
  EditCoverLetter,
  UpdateCoverEducation,
  setCoverFor,
  setCoverEducationFields,
  setCoverExperienceFields,
  UpdateCoverExperience,
  DeleteCoverExperience,
  setCoverCertificateFields,
  DeleteCoverCertificate,
  UpdateCoverCertificate,
  setFieldFocus,
  setCoverProjectFields,
  UpdateCoverProject,
  DeleteCoverProject,
  setCoverSkillFields,
  DeleteCoverSkill,
  setCoverFields,
  setCoverInvolvementFields,
  DeleteCoverInvolvement,
  UpdateCoverInvolvement,
  ResetCoverLetter,
  SetAnalysisCover,
  setCoverCreateStep,
} = CoverSlice.actions;
export default CoverSlice.reducer;
