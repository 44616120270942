import React from "react";

function BasicTextArea({
  handleChange,
  label,
  name,
  value,
  placeholder,
  index,
  submitAttempted,
  required,
  rows
}) {
  const isValid = !required || value.trim() !== "";

  return (
    <>
      <div className="d-flex flex-column form-input mb-4 w-100">
        <label for="first_name" className="text-sm ">
          {label}
        </label>
        <textarea
          id="message"
          rows={rows?rows:"4"}
          onChange={(e) => handleChange(index, e)}
          value={value}
          className=""
          placeholder={placeholder}
          name={name}
          style={{
            border:
              submitAttempted && !isValid
                ? "1.5px solid red"
                : "1.5px solid #602a94",
          }}
        ></textarea>
      </div>
    </>
  );
}

export default BasicTextArea;
