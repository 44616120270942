import AnalysisFeature from "common/AnalysisFeature";
import React from "react";

function Statistics() {
  return (
    <div className="statistics-container d-flex my-5 gap-3">
      <AnalysisFeature />
    </div>
  );
}

export default Statistics;
