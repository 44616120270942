import React from "react";
import { Spinner } from "react-bootstrap";
import { RiReactjsFill } from "react-icons/ri";

function BasicTextAreaWithAi({
  handleChange,
  label,
  name,
  value,
  placeholder,
  onKeyPress,
  index,
  postChatGPTData,
  onKeyDown,
  loadingAi,
  data,
}) {
  return (
    <>
      <div className="d-flex flex-column form-input mb-4 mt-4 w-100">
        <div className="d-flex  justify-content-between align-items-center">
          <label for="first_name" className="text-sm ">
            {label}
          </label>
          <button
            disabled={loadingAi}
            className={`${
              loadingAi ? "cmn-gray-bg" : "primary-btn"
            } p-2 rounded mb-2 d-flex align-items-center`}
            onClick={(e) => postChatGPTData(e, index, data)}
          >
            <span className="bg-white rounded p-1 primary-text">21/200</span>
            {loadingAi ? (
              <Spinner
                color="white"
                variant="white"
                size="sm"
                className="mx-1"
              />
            ) : (
              <RiReactjsFill fontSize={18} className="mx-1" />
            )}
            <span>AI</span>
          </button>
        </div>
        <textarea
          id="message"
          rows={9}
          onChange={(e) => handleChange(index, e)}
          value={value}
          className=""
          //   onKeyPress={(e) => onKeyPress(index, e)}
          placeholder={placeholder}
          onKeyDown={(e) => onKeyDown(index, e)}
          name={name}
        ></textarea>
      </div>
    </>
  );
}

export default BasicTextAreaWithAi;
