import React, { useEffect, useState } from "react";
import loginbg from "assets/image/loginbackground.png";
import {
  BoxStyle,
  DarkHeading,
  Paragraph,
} from "components/common/CommonStyles";
import logo from "assets/image/logo.svg";
import "./auth.css";
import { Login } from "./Login";
import { Signup } from "./Signup";
import { ForgetPassword } from "./ForgetPassword";
import { useDispatch } from "react-redux";
import { getProfile } from "helper/api";
import { setUser } from "helper/redux/Slice/UserSlice";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router";
import sidelogo from "../../assets/image/sidelogo.mp4";

export const Authentication = () => {
  const [select, setSelect] = useState(0);

  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const id = url.searchParams.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checklogin = async () => {
    try {
      if (!!id) {
        const response = await getProfile(id);
        // console.log(response)
        // console.log(JSON.stringify(response.data.data))
        // Cookies.set("prashant", "oiuygtfrg")
        // local
        const userprofile = JSON.stringify(response.data.data);
        // debugger
        const obj = {
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          number: response?.data?.data?.phoneNumber,
          _id: response?.data?.data?._id,
        };
        console.log(obj);
        Cookies.set("user", JSON.stringify(obj));
        localStorage.setItem("user2", userprofile);
        dispatch(setUser(response.data.data));
        // navigate("/");
        window.location.replace("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!token) {
      Cookies.set("token", token);
      checklogin();
    }
  }, [token]);

  return (
    <>
      {!!token ? (
        <>
          <div className="loading-screen">
            <video
              className="notShowLoader"
              style={{ width: "200px" }}
              src={sidelogo}
              autoplay="true"
              muted="muted"
              alt="logo"
            />
          </div>
        </>
      ) : (
        <>
          <div className="d-flex col-12 ">
            <div className="col-lg-8 col-0 " style={{ overflow: "hidden" }}>
              <img
                src={loginbg}
                alt="loginbg"
                className="login-bg w-100 d-lg-flex d-none"
              />
            </div>
            <div className="col-lg-4 col-12 mt-5 px-4">
              <div className="d-flex justify-content-center flex-column align-items-center">
                <img src={logo} width="157px" height="48px" alt="logo" />
                <DarkHeading>Viosa Resumatic</DarkHeading>
                <Paragraph className="text-center fs-12">
                  Indias First AI-Powered Resumatic portal
                </Paragraph>
              </div>
              <BoxStyle
                className="background-primary h-380"
                style={{ backgroundColor: "#ffa303" }}
              >
                <div className="d-flex">
                  <Paragraph
                    onClick={() => setSelect(0)}
                    className={`fw-400 fs-14 text-left mb-0 cursor-pointer ${
                      select === 0 && "text-underlined text-purple "
                    }}`}
                  >
                    Login
                  </Paragraph>
                  <Paragraph
                    onClick={() => setSelect(1)}
                    className={`fw-400 fs-14 mx-3 text-left mb-0 cursor-pointer ${
                      select === 1 && "text-underlined text-purple "
                    }}`}
                  >
                    Signup
                  </Paragraph>
                  <hr />
                </div>
                {select === 0 && <Login setSelect={setSelect} />}
                {select === 1 && <Signup setSelect={setSelect} />}
                {select === 2 && <ForgetPassword />}
              </BoxStyle>
            </div>
          </div>
        </>
      )}
    </>
  );
};
