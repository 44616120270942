import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/image/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { SetAnalysis, SetAnalysisResume } from "helper/redux/Slice/ResumeSlice";
import moment from "moment";

const RT6editable = ({ data }) => {
  const [fontSize, setfontSize] = useState(0.7);
  const defaultimg = "https://viosa-storage-2.s3.amazonaws.com/profile.png";
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "1%",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      display: "flex",
      fontFamily: "'Times New Roman', serif",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#000",
      fontFamily: "'Times New Roman', serif",
    },
    title: {
      fontSize: `${fontSize * 6}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      fontWeight: "700",
      // textTransform: "uppercase",
      fontFamily: "'Times New Roman', serif",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Times New Roman', serif",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "left",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      // padding: "0px 5px",
      lineHeight: `${fontSize * 5}cqw`,
    },
    date: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Times New Roman', serif",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      whiteSpace: "break-spaces",
      textAlign: "left",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      // padding: "0px 5px",
      lineHeight: `${fontSize * 5}cqw`,
      width: "65%",
    },
    profession: {
      color: "#000",
      fontFamily: "'Times New Roman', serif",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
      fontFamily: "'Times New Roman', serif",
    },
    headings: {
      fontFamily: "'Times New Roman', serif",
      fontSize: `${fontSize * 4}cqw`,
      color: "#000",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 0px",
      fontFamily: "'Times New Roman', serif",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 0}cqw`,
      color: "#000",
      padding: "0px 0px",
      fontFamily: "'Times New Roman', serif",
    },
    eduheads: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 0px",
      fontFamily: "'Times New Roman', serif",
    },
    marginHR1: {
      margin: `${fontSize * 2}cqw 0`,
      color: "#000",
      borderTop: "1px solid",
      opacity: 1.25,
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`,
      color: "#C1EBD9",
      borderTop: "10px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: 0,
      paddingLeft: "15px",
    },
    resumesideL: {
      padding: "10px",
      width: "105%",
    },
    resumesideR: {
      padding: "10px",
      background: "#C1EBD9",
      width: "50%",
    },
    img: {
      paddingBottom: "20px",
      width: "100%",
      height: "150px",
      objectFit: "contain",
    },
    logo: {
      paddingBottom: "5px",
      width: "18%",
      height: "50px",
      objectFit: "contain",
    },
  };
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }
  console.log("resume data", ResumeData);

  const monthYear = (date) => {
    if (!date) {
      // Handle the case when date is undefined
      return "Unknown Date";
    }
    const [year, month, day] = date.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (day) {
      // Format: YYYY-MM-DD
      return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
    } else if (date.length === 7) {
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `March-${year}`;
        case "4":
          return `April-${year}`;
        case "5":
          return `May-${year}`;
        case "6":
          return `June-${year}`;
        case "7":
          return `July-${year}`;
        case "8":
          return `Aug-${year}`;
        case "9":
          return `Sep-${year}`;
        case "10":
          return `Oct-${year}`;
        case "11":
          return `Nov-${year}`;
        case "12":
          return `Dec-${year}`;
        // break;
        default:
          // return date;
          return `${monthNames[parseInt(month, 10) - 1]}-${year}`;
        // break;
      }
    } else {
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);
      switch (month) {
        case "1":
          return `Jan-${year}`;
        case "2":
          return `Feb-${year}`;
        case "3":
          return `${day}-March-${year}`;
        case "4":
          return `${day}-April-${year}`;
        case "5":
          return `${day}-May-${year}`;
        case "6":
          return `${day}-June-${year}`;
        case "7":
          return `${day}-July-${year}`;
        case "8":
          return `${day}-Aug-${year}`;
        case "9":
          return `${day}-Sep-${year}`;
        case "10":
          return `${day}-Oct-${year}`;
        case "11":
          return `${day}-Nov-${year}`;
        case "12":
          return `${day}-Dec-${year}`;
        // break;
        default:
          return date;
        // break;
      }
    }
  };

  return (
    <>
      <div style={Style.resumeWrapper}>
        <div
          id="resume-container"
          className="resume-container"
          style={Style.resumesideL}
        >
          <div className="image">
            <img
              src="https://viosainterviewvideos.s3.ap-south-1.amazonaws.com/interviewvideos/Ashoka+logo.png"
              alt="logo"
              style={Style.logo}
            />
          </div>
          <div className="resume-head d-flex justify-content-between">
            <div className="">
              {/* <img src={logo} width={100} alt="profile" /> */}
              <h1 style={Style.title}>
                {`${ResumeData?.contact?.title} ${ResumeData?.contact?.firstName} ${ResumeData?.contact?.lastName}`}{" "}
              </h1>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                {/* <h2 style={Style.profession}>{ResumeData?.profession}</h2> */}
              </div>
            </div>
          </div>
          <div style={Style.marginHR1}></div>

          <div>
            <div>
              <div>
                {!!ResumeData?.summary && (
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center justify-content-between">
                      <h2 style={Style.headings}>Summary c iouyegdhj</h2>
                      <div
                        style={{
                          width: "50%",
                          height: "4px",
                          backgroundColor: "rgb(88 149 123)",
                        }}
                      />
                    </div>
                    <div class="yui-u">
                      <p style={Style.common}>{ResumeData?.summary}</p>
                    </div>

                    <div style={Style.marginHR1}></div>
                  </div>
                )}

                {ResumeData?.education?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Education</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      {/* {ResumeData?.education?.map((item) => { */}
                      {[...ResumeData?.education]
                        ?.sort((a, b) => {
                          // Convert date strings to Date objects for comparison
                          const dateA = new Date(a.date);
                          const dateB = new Date(b.date);
                          // Sort in descending order (latest first)
                          return dateB - dateA;
                        })
                        .map((item) => {
                          return (
                            <div className="">
                              <div class="yui-u d-flex justify-content-between">
                                <h2 style={Style.eduheads}>
                                  {item?.degree}
                                  {item.specialization
                                    ? `- ${item.specialization}`
                                    : ""}
                                </h2>
                                <p style={Style.common}>
                                  {moment(item?.date).format("MMM-YYYY")}{" "}
                                </p>
                              </div>
                              <div class="yui-u d-flex justify-content-between mb-2">
                                <p style={Style.common}>
                                  {item?.institute}, {item?.location}
                                </p>
                                {/* <p style={Style.common}>SGPA - {item?.gpa}</p> */}
                                {item?.gpa !== undefined &&
                                  item?.gpa !== null &&
                                  item?.gpa !== "" && (
                                    <p style={Style.common}>
                                      SGPA - {item?.gpa}
                                    </p>
                                  )}
                                {!!item?.percentage && (
                                  <p style={Style.common}>
                                    {item?.percentage}%
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        })}

                      <div style={Style.marginHR1}></div>
                    </div>
                  </>
                )}

                {ResumeData?.certification?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex justify-content-between align-items-center">
                        <h2 style={Style.headings}>
                          Certificates, Workshops & Seminars{" "}
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.certification?.map((item) => { */}
                        {[...ResumeData.certification]
                          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((item, index) => {
                            return (
                              <>
                                <div class="yui-u d-flex justify-content-between">
                                  <h2 style={Style.eduheads}>{item?.name}</h2>
                                  {!!item?.date && (
                                    <p style={Style.common}>
                                      {moment(item?.date).format("MMM-YYYY")}
                                    </p>
                                  )}
                                </div>
                                <div class="yui-u">
                                  <p style={Style.common}>{item.org}</p>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                {ResumeData?.projects?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Projects</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u ">
                        {/* {ResumeData?.projects?.map((item) => { */}
                        {[...ResumeData?.projects]
                          ?.sort((a, b) => {
                            // Convert date strings to Date objects for comparison
                            const dateA = new Date(a.start);
                            const dateB = new Date(b.start);
                            // Sort in descending order (latest first)
                            return dateB - dateA;
                          })
                          .map((item) => {
                            return (
                              <div class="job">
                                <div className="d-flex gap-4 justify-content-between">
                                  <h2 style={Style.subHeading}>
                                    {item?.title}{" "}
                                  </h2>
                                  <p style={Style.common}>
                                    {moment(item?.start).format("MMM-YYYY")}
                                  </p>
                                </div>
                                {Array.isArray(item?.description) ? (
                                  <ul style={Style.common}>
                                    {item.description.map((desc, i) => (
                                      <li key={i} style={Style.li}>
                                        <span className="me-1">•</span> {desc}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  item?.description && (
                                    <p style={Style.common}>
                                      {item.description}
                                    </p>
                                  )
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div style={Style.marginHR1}></div>
                    </div>
                  </>
                )}

                {ResumeData?.researchPapers?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>Research Papers</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u ">
                        {[...ResumeData?.researchPapers]
                          ?.sort((a, b) => {
                            // Convert date strings to Date objects for comparison
                            const dateA = new Date(a.start);
                            const dateB = new Date(b.start);
                            // Sort in descending order (latest first)
                            return dateB - dateA;
                          })
                          .map((item) => {
                            return (
                              <div class="job">
                                <div className="d-flex gap-4 justify-content-between">
                                  <h2 style={Style.subHeading}>
                                    {item?.title}{" "}
                                  </h2>
                                  <p style={Style.common}>
                                    {moment(item?.start).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <p style={Style.common}>{item?.description}</p>
                              </div>
                            );
                          })}
                      </div>

                      <div style={Style.marginHR1}></div>
                    </div>
                  </>
                )}

                {ResumeData?.experiences?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex justify-content-between align-items-center">
                        <h2 style={Style.headings}>Experience</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u ">
                        {/* {ResumeData?.experiences?.map((item) => { */}
                        {[...ResumeData.experiences]
                          ?.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          )
                          .map((item, index) => {
                            return (
                              <div class="job">
                                <div className="d-flex justify-content-between">
                                  <p style={Style.eduheads}>{item?.role}</p>
                                  <p style={Style.common}>
                                    {moment(item?.start).format("MMM-YYYY")} -{" "}
                                    {item?.isWorking
                                      ? "Present"
                                      : moment(item?.end).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h2 style={Style?.subHeading}>
                                    {item?.company} ,{" "}
                                    <small>{item?.location}</small>
                                  </h2>
                                  <p style={Style.common}>{item.jobtype}</p>
                                </div>
                                {Array.isArray(item?.description) ? (
                                  <ul style={Style.common}>
                                    {item.description.map((desc, i) => (
                                      <li key={i} style={Style.li}>
                                        <span className="me-1">•</span> {desc}
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  item?.description && (
                                    <p style={Style.common}>
                                      {item.description}
                                    </p>
                                  )
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div style={Style.marginHR1}></div>
                    </div>
                  </>
                )}

                {ResumeData?.achievements?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex justify-content-between align-items-center">
                        <h2 style={Style.headings}>Achievements</h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.achievements?.map((item) => { */}
                        {[...ResumeData.achievements]
                          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((item, index) => {
                            return (
                              <>
                                <div class="yui-u d-flex justify-content-between">
                                  <div>
                                    <h2 style={Style.subHeadings}>
                                      {item?.title}{" "}
                                    </h2>
                                  </div>
                                  <div>
                                    <p style={Style.common}>
                                      {moment(item?.date).format("MMM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  {Array.isArray(item?.description) ? (
                                    <ul style={Style.common}>
                                      {item.description.map((desc, i) => (
                                        <li key={i} style={Style.li}>
                                          <span className="me-1">•</span> {desc}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    item?.description && (
                                      <p style={Style.common}>
                                        {item.description}
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.involvement?.length > 0 && (
                  <>
                    <div class="yui-gf last mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings}>
                          Extra Curricular, Co-Curricular & Voluntary
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div>
                        {/* {ResumeData?.involvement?.map((item) => { */}
                        {[...ResumeData.involvement]
                          ?.filter((item) => item && item.start)
                          ?.sort(
                            (a, b) => new Date(b.start) - new Date(a.start)
                          )
                          .map((item, index) => {
                            return (
                              <>
                                <div class="d-flex justify-content-between">
                                  <h2 style={Style.eduheads}>{item?.role} </h2>
                                  <p style={Style.date}>
                                    {/* {moment(item?.start).format("MMM-YYYY")} -{" "} */}
                                    {item?.isWorking
                                      ? "Present"
                                      : moment(item?.end).format("MMM-YYYY")}
                                  </p>
                                </div>
                                <div>
                                  <h2 style={Style.subHeading}>
                                    {item?.company} {item?.location}
                                  </h2>
                                </div>
                                <div>
                                  {Array.isArray(item?.description) ? (
                                    <ul style={Style.common}>
                                      {item.description.map((desc, i) => (
                                        <li key={i} style={Style.li}>
                                          <span className="me-1">•</span> {desc}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    item?.description && (
                                      <p style={Style.common}>
                                        {item.description}
                                      </p>
                                    )
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          id="resume-container"
          className="resume-container"
          style={Style.resumesideR}
        >
          <div className="resume-head d-flex justify-content-between">
            {/* <img src={logo} width={100} alt="profile" /> */}
            <img
              src={ResumeData?.contact?.picture || defaultimg}
              alt="profile"
              style={Style.img}
            />
          </div>
          {/* <div style={{ flex: 1, backgroundColor: "#C1EBD9", height: "10px" }} /> */}
          {/* <div style={Style.marginHR}></div> */}
          <div class="yui-u first d-flex align-items-center justify-content-between">
            <h2 style={Style.headings}>Contact</h2>
            <div
              style={{
                width: "50%",
                height: "4px",
                backgroundColor: "rgb(88 149 123)",
              }}
            />
          </div>
          <div className="resume-head justify-content-between mb-2">
            <div className="">
              <h4 style={Style.common}>
                Email ID : {ResumeData?.contact?.email}
              </h4>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>
                  Contact No.: {ResumeData?.contact?.number}
                </h4>
              </div>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>
                  Address:
                  {!!ResumeData?.contact?.address
                    ? ResumeData?.contact?.address
                    : `${ResumeData?.contact?.city} ${ResumeData?.contact?.country}`}
                </h4>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div>
                {ResumeData?.skills?.length > 0 && (
                  <>
                    <div class="yui-gf  mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Skills
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          <ul style={Style?.ul}>
                            {ResumeData?.skills?.map((item, index) => {
                              // Check if the item is an object and handle it appropriately
                              const skillDisplay =
                                typeof item === "object" && item !== null
                                  ? item
                                  : item;
                              return (
                                <li key={index} style={Style?.common}>
                                  {skillDisplay}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.hobby?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Hobby
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          <ul style={Style?.ul}>
                            {ResumeData?.hobby?.map((item, index) => {
                              return (
                                <li key={index} style={Style?.common}>
                                  {item}
                                  {index < ResumeData?.hobby?.length - 1 && ""}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.languagesKnown?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Languages Known
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          <ul style={Style?.ul}>
                            {ResumeData?.languagesKnown?.map((item, index) => {
                              return (
                                <li key={index} style={Style?.common}>
                                  {item}
                                  {index <
                                    ResumeData?.languagesKnown?.length - 1 &&
                                    ""}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {ResumeData?.links?.length > 0 && (
                  <>
                    <div class="yui-gf mb-2">
                      <div class="yui-u first d-flex align-items-center justify-content-between">
                        <h2 style={Style.headings} className="mb-0">
                          Links
                        </h2>
                        <div
                          style={{
                            width: "50%",
                            height: "4px",
                            backgroundColor: "rgb(88 149 123)",
                          }}
                        />
                      </div>
                      <div class="yui-u d-flex">
                        <div style={Style.marginUl}>
                          <ul style={Style?.ul}>
                            {ResumeData?.links?.map((item, index) => {
                              return (
                                <li key={index} style={Style?.common}>
                                  {item}
                                  {index < ResumeData?.links?.length - 1 && ""}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RT6editable;
