import React, { useState } from "react";
import "./RT3.css";
const RT3non = () => {
  const [fontSize, setfontSize] = useState(0.7);
  const Style = {
    resumeWrapper: {
      width: "100%",
      padding: "1%",
      minHeight: "100%",
      background: "#fff",
      overflow: "auto",
      containerType: "inline-size",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      display: "flex",
    },
    resumeHead: {
      fontSize: fontSize,
      color: "#000",
    },
    title: {
      fontSize: `${fontSize * 6}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      fontWeight: "700",
      // textTransform: "uppercase",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      fontFamily: "'Open Sans-Light', Helvetica",
      marginBottom: `${fontSize * 0.5}cqw`,
      wordBreak: "break-word",
      // whiteSpace: "break-spaces",
      textAlign: "justify",
      wordSpacing: "-1px", // Adjusted for reduced word spacing
      color: "#000",
      marginRight: "10px",
      // padding: "0px 5px",
      lineHeight: `${fontSize * 5}cqw`,
    },
    profession: {
      color: "#000",
      fontFamily: "'Open Sans-Light', Helvetica",
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontfamily: "'Open Sans', Helvetica",
      fontSize: `${fontSize * 4}cqw`,
      color: "#000",
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      marginRight: `15px`,
    },
    subHeadings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 0px",
    },
    subHeading: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 0}cqw`,
      color: "#000",
      padding: "0px 0px",
    },
    eduheads: {
      fontSize: `${fontSize * 3.4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
      color: "#000",
      padding: "0px 0px",
    },
    marginHR1: {
      margin: `${fontSize * 2}cqw 0`,
      color: "#000",
      borderTop: "1px solid",
      opacity: 1.25,
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`,
      color: "rgb(88 149 123)",
      borderTop: "10px solid",
      opacity: 1.25,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
      display: "flex",
      listStyletype: "none",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    horizontallist: {
      display: "flex",
      listStyletype: "none",
      margin: 0,
      padding: 0,
    },
    marker: {
      margin: 0,
    },
    ul: {
      margin: 0,
      paddingLeft: "15px",
    },
    resumesideL: {
      padding: "10px",
      width: "105%",
    },
    resumesideR: {
      padding: "10px",
      width: "50%",
      background: "#C1EBD9",
    },
    img: {
      paddingBottom: "5px",
      width: "25%",
      height: "25px",
      objectFit: "contain",
    },
    logo: {
      paddingBottom: "5px",
      width: "12%",
      // height: "70px",
      objectFit: "contain",
    },
  };
  return (
    <>
      <div style={Style.resumeWrapper}>
        <div
          id="resume-container"
          className="resume-container"
          style={Style.resumesideL}
        >
          <div className="image">
            <img
              src="https://viosa-storage-2.s3.amazonaws.com/20240902T112524458Zimage001.jpg"
              alt="logo"
              style={Style.logo}
            />
          </div>
          <div className="resume-head d-flex justify-content-between">
            <div className="">
              <h1 style={Style.title}>Ajay Shah</h1>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h2 style={Style.profession}>Marketing Chief</h2>
              </div>
            </div>
          </div>
          <div style={Style.marginHR1}></div>

          <div>
            <div>
              <div>
                <div class="yui-gf mb-2">
                  <div class="yui-u first d-flex align-items-center">
                    <h2 style={Style.headings}>Summary</h2>
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "rgb(88 149 123)",
                        height: "5px",
                      }}
                    />
                  </div>
                  <div class="yui-u">
                    <p style={Style.common}>
                      Progressively evolve cross-platform ideas before impactful
                      infomediaries. Energistically visualize tactical
                      initiatives before cross-media catalysts for change.
                    </p>
                  </div>

                  <div style={Style.marginHR1}></div>
                </div>

                <>
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex justify-content-between align-items-center">
                      <h2 style={Style.headings}>Experience</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u ">
                      <div class="job">
                        <div className="d-flex justify-content-between">
                          <p style={Style.eduheads}>
                            Senior Interface Designer
                          </p>
                          <p style={Style.common}>May 2005 - June 2007</p>
                        </div>
                        <h2 style={Style?.subHeading}>
                          Facebook , <small>California</small>
                        </h2>
                        <p style={Style.common}>
                          Intrinsicly enable optimal core competencies through
                          corporate relationships. Phosfluorescently implement
                          worldwide vortals and client-focused imperatives.{" "}
                        </p>
                      </div>
                      <div class="job">
                        <div className="d-flex justify-content-between">
                          <p style={Style.eduheads}>
                            Junior Interface Designer
                          </p>
                          <p style={Style.common}>May 2005 - June 2007</p>
                        </div>
                        <h2 style={Style?.subHeading}>
                          Facebook , <small>California</small>
                        </h2>
                        <p style={Style.common}>
                          Intrinsicly enable optimal core competencies through
                          corporate relationships. Phosfluorescently implement
                          worldwide vortals and client-focused imperatives.{" "}
                        </p>
                      </div>
                    </div>

                    <div style={Style.marginHR1}></div>
                  </div>
                </>

                <>
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings}>Projects</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u ">
                      <div class="job">
                        <h2 style={Style.subHeadings}>
                          Social Media Application
                        </h2>
                        <div className="d-flex gap-4">
                          <p style={Style.common}>May 2021 - Jun 2021</p>
                        </div>
                        <p style={Style.common}>
                          lorem ipsum lorem ipsum lorem ipsum
                        </p>
                        <h2 style={Style.subHeadings}>Website Development </h2>
                        <div className="d-flex gap-4">
                          <p style={Style.common}>May 2021 - Jun 2021</p>
                        </div>
                        <p style={Style.common}>
                          lorem ipsum lorem ipsum lorem ipsum
                        </p>
                      </div>
                    </div>

                    <div style={Style.marginHR1}></div>
                  </div>
                </>

                <>
                  <div class="yui-gf last mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings}>Education</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div className="">
                      <div class="yui-u d-flex justify-content-between">
                        <h2 style={Style.eduheads}>
                          Bachelors of Engineering - IT
                        </h2>
                        <p style={Style.common}>May 2021 </p>
                      </div>
                      <div class="yui-u d-flex justify-content-between mb-2">
                        <p style={Style.common}>Mumbai University, Mumbai</p>
                        <p style={Style.common}>SGPA - 9</p>
                        <p style={Style.common}>90%</p>
                      </div>
                    </div>
                    <div style={Style.marginHR1}></div>
                  </div>
                </>
                <>
                  <div class="yui-gf last mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings}>Acheivements</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <>
                        <div class="yui-u d-flex justify-content-between">
                          <div>
                            <h2 style={Style.subHeadings}>
                              First Price in Hackathon
                            </h2>
                          </div>
                          <div>
                            <p style={Style.common}>2017</p>
                          </div>
                        </div>
                        <div>
                          <p style={Style.common}>
                            Build a robot that flows the line. My robot was the
                            fastest and scored highest on all metrics.
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                </>
                <>
                  <div class="yui-gf last mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings}>Certificates</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <>
                        <div class="yui-u d-flex justify-content-between">
                          <h2 style={Style.eduheads}>Full Stack Developer</h2>
                          <p style={Style.common}>2021-06-16</p>
                        </div>
                        <div class="yui-u">
                          <p style={Style.common}>SimpliLearn</p>
                        </div>
                      </>
                    </div>
                  </div>
                </>
                <>
                  <div class="yui-gf last mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings}>Voluntary</h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <>
                        <div class="yui-u d-flex justify-content-between">
                          <div>
                            <h2 style={Style.subHeadings}>Project Manager</h2>
                            <h2 style={Style.common}>Mumbai</h2>
                          </div>
                          <div>
                            <p style={Style.common}>2017 - Present</p>
                          </div>
                        </div>
                        <div>
                          <p style={Style.common}>
                            1. Project managed multiple initiatives and events
                            for a local NGO, including fundraising campaigns,
                            organizational restructuring, and volunteer
                            recruitment efforts. 2. Developed partnerships with
                            local businesses and organizations to support the
                            goals of the NGO. 3. Coordinated and supervised
                            volunteer activities, ensuring that tasks were
                            completed in a timely and efficient manner.
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
        <div
          id="resume-container"
          className="resume-container"
          style={Style.resumesideR}
        >
          <div className="resume-head d-flex justify-content-between">
            <img
              src="https://viosa-storage-2.s3.amazonaws.com/profile.png"
              alt="profile"
              style={Style.img}
            />
          </div>
          {/* <div style={{ flex: 1, backgroundColor: "rgb(88 149 123)", height: "10px" }} /> */}
          {/* <div style={Style.marginHR}></div> */}
          <div class="yui-u first d-flex align-items-center">
            <h2 style={Style.headings}>Contact</h2>
            <div
              style={{
                flex: 1,
                backgroundColor: "rgb(88 149 123)",
                height: "5px",
              }}
            />
          </div>
          <div className="resume-head justify-content-between mb-2">
            <div className="">
              <h4 style={Style.common}>abc@email.com</h4>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <h4 style={Style.common}>+91 9090900900</h4>
              </div>
            </div>
            <div class="yui-u">
              <div class="contact-info">
                <div class="contact-info">
                  <h4 style={Style.common}>Mumbai, Maharshtra, India</h4>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div>
                <>
                  <div class="yui-gf  mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings} className="mb-0">
                        Skills
                      </h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>XHTML</li>
                          <li style={Style?.common}>CSS</li>
                          <li style={Style?.common}>Javascript</li>
                          <li style={Style?.common}>XHTML</li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>CSS</li>
                          <li style={Style?.common}>XHTML</li>
                          <li style={Style?.common}>Javascript</li>
                          <li style={Style?.common}>CSS</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>

                <>
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings} className="mb-0">
                        Hobby
                      </h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>Music</li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>Painting</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings} className="mb-0">
                        Languages Known
                      </h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>English</li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>Hindi</li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>Marathi</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div class="yui-gf mb-2">
                    <div class="yui-u first d-flex align-items-center">
                      <h2 style={Style.headings} className="mb-0">
                        Links
                      </h2>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: "rgb(88 149 123)",
                          height: "5px",
                        }}
                      />
                    </div>
                    <div class="yui-u d-flex">
                      <div style={Style.marginUl}>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>
                            https://linkedin.com/ajayshah213
                          </li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>
                            https://linkedin.com/ajayshah213
                          </li>
                        </ul>
                        <ul style={Style?.ul}>
                          <li style={Style?.common}>
                            https://linkedin.com/ajayshah213
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RT3non;
