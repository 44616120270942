import Cookies from "js-cookie";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { CreateResume } from "screen/CreateResume/CreateResume";
import CreateResume1 from "New Ui/User Journey/CreateResume1";
import { Dashboard } from "screen/Dashboard/Dashboard";
import { Templates } from "screen/Templates";
import { CreateCover } from "screen/CreateCover";
import { BestResumePractice } from "screen/BestResumePractice";
import { SelectedCourse } from "./components/bestResumePractice/SelectedCourse";
import { Profile } from "screen/Profile";
import { Review } from "./screen/Review/Review";
import { Authentication } from "components/Authentication/Authentication";
import "./styles/card.css";
import "./styles/Navbar.css";
import "./styles/sidebar.css";
import "./styles/templates.css";
import "./styles/createresume.css";
import "./styles/cover.css";
import { ProtectedRoutes } from "helper/ProctedRoutes";
import CreateVideo from "screen/CreateVideo";
import Pricing from "screen/Pricing";
import RT1non from "components/ResumeTemplate/T1/RT1non";
import CT1non from "components/CoverLetterTemplate/CT1/CT1non";
import T2non from "components/ResumeTemplate/T2/RT2non";
import Subscription from "screen/Subscription/Subscription";
import Subscription2 from "screen/Subscription/Subscription2";
import { ReviewFinishup } from "screen/Review/ReviewFinishup";
import CT2non from "components/CoverLetterTemplate/CT2/CT2non";
import RT3non from "components/ResumeTemplate/T1/RT1non";
import { useDispatch } from "react-redux";
import { removedata, setUser } from "helper/redux/Slice/UserSlice";
import { RT2editable } from "components/ResumeTemplate/T2/RT2editable.jsx";
import CoverStepsWrapper from "New Ui/User Journey/CoverStepsWrapper";

export const PageRoutes = () => {
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  if (!!id) {
    Cookies.remove("token");
    dispatch(removedata(null));
  }
  const token = Cookies.get("token");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create"
          element={
            token ? (
              // <ProtectedRoutes>
              <CreateResume1 />
            ) : (
              // </ProtectedRoutes>
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create/cover"
          element={
            token ? (
              // <ProtectedRoutes>
              <CoverStepsWrapper />
            ) : (
              // </ProtectedRoutes>
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create/cover/:id"
          element={
            token ? (
              // <ProtectedRoutes>
              <CoverStepsWrapper />
            ) : (
              // </ProtectedRoutes>
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/edit/:id"
          element={
            token ? (
              // <ProtectedRoutes>
              <CreateResume1 />
            ) : (
              // </ProtectedRoutes>
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/t1"
          element={
            token ? (
              <ProtectedRoutes>
                <RT3non />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/templates"
          element={
            token ? (
              <ProtectedRoutes>
                <Templates />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-resume"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateResume />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-resume/automated/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateResume />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-resume/ai/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateResume />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-resume/trail/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateResume />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-cover-letter"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateCover />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-cover-letter/fresher/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateCover />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-cover-letter/experience/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateCover />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/t1"
          element={
            token ? (
              <ProtectedRoutes>
                <T2non />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/create-video-resume"
          element={
            token ? (
              <ProtectedRoutes>
                <CreateVideo />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/best-resume-practice"
          element={
            token ? (
              <ProtectedRoutes>
                <BestResumePractice />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/best-resume-practice/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <SelectedCourse />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/review-resume"
          element={
            token ? (
              <ProtectedRoutes>
                <Review />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/review-resume/:id"
          element={
            token ? (
              <ProtectedRoutes>
                <ReviewFinishup />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/my-profile"
          element={
            token ? (
              <ProtectedRoutes>
                <Profile />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/subscription"
          element={
            token ? (
              <ProtectedRoutes>
                <Subscription2 />
              </ProtectedRoutes>
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
        <Route
          path="/auth"
          element={token ? <Navigate to="/" /> : <Authentication />}
        />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Routes>
    </BrowserRouter>
  );
};
