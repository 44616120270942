import React, { useState } from "react";
import { useSelector } from "react-redux";
import UploadFromResume from "./Steps to cover/UploadFromResume";
import CoverResume from "./Steps to cover/CoverTextField";

function CoverStepsWrapper() {
  // const CoverStep = useSelector((item) => item.cover.coverStep);
  // const [coverStep, setcoverStep] = useState(1);
  return (
    <>
      <UploadFromResume />
      {/* {coverStep === 2 ? <CoverResume setcoverStep={setcoverStep} /> : <></>} */}
    </>
  );
}

export default CoverStepsWrapper;
