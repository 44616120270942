import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaRegSave } from "react-icons/fa";
import { ChatGPTAPI } from "helper/api";
import { setSummaryFields } from "helper/redux/Slice/ResumeSlice";
import { RiDeleteBin5Line } from "react-icons/ri";

function SummarySection() {
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [summarytext, setsummarytext] = useState("");
  const [summaryEdit, setsummaryEdit] = useState(false);
  const dispatch = useDispatch();
  const [loadingAi, setloadingAi] = useState(false);
  const [editKey, seteditKey] = useState(null);

  const handleSave = () => {
    dispatch(setSummaryFields(summarytext));
    seteditKey(!editKey);
  };
  useEffect(() => {
    if (!!ResumeReduxData?.summary) {
      setsummarytext(ResumeReduxData?.summary);
    } else {
      setsummarytext("");
    }
  }, []);

  function calculateTotalExperience(experiences) {
    let totalMonths = 0;

    experiences?.forEach((exp) => {
      const start = new Date(exp.start);
      const end = exp.end ? new Date(exp.end) : new Date(); // assuming current date if end is not defined
      let months = (end.getFullYear() - start.getFullYear()) * 12;
      months -= start.getMonth();
      months += end.getMonth();
      totalMonths += months;
    });

    if (totalMonths < 12) {
      return `${totalMonths} month${totalMonths === 1 ? "" : "s"}`;
    } else {
      const years = Math.floor(totalMonths / 12);
      const remainingMonths = totalMonths % 12;
      return `${years} year${
        years === 1 ? "" : "s"
      } and ${remainingMonths} month${remainingMonths === 1 ? "" : "s"}`;
    }
  }
  const totalExperience = calculateTotalExperience(
    ResumeReduxData?.experiences
  );

  //   summary prompt

  let EducationForPrompt = "";
  ResumeReduxData?.education?.map(
    (item) =>
      (EducationForPrompt += item?.degree + ", " + item?.specialization + ";")
  );
  let ExperienceForPrompt = "";
  ResumeReduxData?.experiences?.map(
    (item) =>
      (ExperienceForPrompt +=
        item?.jobtype + ", " + item?.role + ", " + item?.company + ";")
  );
  let CertificationForPrompt = "";
  ResumeReduxData?.certification?.map(
    (item) => (CertificationForPrompt += item?.name + ", ")
  );

  let skillsForPrompt = "";
  ResumeReduxData?.skills?.map((item) => (skillsForPrompt += item + ", "));

  const isFresher =
    !ResumeReduxData?.experiences?.length || totalExperience === "0 months";
  const prompt = `Create a concise resume summary tailored for a job application. Limit the summary to no more than 30 words and write in a professional tone, without using quotes or special formatting.

Resume Details:
- Name: ${ResumeReduxData?.contact?.firstName} ${ResumeReduxData?.contact?.lastName}
- Education: ${EducationForPrompt}
- Key Skills: ${skillsForPrompt}
- Certifications: ${CertificationForPrompt}
- Work Experience Summary: ${ExperienceForPrompt}
- Total Professional Experience: ${totalExperience}

Guidance:
- For a Fresher: Emphasize academic achievements, relevant skills, and a strong eagerness to learn and contribute to organizational success.
- For an Experienced Candidate: Highlight significant career accomplishments, specialized skills, and a commitment to advancing organizational objectives.

Please tailor the summary directly for potential employers and ensure it succinctly encapsulates the candidate’s qualifications and career aspirations within the 30-word limit.`;
  //    isFresher
  //     ? `following resume details:
  //       Personal Information: ${ResumeReduxData?.contact?.firstName}, ${ResumeReduxData?.contact?.lastName}
  //       Education: ${EducationForPrompt}
  //       Skills: ${skillsForPrompt}
  //       Certifications: ${CertificationForPrompt}

  //       Note (important):
  //       write a short summary of 70 words for resume as fresher for this resume . giving you the refrence for the output . provide summary similar to below . give the summary in the format below , and without "" or ''. just give one paragraph of at max 70 words .

  //       Example summary:
  //       Aspiring Management Trainee with a keen interest in organizational management. Eager to apply academic knowledge in real-world scenarios. Strong analytical, problem-solving, and communication skills combined with a proactive attitude and a willingness to learn and grow within a dynamic and fast-paced environment.
  //       `
  //     : `You've been provided with a sample resume in the following format:
  //           1. Personal Information: ${ResumeReduxData?.contact?.firstName}, ${ResumeReduxData?.contact?.lastName}
  //           2. Work Experience: ${ExperienceForPrompt}
  //           3. Education: ${EducationForPrompt}
  //           6. Skills: ${skillsForPrompt}
  //           7. Certifications: ${CertificationForPrompt}
  //           8. total experience : ${totalExperience}

  //           Note :
  //         write a short summary of 70 words for resume with ${totalExperience} of Experience. giving you the refrence for the output . provide summary similar to below .
  //         give the summary in the format below , and without "" or ''. just give one paragraph of at max 70 words .
  // Referrence for experienced:
  //       Experienced Management Trainee with expertise in applying leadership and technical skills in dynamic business environments. Skilled in data analysis, problem-solving, and strategic planning. Proven ability to lead cross-functional teams and deliver business growth through innovative solutions. Committed to continuous learning and professional development.`;

  const postChatGPTData = async (e) => {
    e.preventDefault();
    setloadingAi(true);

    const payload = {
      question: prompt,
      temperature: 0.1,
    };

    try {
      const res = await ChatGPTAPI(payload);
      console.log(res.data.text);
      const generatedDescription = res.data.trim();
      setsummarytext(generatedDescription);
      //   if (generatedDescription) {
      //     // Prepend bullet point if not already present

      //     toast.success("New experience description added successfully!");
      //   }
      setloadingAi(false);
    } catch (error) {
      setloadingAi(true);

      console.error(error);
    }
  };

  const handleDescriptionChange = (index, e) => {
    setsummarytext(e.target.value);
  };
  const handleKeyDown = () => {};
  return (
    <>
      <div className="primary-border rounded p-3">
        <h2 className="primary-text  text-3xl font-semibold d-flex justify-content-between">
          Summary
          <div>
            {/* {summaryEdit ? (
              <FaRegSave
                className="primary-icon me-2 cursor-pointer"
                onClick={() => {
                  saveSummaryToRedux();
                  setsummaryEdit(!summaryEdit);
                }}
              />
            ) : (
              <FaRegEdit
                className="primary-icon me-2 cursor-pointer"
                onClick={() => setsummaryEdit(!summaryEdit)}
              />
            )} */}
            {/* <RiDeleteBin5Line className="primary-icon mx-2 ms-1" /> */}
          </div>
        </h2>
        <div className="my-4">
          {editKey ? (
            <BasicTextAreaWithAi
              name="description"
              index={1}
              handleChange={handleDescriptionChange}
              loadingAi={loadingAi}
              label="Summary"
              postChatGPTData={postChatGPTData}
              data={summarytext}
              value={summarytext}
              onKeyDown={handleKeyDown}
              placeholder="Write Summary...."
            />
          ) : (
            <div className="my-2">
              <ul
                style={{ borderLeft: "2px solid #602a94" }}
                className="  rounded  p-3 position-relative d-flex bg-light ai-description-box"
              >
                <div className="position-relative w-100">
                  <p className=" rounded my-3 ">
                    {!!ResumeReduxData?.summary
                      ? ResumeReduxData?.summary
                      : "here is your summary"}
                  </p>
                </div>
                <span className="primary-text text-xl align-self-center px-2">
                  AI
                </span>
              </ul>
            </div>
          )}
          <div className="d-flex justify-content-end gap-3 mt-3">
            {editKey ? (
              // Show Save button when editing
              <button
                className=" p-2 rounded text-sm secondary-btn"
                onClick={() => handleSave()}
              >
                Save Summary
              </button>
            ) : (
              // Show Edit button when not editing
              <button
                className=" p-2 rounded text-sm secondary-btn"
                onClick={() => seteditKey(!editKey)}
              >
                Edit Summary
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SummarySection;
