import RT3non from "components/ResumeTemplate/T1/RT1non";
import { setBaseStep } from "helper/redux/Slice/UtilsSlice";
import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";

function ResumeCard({ component, handleSelectedTemplate, data }) {
  const dispatch = useDispatch();
  const handleBaseStep = (step) => {
    dispatch(setBaseStep(step));
  };
  return (
    <>
      <Card
        onClick={() => {
          handleBaseStep(3);
          handleSelectedTemplate(data?.templateNo);
        }}
        className="resume-common-card rounded"
      >
        <Card.Body style={{ padding: "7px " }}>{component}</Card.Body>
      </Card>
    </>
  );
}

export default ResumeCard;
