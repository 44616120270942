import React, { useState } from "react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import "./mobilesidebar.css"; // Assuming you have specific styles for mobile
import {
  MdAssignment,
  MdOutlineContactPage,
  MdOutlineSchool,
  MdWorkOutline,
} from "react-icons/md";
import "./usersidebar.css";
import { FaTools } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";

import { SiHyperskill } from "react-icons/si";
import { MdOutlineAssignment } from "react-icons/md";
import { GrVulnerability } from "react-icons/gr";
import { CiCircleMore } from "react-icons/ci";
import { MdAddchart } from "react-icons/md";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { AiOutlineAim } from "react-icons/ai";
import logo from "../../assets/image/logo.svg";
import logoSmall from "../../assets/image/logo-small.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineMenuOpen } from "react-icons/md";
import { useNavigate } from "react-router";
import { RiMenuFold4Line } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
const MobileDropdownSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepResume = useSelector((item) => item.utils.stepResume);

  //   const steps = [
  //     { id: "contact", name: "Contact" },
  //     { id: "experience", name: "Experience" },
  //     { id: "education", name: "Education" },
  //     { id: "project", name: "Projects" },
  //     { id: "certificate", name: "Certificate" },
  //     { id: "skill", name: "Skills" },
  //     { id: "other", name: "Others" },
  //     { id: "finish", name: "Finish" },
  //   ];
  const steps = [
    {
      id: "contact",
      icon: <MdOutlineContactPage fontSize={22} />,
      name: "Contact",
      step: 1,
    },
    {
      id: "experience",
      icon: <MdWorkOutline fontSize={22} />,
      name: "Experience",
      step: 2,
    },
    {
      id: "education",
      icon: <MdOutlineSchool fontSize={22} />,
      name: "Education",
      step: 3,
    },
    {
      id: "project",
      icon: <MdOutlineAssignment fontSize={22} />,
      name: "Project",
      step: 4,
    },
    {
      id: "activity",
      icon: <FiActivity fontSize={22} />,
      name: "Activity",
      step: 5,
    },
    {
      id: "skill",
      icon: <AiOutlineAim fontSize={22} />,
      name: "Skill",
      step: 6,
    },
    {
      id: "other",
      icon: <MdAddchart fontSize={22} />,
      name: "Others",
      step: 7,
    },
    {
      id: "review",
      icon: <MdOutlineAssignmentTurnedIn fontSize={22} />,
      name: "Review",
      step: 8,
    },
  ];

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectStep = (step) => {
    setActiveStep(step);
    setIsOpen(false);
    dispatch(setResumeStep(step));
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-header" onClick={handleDropdownToggle}>
        <span>{steps[activeStep - 1].name}</span>
        {isOpen ? <MdExpandLess /> : <MdExpandMore />}
      </div>

      {isOpen && (
        <div className="sidebar-body">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`dropdown-item ${
                index + 1 === activeStep ? "active" : ""
              }`}
              onClick={() => handleSelectStep(index + 1)}
            >
              {step.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileDropdownSidebar;
