import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import UserSidebar from "New Ui/Common/UserSidebar";
import React, { useEffect, useState } from "react";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoIosAdd, IoIosArrowBack } from "react-icons/io";
import EducationAccordian from "./EducationAccordian";
import { useDispatch, useSelector } from "react-redux";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import {
  setEducationFields,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import toast from "react-hot-toast";

function Education() {
  const dispatch = useDispatch();
  const EducationReduxData = useSelector((item) => item?.resume?.education);
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const [EducationArr, setEducationArr] = useState([
    {
      status: "",
      degree: "",
      specialization: "",
      institute: "",
      location: "",
      date: "",
      minor: "",
      academicScore: "",
      gpaOutOf: "",
      gpa: "",
      percentage: "",
      major: "",
    },
  ]);

  useEffect(() => {
    if (EducationReduxData?.length > 0) {
      setEducationArr(EducationReduxData);
    } else {
      setEducationArr([
        {
          status: "",
          degree: "",
          specialization: "",
          institute: "",
          location: "",
          date: "",
          minor: "",
          academicScore: "",
          gpaOutOf: "",
          gpa: "",
          percentage: "",
          major: "",
        },
      ]);
    }
  }, []);

  const validateEducation = (education) => {
    const errors = {};
    if (!education.status) {
      errors.role = "Status is required";
    }
    if (!education.degree) {
      errors.company = "Degree name is required";
    }
    if (!education.institute) {
      errors.location = "institute is required";
    }
    if (!education.date) {
      errors.start = "date is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...EducationArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    if (value === "Secondary(10th)" || value === "Higher Secondary(12th)") {
      newArr[index].degree = value; // Set degree to the same as status
    }
    setEducationArr(newArr);
  };
  const handleAddEducation = () => {
    setsubmitAttempted(true);

    const lastEducation = EducationArr[EducationArr?.length - 1];
    const errors = validateEducation(lastEducation);

    if (Object.keys(errors).length === 0) {
      const newEducation = {
        status: "",
        degree: "",
        specialization: "",
        institute: "",
        location: "",
        date: "",
        minor: "",
        academicScore: "",
        gpaOutOf: "",
        gpa: "",
        percentage: "",
        major: "",
      };
      setEducationArr([...EducationArr, newEducation]);
      dispatch(setEducationFields(EducationArr));
      toast.success(" Education Saved ", {
        duration: 5000, // Display the toast for 4 seconds
      });
      setsubmitAttempted(false);
    } else {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
    }
  };

  const handleRemoveEducation = (index) => {
    const newArr = [...EducationArr];
    newArr.splice(index, 1);
    setEducationArr(newArr);
    if (index === 0) {
      setEducationArr([
        {
          status: "",
          degree: "",
          specialization: "",
          institute: "",
          location: "",
          date: "",
          minor: "",
          academicScore: "",
          gpaOutOf: "",
          gpa: "",
          percentage: "",
          major: "",
        },
      ]);
    }
  };

  const handleSubmitAndNext = () => {
    setsubmitAttempted(true);

    const lastEducation = EducationArr[EducationArr.length - 1];
    const errors = validateEducation(lastEducation);

    if (
      EducationArr?.length === 1 &&
      EducationArr[0]?.status === "" &&
      EducationArr[0]?.degree === "" &&
      EducationArr[0]?.institute === "" &&
      EducationArr[0]?.date === ""
    ) {
      toast.success("Education Skipped", {
        duration: 5000, // Display the toast for 4 seconds
      });
      dispatch(setResumeStep(4)); // Update the step even if skipped
      dispatch(setEducationFields([]));
    } else if (EducationArr.length >= 1 && Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
      // Do not update the resume step because there are errors
    } else if (Object.keys(errors).length === 0) {
      dispatch(setEducationFields(EducationArr));
      toast.success("Education Saved", {
        duration: 5000, // Display the toast for 4 seconds
      });
      if (ResumeReduxData?.isResumeEdit) {
        dispatch(setResumeStep(9));
        dispatch(setIsResumeEdit(false));
      } else {
        dispatch(setResumeStep(4)); // Update the step after successful save
      }
    }
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };
  return (
    <>
      <div className="">
        <h1 className="primary-text mt-4 text-3xl font-semibold step-content-header">
          Add Education
        </h1>

        <form
          action=""
          className="mt-4 d-flex  flex-column  w-100 gap-x-14   resume-step-content"
        >
          <EducationAccordian
            EducationArr={EducationArr}
            setEducationArr={setEducationArr}
            handleRemoveEducation={handleRemoveEducation}
            handleChange={handleChange}
            submitAttempted={submitAttempted}
          />
        </form>
      </div>
      <div className="mt-3 d-flex justify-content-between resume-content-footer-btn pe-lg-5 pe-0">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>
        <button
          className="secondary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center"
          onClick={handleAddEducation}
        >
          <IoIosAdd fontSize={24} />
          Education
        </button>

        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          {EducationArr?.length === 1 &&
            EducationArr[0]?.status === "" &&
            EducationArr[0]?.degree === "" &&
            EducationArr[0]?.institute === "" &&
            EducationArr[0]?.date === ""
            ? "Skip & Next"
            : "Save & Next"}
        </button>
      </div>
    </>
  );
}

export default Education;
