import React, { useState, useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ChatGPTAPI } from "helper/api";
import {
  setExperienceFields,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import BasicTextAreaWithAi from "New Ui/Common/Input Fields/BasicTextAreaWithAi";
import toast from "react-hot-toast";

function FinishExperience() {
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [ExperienceArr, setExperienceArr] = useState([]);
  const [loadingAi, setLoadingAi] = useState(false);
  const [editKey, seteditKey] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setExperienceArr(ResumeReduxData?.experiences);
  }, [ResumeReduxData?.experiences]);

  const handleEditResumeStep = (step) => {
    dispatch(setResumeStep(step));
  };

  const handleIsResumeEdit = () => {
    dispatch(setIsResumeEdit(true));
  };

  // Handle description change
  const handleDescriptionChange = (index, e) => {
    console.log(index, e.target.value);
    const lines = e.target.value.split("\n");

    const newDescriptions = lines
      .map(
        (line) => line.replace(/^•\s*/, "") // Remove bullets for internal storage
      )
      .filter((line) => line.trim() !== ""); // Optionally filter out empty strings

    const newArr = [...ExperienceArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setExperienceArr(newArr);
  };
  console.log(ExperienceArr);

  // Handle key down events (Enter and Backspace)
  const handleKeyDown = (index, e) => {
    const textarea = e.target;
    const value = textarea.value;
    const position = textarea.selectionStart;

    // Handling 'Enter' for new bullet points
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default newline behavior
      const beforeEnter = value.substring(0, position);
      const afterEnter = value.substring(position);
      const newValue = `${beforeEnter}\n• ${afterEnter}`;
      textarea.value = newValue;
      updateTextArea(index, newValue, position + 3); // Position cursor after the new bullet
    }

    // Handling 'Backspace' for bullet point removal
    else if (e.key === "Backspace") {
      if (position === 0) {
        e.preventDefault(); // Prevent backspace when at the start of the textarea
        return;
      }
      const charBeforeCursor = value.substring(position - 1, position);
      const twoCharsBeforeCursor = value.substring(position - 2, position);

      if (
        twoCharsBeforeCursor === "• " ||
        (charBeforeCursor === "\n" &&
          value.substring(position - 3, position - 1) === "•")
      ) {
        e.preventDefault();
        // Calculate new text removing the bullet or the newline directly before a bullet
        const newStartPos =
          charBeforeCursor === "\n" ? position - 3 : position - 2;
        const beforeBackspace = value.substring(0, newStartPos);
        const afterBackspace = value.substring(position);
        const newValue = `${beforeBackspace}${afterBackspace}`;
        textarea.value = newValue;
        updateTextArea(index, newValue, newStartPos);
      }
    }
  };

  const updateTextArea = (index, newValue, newCursorPos) => {
    // Update React state
    const newDescriptions = newValue.split("\n").map(
      (line) => (line.startsWith("• ") ? line.slice(2) : line) // Remove bullets for state storage
    );
    const newArr = [...ExperienceArr];
    newArr[index] = { ...newArr[index], description: newDescriptions };
    setExperienceArr(newArr);

    // Update cursor position
    setTimeout(() => {
      const textarea = document.querySelectorAll("textarea")[index]; // Target the correct textarea based on index
      textarea.selectionStart = textarea.selectionEnd = newCursorPos;
    }, 0);
  };

  // Handle AI-generated description
  const postChatGPTData = async (e, index, experience) => {
    e.preventDefault();
    setLoadingAi(true);

    const payload = {
      question: `Give one ATS compliant work experience description point in past tense for the role of ${experience?.role} at ${experience?.company}, with the job type being ${experience?.jobtype}. Ensure the sentence is concise, free of grammatical errors, and optimized for ATS parsing. Give only text without any "" or '' or -`,
      temperature: 0.7,
    };

    try {
      const res = await ChatGPTAPI(payload);
      const generatedDescription = res.data.trim().replaceAll(`"`, "");
      if (generatedDescription) {
        // Prepend bullet point if not already present
        const formattedDescription = `${generatedDescription}`;
        // Create a new array with the new description added
        const updatedDescriptions = [
          ...experience.description,
          formattedDescription,
        ];
        // Create a new experience object with updated descriptions
        const updatedExperience = {
          ...experience,
          description: updatedDescriptions,
        };
        // Create a new array for the entire experiences, replacing the old experience with the updated one
        const newArr = [
          ...ExperienceArr.slice(0, index),
          updatedExperience,
          ...ExperienceArr.slice(index + 1),
        ];
        setExperienceArr(newArr); // Set the new array to the state
        toast.success("New point added ");
      }
      setLoadingAi(false);
    } catch (error) {
      setLoadingAi(false);
      console.error(error);
    }
  };

  const handleSave = () => {
    dispatch(setExperienceFields(ExperienceArr));
    seteditKey(null);
  };

  return (
    <>
      {ExperienceArr?.length > 0 && (
        <div className="primary-border rounded p-3">
          <h2 className="primary-text text-3xl font-semibold d-flex justify-content-between">
            Experience
            <div>
              <button
                onClick={() => {
                  handleIsResumeEdit();
                  handleEditResumeStep(2);
                }}
                className="primary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center text-sm"
              >
                Add/Edit Experience
              </button>
              {/* <FaRegEdit
                className="primary-icon me-2 cursor-pointer"
                onClick={() => {
                  handleIsResumeEdit();
                  handleEditResumeStep(2);
                }}
              /> */}
            </div>
          </h2>

          {ExperienceArr?.map((item, index) => {
            // const isEditing = editingKey === key; // Check if this AI box is being edited

            return (
              <div className="mt-3" key={index}>
                <h1 className="text-2xl font-semibold">{item?.role}</h1>
                <h6>
                  {item?.company}, {item?.location}
                </h6>
                <p>
                  {`${moment(item?.start).format("MMM-YYYY")} - ${
                    !!item?.end && !item.isWorking
                      ? moment(item?.end).format("MMM-YYYY")
                      : "Present"
                  }`}
                </p>
                <div className="my-2">
                  {editKey === index ? (
                    <div className="">
                      <BasicTextAreaWithAi
                        name="description"
                        index={index}
                        handleChange={handleDescriptionChange}
                        label="Description"
                        postChatGPTData={postChatGPTData}
                        data={item}
                        loadingAi={loadingAi}
                        value={item.description
                          .map((desc) => `• ${desc}`)
                          .join("\n")}
                        // onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyDown}
                        placeholder="Type each point and press Enter for a new point"
                      />
                    </div>
                  ) : (
                    <ul
                      style={{ borderLeft: "4px solid #602a94" }}
                      className="rounded p-3 position-relative d-flex bg-light ai-description-box"
                    >
                      <div className="position-relative w-100">
                        {item?.description?.map((description, idx) => (
                          <li key={idx}>
                            <span className="me-1">•</span> {description}
                          </li>
                        ))}
                      </div>
                      <span className="primary-text text-xl align-self-center px-2">
                        AI
                      </span>
                    </ul>
                  )}
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    {editKey === index ? (
                      // Show Save button when editing
                      <button
                        className=" p-2 rounded text-sm secondary-btn"
                        onClick={() => handleSave()}
                      >
                        Save Description
                      </button>
                    ) : (
                      // Show Edit button when not editing
                      <button
                        className=" p-2 rounded text-sm secondary-btn"
                        onClick={() => seteditKey(index)}
                      >
                        Edit Description
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default FinishExperience;
