import React from "react";
import { useSelector } from "react-redux";

console.log(Number((100 / 8).toFixed(1)));
const UseCompleteness = ({ data }) => {
  console.log(data);
  let CompletenessValue = 0;
  const resumeData = useSelector((state) => state.resume);
  let ResumeRedux;

  if (!!data) {
    ResumeRedux = data;
  } else {
    ResumeRedux = resumeData;
  }
  const CoverRedux = useSelector((item) => item.cover);
  const types = useSelector((item) => item.types);
  if (types?.doctype === "resume" && types?.docsubtype !== "automatedResume") {
    if (ResumeRedux?.experiences?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.education?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.skills?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.hobby?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.projects?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.involvement?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.certification?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (ResumeRedux?.summary?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
  } else if (
    types?.doctype === "resume" &&
    types?.docsubtype === "automatedResume"
  ) {
    if (ResumeRedux?.experiences?.length > 0) {
      CompletenessValue += Number((100 / 4).toFixed(1));
    }
    if (ResumeRedux?.education?.length > 0) {
      CompletenessValue += Number((100 / 4).toFixed(1));
    }
    if (ResumeRedux?.skills?.length > 0) {
      CompletenessValue += Number((100 / 4).toFixed(1));
    }
    if (ResumeRedux?.hobby?.length > 0) {
      CompletenessValue += Number((100 / 4).toFixed(1));
    }
  } else if (types?.doctype === "cover") {
    if (!!CoverRedux?.targetJob.position && !!CoverRedux?.targetJob.company) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (
      !!CoverRedux?.to.name &&
      !!CoverRedux?.to.email &&
      !!CoverRedux?.to.position
    ) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (CoverRedux?.experiences?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (CoverRedux?.education?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (CoverRedux?.projects?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (CoverRedux?.involvement?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (CoverRedux?.certification?.length > 0) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
    if (!!CoverRedux?.content) {
      CompletenessValue += Number((100 / 8).toFixed(1));
    }
  }

  return CompletenessValue;
};

export default UseCompleteness;
