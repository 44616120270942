import Accordion from "react-bootstrap/Accordion";
import BasicInput from "../../../Common/Input Fields/BasicInput";
import BasicSelect from "../../../Common/Input Fields/BasicSelect";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import { RiDeleteBin5Line } from "react-icons/ri";
import BasicSearchInput from "New Ui/Common/Input Fields/BasicSearchInput";
import {
  BachelorsData,
  SecondaryData,
  MastersData,
} from "components/JSON/EducationJSON";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
function EducationAccordian({
  EducationArr,
  setEducationArr,
  handleChange,
  handleRemoveEducation,
  submitAttempted,
}) {
  const handleSearchChange = (index, name, value) => {
    const newArr = [...EducationArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setEducationArr(newArr);
  };
  const suggestions = [...BachelorsData, ...SecondaryData, ...MastersData];

  const handleDateChange = (index, name, value) => {
    const newArr = [...EducationArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setEducationArr(newArr);
  };

  const getCourseFieldProps = (status) => {
    switch (status) {
      case "Secondary(10th)":
      case "Higher Secondary(12th)":
        return {
          disabled: true,
          suggestions: [], // No suggestions for these statuses
        };
      case "Bachelors":
        return {
          disabled: false,
          suggestions: BachelorsData,
        };
      case "Diploma":
        return {
          disabled: false,
          suggestions: BachelorsData,
        };
      case "Masters":
        return {
          disabled: false,
          suggestions: MastersData,
        };
      case "Doctorate":
        return {
          disabled: false,
          suggestions: MastersData,
        };
      default:
        return {
          disabled: true,
          suggestions: [],
        };
    }
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  return (
    <Accordion defaultActiveKey={0}>
      {EducationArr?.map((item, index) => {
        const { disabled, suggestions } = getCourseFieldProps(item.status);
        // handleChange(index,)
        return (
          <div className="d-flex align-items-center">
            <Accordion.Item
              eventKey={index}
              className="mb-2  rounded w-100"
              style={{ border: "1px solid #602a94" }}
            >
              <Accordion.Header>
                <div className="d-flex justify-content-between w-100 pe-4">
                  <span>{!!item?.degree ? item?.degree : "Course Name"}</span>
                  <div></div>
                </div>
              </Accordion.Header>
              <Accordion.Body style={{ visibility: "visible" }}>
                <div className="flex-row-responsive">
                  <BasicSelect
                    index={index}
                    name="status"
                    label="Education Level"
                    handleChange={handleChange}
                    required={true}
                    submitAttempted={submitAttempted}
                    options={[
                      "Masters",
                      "Doctorate",
                      "Bachelors",
                      "Diploma",
                      "Higher Secondary(12th)",
                      "Secondary(10th)",
                    ]}
                    value={item?.status}
                    placeholder="Select"
                  />
                  <BasicSearchInput
                    name="degree"
                    handleSearchChange={handleSearchChange}
                    label="Course"
                    required={true}
                    handleChange={handleChange}
                    disabled={disabled}
                    suggestions={suggestions}
                    submitAttempted={submitAttempted}
                    // disabled={disableCourse}
                    index={index}
                    value={item?.degree}
                    placeholder={`Eg. ${
                      item?.status === "Masters" ? "MBA" : "BBA"
                    }`}
                  />
                </div>
                <div className="flex-row-responsive">
                  <BasicInput
                    name="specialization"
                    index={index}
                    label="Specialization/Major"
                    value={item?.specialization}
                    handleChange={handleChange}
                    placeholder="Eg . information technology"
                  />
                  {item?.status === "Masters" && (
                    <BasicInput
                      name="minor"
                      index={index}
                      label="Minor"
                      handleChange={handleChange}
                      value={item?.minor}
                      placeholder="Eg. English"
                    />
                  )}
                </div>
                <div className="flex-row-responsive">
                  <BasicInput
                    name="institute"
                    label="Institute, Location"
                    required={true}
                    value={item?.institute}
                    submitAttempted={submitAttempted}
                    index={index}
                    handleChange={handleChange}
                    placeholder="Eg . Amity Internation , Noida"
                  />
                </div>
                <div className="flex-row-responsive position-relative">
                  <BasicSelect
                    name="academicScore"
                    label="GPA or Percentage"
                    handleChange={handleChange}
                    options={["GPA", "SGPA", "CGPA", "Percentage"]}
                    value={item?.academicScore}
                    index={index}
                    placeholder="Select"
                  />
                  <BasicDate
                    name="date"
                    index={index}
                    handleChange={handleDateChange}
                    required={true}
                    label="Completed/Expected"
                    submitAttempted={submitAttempted}
                    data={item?.date}
                    placeholder="Eg. BBA"
                  />
                </div>
                <div className="d-flex gap-5 align-items-center">
                  {!!item?.academicScore ? (
                    item?.academicScore === "Percentage" ? (
                      <>
                        <BasicInput
                          name="percentage"
                          type="number"
                          label="Percentage"
                          value={item?.percentage}
                          index={index}
                          handleChange={handleChange}
                          placeholder="Eg . 80"
                        />
                      </>
                    ) : (
                      <>
                        <BasicInput
                          name="gpa"
                          type="number"
                          label="GPA"
                          value={item?.gpa}
                          handleChange={handleChange}
                          placeholder="Eg 8"
                          index={index}
                        />

                        <BasicInput
                          type="number"
                          name="gpaOutOf"
                          handleChange={handleChange}
                          label="Out Of?"
                          index={index}
                          value={item?.gpaOutOf}
                          placeholder="Eg 10"
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <RiDeleteBin5Line
              onClick={() => {
                handleShowDelete();
                setdeleteIndex(index);
              }}
              fontSize={30}
              className="secondary-text align-self-start mt-2 ms-3 mr-0 cursor-pointer"
            />
            <Modal
              show={showDelete}
              onHide={handleCloseDelete}
              centered
              className="rounded"
            >
              <Modal.Header style={{ background: "#f0ddff" }} closeButton>
                <h4 className="text-lg">Are you sure?</h4>
              </Modal.Header>
              <Modal.Body>Do you want to delete this Education?</Modal.Body>
              <Modal.Footer>
                <button
                  className="primary-btn px-3 p-2 rounded"
                  onClick={handleCloseDelete}
                >
                  Cancel
                </button>
                <button
                  className="primary-btn  px-3 p-2 rounded"
                  onClick={() => {
                    toast.error("Education Deleted !");
                    handleRemoveEducation(deleteIndex);
                    handleCloseDelete();
                  }}
                >
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      })}
    </Accordion>
  );
}

export default EducationAccordian;
