import React from "react";
import { Placeholder } from "react-bootstrap";

function SkillsSkeleton() {
  return (
    <>
      <Placeholder animation="glow" xs={10} className="w-100">
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-3"
          xs={6}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-0"
          xs={5}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-3"
          xs={3}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-0"
          xs={8}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-3"
          xs={4}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-0"
          xs={7}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-3"
          xs={6}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-3 me-0"
          xs={5}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-2 me-3"
          xs={3}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-2 me-0"
          xs={8}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-2 me-3"
          xs={4}
        />
        <Placeholder
          style={{ padding: "0.8rem" }}
          className="mb-2 me-0"
          xs={7}
        />
      </Placeholder>
    </>
  );
}

export default SkillsSkeleton;
