import ExperienceAccordian from "New Ui/User Journey/Steps to resume/Experience/ExperienceAccordian";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import UserSidebar from "New Ui/Common/UserSidebar";
import React, { useState, useEffect } from "react";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoIosAdd, IoIosArrowBack } from "react-icons/io";
import ProjectAccordian from "./ProjectAccordian";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProjectAiResponseData,
  setIsResumeEdit,
  setProjectFields,
} from "helper/redux/Slice/ResumeSlice";
import toast from "react-hot-toast";

function Project() {
  const dispatch = useDispatch();
  const ProjectReduxData = useSelector((item) => item?.resume?.projects);
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const [ProjectArr, setProjectArr] = useState([
    {
      title: "",
      company: "",
      aidescription: "",
      start: "",
      end: "",
      role: "",
      isWorking: false,
      description: [],
    },
  ]);

  useEffect(() => {
    if (ProjectReduxData?.length > 0) {
      setProjectArr(ProjectReduxData);
    } else {
      setProjectArr([
        {
          title: "",
          company: "",
          aidescription: "",
          start: "",
          role: "",

          end: "",
          isWorking: false,
          description: [],
        },
      ]);
    }
  }, []);

  const validateProject = (experience) => {
    const errors = {};
    if (!experience.title) {
      errors.title = "Title is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...ProjectArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setProjectArr(newArr);
  };

  const handleAddProject = () => {
    setsubmitAttempted(true);

    const lastProject = ProjectArr[ProjectArr.length - 1];
    const errors = validateProject(lastProject);

    if (Object.keys(errors).length === 0) {
      const newProject = {
        title: "",
        company: "",
        aidescription: "",
        start: "",
        role: "",

        end: "",
        isWorking: false,
        description: "",
      };
      setProjectArr([...ProjectArr, newProject]);
      dispatch(setProjectFields(ProjectArr));
      toast.success("Project added ", {
        duration: 5000, // Display the toast for 4 seconds
      });
      setsubmitAttempted(false);
    } else {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
    }
  };

  const handleRemoveProject = (index) => {
    const newArr = [...ProjectArr];
    newArr.splice(index, 1);
    setProjectArr(newArr);
    if (index === 0) {
      setProjectArr([
        {
          title: "",
          company: "",
          role: "",

          aidescription: "",
          start: "",
          end: "",
          isWorking: false,
          description: "",
        },
      ]);
    }
  };

  const handleSubmitAndNext = () => {
    setsubmitAttempted(true);

    const lastProject = ProjectArr[ProjectArr.length - 1];
    const errors = validateProject(lastProject);
    if (ProjectArr?.length === 1 && ProjectArr[0]?.title === "") {
      toast.success("Project Skipped", {
        duration: 5000, // Display the toast for 4 seconds
      });
      dispatch(setResumeStep(5)); // Update the step even if skipped
      dispatch(setProjectFields([]));
    } else if (ProjectArr?.length >= 1 && Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
      // Do not update the resume step because there are errors
    } else if (Object.keys(errors).length === 0) {
      dispatch(setProjectFields(ProjectArr));
      toast.success("Project Saved ", {
        duration: 5000, // Display the toast for 4 seconds
      });
      if (ResumeReduxData?.isResumeEdit) {
        const projectsWithoutDescriptions = ProjectArr?.filter(
          (item) => item.description.length === 0
        );
        if (projectsWithoutDescriptions?.length > 0) {
          postProjectGPTData();
        }
        dispatch(setResumeStep(9));
        dispatch(setIsResumeEdit(false));
      } else {
        dispatch(setResumeStep(5)); // Update the step after successful save
      }
    }
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  // for getting all pointers in one go

  const postProjectGPTData = async () => {
    const projectsWithoutDescriptions = ProjectArr?.filter(
      (item) => item.description.length === 0
    );
    const projectDetails = projectsWithoutDescriptions
      ?.map(
        (project) =>
          `  Title: ${project?.title},Role:${project?.role} Company: ${project?.company
          }, Job Type: ${project?.jobType}, Start: ${project?.start}, End: ${project?.end || "Present"
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_project_details",
        description:
          "Format project details into a JSON array where each object contains a 'description' field with exactly five bullet points summarizing the project.",
        parameters: {
          type: "object",
          properties: {
            projects: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly three bullet points summarizing the project.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point  highlighting a key responsibility, accomplishment, or skill demonstrated in the project.",
                    },
                    minItems: 5,
                    maxItems: 5,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["projects"],
        },
      },
    ];

    const payload = {
      question: `Please generate concise bullet points for each project listed. Each project should be summarized in exactly five bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the project. Each bullet point must not exceed 50 words and atleast have 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to a project and contains a 'description' field with an array of five bullet points.

Project Details:
${projectDetails}

Please create a JSON array where each element is a JSON object for a specific project. Each object should contain a 'description' field with an array of five bullet points. Ensure the number of JSON objects matches the number of projects provided.

Expected JSON output format:
[
  {
    "description": ["Bullet point 1", "Bullet point 2", "Bullet point 3","Bullet point 4","Bullet point 5"]
  }
]

Please ensure the output strictly follows this format and each project is summarized in exactly five bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_project_details",
    };
    try {
      // const result = await ChatGPTAPI(payload); // Assuming ChatGPTAPI is your method to call the API
      // console.log(result);
      dispatch(GetProjectAiResponseData(payload));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="">
        <h1 className="primary-text text-3xl mt-4 font-semibold step-content-header">
          Add Project
        </h1>

        <form
          action=""
          className="mt-4 d-flex  flex-column  w-100 gap-x-14  resume-step-content"
        >
          <ProjectAccordian
            ProjectArr={ProjectArr}
            setProjectArr={setProjectArr}
            handleRemoveProject={handleRemoveProject}
            handleChange={handleChange}
            submitAttempted={submitAttempted}
          />
        </form>
      </div>
      <div className="mt-3 d-flex justify-content-between resume-content-footer-btn pe-lg-5 pe-0">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>
        <button
          className="secondary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center"
          onClick={handleAddProject}
        >
          <IoIosAdd fontSize={24} />
          Project
        </button>
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          {ProjectArr?.length === 1 && ProjectArr[0]?.title === ""
            ? "Skip & Next"
            : "Save & Next"}
        </button>
      </div>
    </>
  );
}

export default Project;
