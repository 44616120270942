import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAchievementAiResponseData,
  setAchievementsFields,
  setAllLinks,
  setCertificateFields,
  setHobbyFields,
  setlanguagesKnown,
  setResearchPapersFields,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import { Accordion } from "react-bootstrap";
import Certificate from "./Certificate";
import Language from "./Language";
import ResearchPaper from "./ResearchPaper";
import Achievement from "./Achievement";
import Hobby from "./Hobby";
import Link from "./Link";
import toast from "react-hot-toast";

function Other() {
  const [linkArr, setlinkArr] = useState([]);
  const [hobbyArr, sethobbyArr] = useState([]);
  const [LanguageArr, setLanguageArr] = useState([]);

  const [researchArr, setresearchArr] = useState([
    { title: "", author: "", date: "", url: "" },
  ]);
  const [certificateArr, setcertificateArr] = useState([
    { name: "", org: "", date: "" },
  ]);
  const [achievementArr, setachievementArr] = useState([
    { title: "", description: "", date: "" },
  ]);

  const dispatch = useDispatch();
  const LinkReduxData = useSelector((state) => state.resume.links);
  const ResumeReduxData = useSelector((state) => state.resume);
  const hobbyReduxData = useSelector((state) => state.resume.hobby);
  const languageReduxData = useSelector((state) => state.resume.languagesKnown);
  const researchReduxData = useSelector((state) => state.resume.researchPapers);
  const certificateReduxData = useSelector(
    (state) => state.resume.certification
  );
  const achievementReduxData = useSelector(
    (state) => state.resume.achievements
  );
  console.log(certificateArr);
  useEffect(() => {
    function setReduxArray(data, setFunction, emptyValue) {
      setFunction(data?.length > 0 ? data : emptyValue);
    }

    setReduxArray(LinkReduxData, setlinkArr, []);
    setReduxArray(hobbyReduxData, sethobbyArr, []);
    setReduxArray(languageReduxData, setLanguageArr, []);
    setReduxArray(researchReduxData, setresearchArr, [
      { title: "", author: "", date: "", url: "" },
    ]);
    setReduxArray(certificateReduxData, setcertificateArr, [
      { name: "", org: "", date: "" },
    ]);
    setReduxArray(achievementReduxData, setachievementArr, [
      { title: "", description: "", date: "" },
    ]);
  }, []);

  const handleSaveToRedux = () => {
    if (researchArr?.length === 1 && researchArr[0]?.title === "") {
      dispatch(setResearchPapersFields([]));
    } else if (
      researchArr?.length >= 1 &&
      researchArr[researchArr?.length - 1]?.title !== ""
    ) {
      dispatch(setResearchPapersFields(researchArr));
    }

    if (certificateArr?.length === 1 && certificateArr[0]?.name === "") {
      dispatch(setCertificateFields([]));
    } else if (
      certificateArr?.length >= 1 &&
      certificateArr[certificateArr?.length - 1]?.name !== ""
    ) {
      if (ResumeReduxData?.isResumeEdit) {
        const achievementWithoutDescriptions = achievementArr?.filter(
          (item) => item.description.length === 0
        );
        if (achievementWithoutDescriptions?.length > 0) {
          postAchievementGptData();
        }
      }
      dispatch(setCertificateFields(certificateArr));
    }

    if (achievementArr?.title === 1 && achievementArr[0]?.title === "") {
      dispatch(setAchievementsFields([]));
    } else if (
      achievementArr?.length >= 1 &&
      achievementArr[achievementArr?.length - 1]?.title !== ""
    ) {
      dispatch(setAchievementsFields(achievementArr));
    }

    dispatch(setAllLinks(linkArr));
    dispatch(setlanguagesKnown(LanguageArr));
    dispatch(setHobbyFields(hobbyArr));
    dispatch(setResumeStep(8));
    toast.success("section saved ");
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  const postAchievementGptData = async (index, achievement) => {
    // Assuming ResumeData.achievements is an array of achievements with title and date
    const achievementWithoutDescriptions = achievementArr?.filter(
      (item) => item.description.length === 0
    );
    const achievementDetails = achievementWithoutDescriptions
      ?.map((ach) => `Title: ${ach.title}, Date: ${ach.date}`)
      .join("; ");

    const function_descriptions = [
      {
        name: "format_achievement_details",
        description:
          "Format achievement into a JSON array where each object contains a 'description' field with exactly 2 bullet points.",
        parameters: {
          type: "object",
          properties: {
            achievements: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly 2 bullet points summarizing the achievement.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point highlighting a key responsibility, accomplishment, or skill demonstrated in the achievement.",
                    },
                    minItems: 2,
                    maxItems: 2,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["achievements"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each achievement listed. Each achievement should be summarized in exactly 2 bullet points, highlighting key responsibilities, accomplishments demonstrated in the role. Each bullet point must not exceed 50 words and have at least 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to an achievement and contains a 'description' field with an array of 2 bullet points.
          
          Achievement Details:
          ${achievementDetails}
          
          Please create a JSON array where each element is a JSON object for a specific achievement. Each object should contain a 'description' field with an array of 2 bullet points. Ensure the number of JSON objects matches the number of achievements provided. For example:
          
          - If there is only one achievement, the output should be a JSON array with one object.
          - If there are multiple achievements, the output should be a JSON array with multiple objects, each corresponding to an achievement.
          
          Expected JSON output format:
          [
              {
                  "description": [
                      "",
                      ""
                  ]
              }
          ]
          
          Please ensure the output strictly follows this format and each achievement is summarized in exactly 2 bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_achievement_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetAchievementAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };

  return (
    <>
      <div className="">
        <h1 className="primary-text mt-4 text-3xl font-semibold">
          Additional Info
        </h1>
        <div className="mb-5 mt-4 d-flex flex-column w-100 gap-x-14 resume-step-content">
          <Accordion defaultActiveKey="0">
            <div className="d-flex align-items-center flex-column gap-2">
              <Certificate
                certificateArr={certificateArr}
                setcertificateArr={setcertificateArr}
              />
              <Language
                LanguageArr={LanguageArr}
                setLanguageArr={setLanguageArr}
              />
              <Link linkArr={linkArr} setlinkArr={setlinkArr} />
              <ResearchPaper
                researchArr={researchArr}
                setresearchArr={setresearchArr}
              />
              <Achievement
                achievementArr={achievementArr}
                setachievementArr={setachievementArr}
              />
              <Hobby hobbyArr={hobbyArr} sethobbyArr={sethobbyArr} />
            </div>
          </Accordion>
        </div>
      </div>
      <div className="d-flex justify-content-between resume-content-footer-btn">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleSaveToRedux}
        >
          Save & Next
        </button>
      </div>
    </>
  );
}

export default Other;
