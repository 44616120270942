import React, { useEffect, useState } from "react";
import ResumeOrCoverLetter from "./Steps to resume/ResumeOrCoverLetter";
import SelectTemplate from "./Steps to resume/SelectTemplate";
import Contact from "./Steps to resume/Contact";
import StepsWrapper from "./StepsWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setBaseStep } from "helper/redux/Slice/UtilsSlice";
import { Toaster } from "react-hot-toast";
import { setSummaryFields } from "helper/redux/Slice/ResumeSlice";
import "./commoncss.css";
function CreateResume1() {
  const [Step, setStep] = useState(1);
  const baseStep = useSelector((item) => item.utils.baseStep);
  const dispatch = useDispatch();
  const handleResumeStep = (step) => {
    dispatch(setBaseStep(step));
  };
  useEffect(() => {
    dispatch(setSummaryFields(""));
  }, []);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: "#602a94",
              color: "white",
            },
          },
          error: {
            style: {
              background: "#602a94",
              color: "white",
            },
          },
        }}
      />
      {baseStep === 1 ? (
        <ResumeOrCoverLetter handleResumeStep={handleResumeStep} />
      ) : baseStep === 2 ? (
        <SelectTemplate />
      ) : baseStep === 3 ? (
        <StepsWrapper />
      ) : (
        ""
      )}
    </>
  );
}

export default CreateResume1;
