import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import CT1non from "components/CoverLetterTemplate/CT1/CT1non";
import {
  generatePDF,
  PostCoverLetterFresher,
  UpdateFresherCoverLetter,
} from "helper/api";
import CoverLetterCard from "New Ui/Common/Cards/CoverLetterCard";
import React, { useState } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { FaFileDownload } from "react-icons/fa";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";

function CoverTextField({
  CoverLetter,
  setCoverLetter,
  setcoverStep,
  setgenerateCoverLetter,
}) {
  const navigate = useNavigate();
  const [FinalCoverLetter, setFinalCoverLetter] = useState(false);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);
  const { id } = useParams();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoverLetter({ ...CoverLetter, content: value });
  };

  const postOrUpdateCoverLetter = async () => {
    try {
      setsubmitLoading(true);

      if (!!id) {
        await UpdateFresherCoverLetter(CoverLetter);
      } else {
        await PostCoverLetterFresher(CoverLetter);
      }
      setsubmitLoading(false);
      setFinalCoverLetter(true);
    } catch (error) {
      setsubmitLoading(false);
      console.log(error);
    }
  };
  const handleFinalCoverClose = () => {
    setFinalCoverLetter(false);
  };

  const exportPDFWithComponent = async () => {
    try {
      setexportLoading(true);
      const data = {
        CoverData: CoverLetter,
        Template: 1,
        doctype: "cover",
      };

      // Call the API to generate the PDF
      const response = await generatePDF(data);

      if (response.status === 200) {
        const { pdfUrl } = await response.data;
        const fullpdfurl = process.env.REACT_APP_API_URL + pdfUrl;

        const downloadLink = document.createElement("a");
        downloadLink.href = fullpdfurl;
        document.body.appendChild(downloadLink);
        downloadLink.download = "document.pdf";
        downloadLink.type = "application/pdf";
        downloadLink.target = "_blank"; // Open the link in a new tab
        downloadLink.click();
        document.body.removeChild(downloadLink);
        // document.body.removeChild(downloadLink);
        console.log("PDF downloaded successfully");
      } else {
        // Handle error
        const errorMessage = await response.text();
        console.error("Failed to generate PDF,", errorMessage);
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      setexportLoading(false);
    }
  };

  return (
    <div className="w-100 px-lg-5 px-2 d-flex gap-4 position-relative">
      <div className=" p-3  w-lg-75 w-100">
        <div className="d-flex rounded primary-border gap-3 justify-content-between mb-4 align-items-center p-2">
          <div className="d-flex justify-content-between">
            <button
              className="primary-btn p-2 rounded  align-self-start "
              onClick={() => setgenerateCoverLetter(false)}
            >
              Back
            </button>
            <button className="primary-btn p-2 rounded d-inline-block d-lg-none">
              <BsEye fontSize={18} />
            </button>
          </div>
          <div className="d-flex gap-4">
            {/* <button
              onClick={exportPDFWithComponent}
              className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
            >
              Export <FaFileDownload className="ms-2" fontSize={16} />
            </button> */}
            {!!id ? (
              <button
                className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
                onClick={postOrUpdateCoverLetter}
              >
                Update
                {submitLoading ? (
                  <Spinner size="sm" className="ms-2" />
                ) : (
                  <IoCheckmarkCircle className="ms-2" fontSize={18} />
                )}
              </button>
            ) : (
              <button
                className="primary-btn p-2 rounded align-self-end d-flex align-items-center"
                onClick={postOrUpdateCoverLetter}
              >
                Submit
                {submitLoading ? (
                  <Spinner size="sm" className="ms-2" />
                ) : (
                  <IoCheckmarkCircle className="ms-2" fontSize={18} />
                )}
              </button>
            )}
          </div>
        </div>
        <textarea
          id="message"
          onChange={handleChange}
          className="primary-border rounded h-100 w-100  p-3"
          placeholder="write your cover letter "
          rows={15}
          value={CoverLetter?.content}
        ></textarea>
      </div>
      <div className="coverLetter-card-Wrapper d-none d-lg-inline-block">
        <h6 className="text-xl font-semibold  primary-text mb-3"> Preview </h6>
        <Card className="resume-common-card rounded">
          <Card.Body style={{ padding: "7px " }}>
            <CT1editable data={CoverLetter} />
          </Card.Body>
        </Card>
      </div>

      <Modal
        show={FinalCoverLetter}
        onHide={handleFinalCoverClose}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Body className="experience_modal rounded">
          <div className="experience_modal_container">
            {/* <img src={firework} alt="crown" /> */}
            <h6 className="my-4 text-center">
              Congratulations !{" "}
              {!!id ? "Cover Letter updated" : "Your Cover Letter is ready"}
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center ">
          <button
            className="primary-btn p-2 rounded"
            onClick={() => {
              navigate("/");
              handleFinalCoverClose();
            }}
            variant="light"
          >
            Go to dashboard{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CoverTextField;
