import React, { useRef, useState } from "react";
import "./CT1.css";
import jsPDF from "jspdf";
import { pdfFromReact } from "generate-pdf-from-react-html";
import { useSelector } from "react-redux";

const CT1editable = ({ data }) => {
  const reportTemplateRef = useRef();
  const [fontSize, setfontSize] = useState(1);

  let CoverData;
  CoverData = useSelector((item) => item.cover);
  // UserData = useSelector(item => item.user)
  if (!!data) {
    CoverData = data;
  }
  const Style = {
    coverWrapper: {
      width: "100%",
      minHeight: "100%",
      containerType: "inline-size",
      padding: "5px",
      // background: "#ffffff",
      padding: `${fontSize * 1}cqw`,
    },
    coverHead: {
      fontSize: fontSize,
    },
    title: {
      fontSize: `${fontSize * 4}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      fontWeight: "600",
    },
    common: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 1}cqw`,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      textAlign: "justify",
    },
    li: {
      fontSize: `${fontSize * 3}cqw`,
      marginBottom: `${fontSize * 0}cqw`,
    },
    headings: {
      fontSize: `${fontSize * 4}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
    },
    subHeadings: {
      fontSize: `${fontSize * 3}cqw`,
      fontWeight: "700",
      marginBottom: `${fontSize * 1}cqw`,
    },
    marginHR: {
      margin: `${fontSize * 2}cqw 0`,
    },
    marginUl: {
      margin: `${fontSize * 0}cqw 0`,
    },
  };
  return (
    <>
      {/* <button onClick={() =>
        pdfFromReact(".element-to-print", "My-file", "p", true, false)
      }>click</button> */}
      <div
        style={Style.coverWrapper}
        className="container-fluid element-to-print coverLetter-1-wrapper"
        ref={reportTemplateRef}
      >
        <div className="coverLetter-1-head w-100 justify-content-center align-items-center d-flex flex-column">
          <h1 className="text-center " style={Style.title}>
            {!!CoverData?.from?.lastName
              ? CoverData?.from?.firstName + " " + CoverData?.from?.lastName
              : CoverData?.from?.firstName}
          </h1>
          {/* <h3 className='text-center'>123, Street XO, LA, USA</h3> */}
          <p style={Style.common} className="text-center">
            {CoverData?.from?.number} - {CoverData?.from?.email}
          </p>
        </div>
        <aside className="align-self-start mt-2">
          {/* <p>July 24, 2023</p> */}
          <strong style={Style.common}>{CoverData?.to?.name}</strong>
          {CoverData?.to?.position && (
            <p style={Style.common}>
              {`${
                !!CoverData?.to?.position
                  ? CoverData?.to?.position.trim() + "-"
                  : ""
              }  ${CoverData?.targetJob?.company.trim()}`}
            </p>
          )}
        </aside>

        <div className="mt-2">
          {/* <p> {!!CoverData?.to?.name && `Dear ${CoverData?.to?.name}`}  </p> */}
          <p style={Style.common}>{CoverData?.content}</p>
          {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum ducimus, iste, corrupti quidem dolorem, vel tenetur inventore qui itaque et eum vitae doloribus reiciendis nobis magni odit dignissimos enim vero.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum ducimus, iste, corrupti quidem dolorem, vel tenetur inventore qui itaque et eum vitae doloribus reiciendis nobis magni odit dignissimos enim vero.</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum ducimus, iste, corrupti quidem dolorem, vel tenetur inventore qui itaque et eum vitae doloribus reiciendis nobis magni odit dignissimos enim vero.</p> */}
        </div>

        <br />
        <div className="align-self-start">
          {/* <p>Sincerely,</p>
          <p>{CoverData?.name}</p> */}
        </div>
      </div>
    </>
  );
};

export default CT1editable;
