import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./datePicker.css";

function BasicDate({
  index,
  data,
  label,
  handleChange,
  placeholder,
  name,
  EndDateDisabled,
  required,
  minDate,
  submitAttempted,
}) {
  const [value, setValue] = useState(
    data && !isNaN(new Date(data).getTime()) ? new Date(data) : null
  );

  useEffect(() => {
    setValue(data && !isNaN(new Date(data).getTime()) ? new Date(data) : null);
  }, [data]);
  const displayValue =
    value instanceof Date && !isNaN(value.getTime())
      ? `${value.toLocaleString("default", {
          month: "short",
        })}-${value.getFullYear()}`
      : "";

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  const onMonthYearChange = (value) => {
    // setValue(value);
    handleChange(index, name, value);
    setIsCalendarOpen(false); // Close the calendar after selection
  };
  const formatShortMonth = (locale, date) => {
    return date.toLocaleString(locale, { month: "short" }).slice(0, 3); // Ensures only first 3 letters
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const isValid = !required || data !== "";

  return (
    <div className="d-flex flex-column form-input mb-3 w-100 ">
      <label htmlFor="month_year_picker" className="text-sm">
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <div className="position-relative w-100">
        <input
          ref={inputRef}
          type="text"
          className="form-input w-100"
          id="month_year_picker"
          placeholder="MM-YYYY"
          disabled={EndDateDisabled}
          onClick={() => setIsCalendarOpen(true)}
          style={{
            border:
              submitAttempted && !isValid
                ? "1.5px solid red"
                : "1.5px solid #602a94",
          }}
          value={displayValue}
          readOnly // Makes the input field readonly to avoid manual edits
        />
        {isCalendarOpen && (
          <div
            ref={calendarRef}
            style={{
              position: "absolute",
              zIndex: 1000,
              top: "-605%",
              left: "0px",
              width: "100%",
              bottom: 0,
            }}
            className="rounded"
          >
            <Calendar
              onChange={onMonthYearChange}
              value={value}
              view="year"
              onActiveStartDateChange={({ activeStartDate, view }) => {
                if (view === "year") {
                  // handleChange(activeStartDate);
                }
              }}
              onClickMonth={onMonthYearChange}
              returnValue="start"
              minDate={name === "end" ? minDate : ""}
              className="rounded"
              formatMonthYear={formatShortMonth}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BasicDate;
