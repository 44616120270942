import LoadingComponent from "common/LoadingComponent";
import {
  ChatGPTAPI,
  GetFresherCoverLetterById,
  GetTrailResume,
  PostCoverLetterFresher,
} from "helper/api";
import ResumeCardCover from "New Ui/Common/Cards/ResumeCardCover";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import MainLoader from "New Ui/Common/Loader/MainLoader";
import React, { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { CgArrowDown } from "react-icons/cg";
import { TiTick } from "react-icons/ti";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import CoverTextField from "./CoverTextField";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";

function UploadFromResume({ setcoverStep }) {
  const { id } = useParams();
  const [ResumeCardData, setResumeCardData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [selectedResume, setselectedResume] = useState();
  const [expandResume, setexpandResume] = useState(true);
  const [generateCoverLetter, setgenerateCoverLetter] = useState(false);
  const [lodingForGenerate, setlodingForGenerate] = useState(false);
  const [CoverLetter, setCoverLetter] = useState({
    template: 1,
    type: "fresher",
    to: {
      name: "",
      position: "",
    },
    targetJob: {
      position: "",
      company: "",
    },
    from: {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
    },
    experiences: [],
    projects: [],
    skills: [],
    researchPapers: [],
    achievements: [],
    content: "",
    resumeId: "",
  });
  const handleRecipientChange = (index, e) => {
    const { name, value } = e.target;
    setCoverLetter({
      ...CoverLetter,
      to: { ...CoverLetter.to, [name]: value },
    });
  };
  const handleTargetJobChange = (index, e) => {
    const { name, value } = e.target;
    setCoverLetter({
      ...CoverLetter,
      targetJob: { ...CoverLetter.targetJob, [name]: value },
    });
  };
  const getResumeData = async () => {
    setLoading(true);
    try {
      const res = await GetTrailResume();
      const { templateNos } = res.data;

      //   const matchedTemplates = ResumeTemplateJson.filter((template) =>
      //     templateNos.includes(template.templateNo)
      //   );
      setResumeCardData(res?.data?.data);

      // setFilteredTemplates();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getResumeData();
  }, []);
  useEffect(() => {
    if (!!id) {
      fetchCoverLetterById();
    }
  }, [id]);
  useEffect(() => {
    if (!!CoverLetter?.resumeId && ResumeCardData?.length > 0) {
      setselectedResume(
        ResumeCardData?.find((item) => item?._id === CoverLetter?.resumeId)
      );
    }
  }, [CoverLetter?.resumeId, ResumeCardData]);

  const handleExpandResumeSection = () => {
    setexpandResume(!expandResume);
  };

  const fetchCoverLetterById = async () => {
    try {
      const res = await GetFresherCoverLetterById(id);
      console.log(res);
      // const originalData = {...res.data.data}
      setCoverLetter({ ...res?.data?.data, coverId: res?.data?.data?._id });
    } catch (error) {}
  };

  const handleSelectedResume = (data) => {
    setselectedResume(data);
    const newFrom = {
      firstName: data?.contact?.firstName,
      lastName: data?.contact?.lastName,
      number: data?.contact?.number,
      email: data?.contact?.email,
    };
    setCoverLetter({ ...CoverLetter, from: newFrom, resumeId: data?._id });
  };
  useEffect(() => {
    if (!!id) {
      setexpandResume(false);
    }
  }, [id]);

  const handleCoverLetterResumeFields = (field) => {
    setCoverLetter((prevCoverLetter) => {
      // Immediately create a new object to modify
      let newState = { ...prevCoverLetter };

      // Check if the field already exists in the CoverLetter
      if (newState[field].length > 0) {
        // If it exists, delete the field from newState
        newState[field] = [];
      } else {
        // If it doesn't exist, add it from selectedResume
        newState[field] = selectedResume[field];
      }

      // Return the newState to be set
      return newState;
    });
  };
  console.log();
  function calculateTotalExperience(experiences) {
    let totalMonths = 0;

    experiences?.forEach((exp) => {
      const start = new Date(exp.start);
      const end = exp.end ? new Date(exp.end) : new Date(); // assuming current date if end is not defined
      let months = (end.getFullYear() - start.getFullYear()) * 12;
      months -= start.getMonth();
      months += end.getMonth();
      totalMonths += months;
    });

    if (totalMonths < 12) {
      return `${totalMonths} month${totalMonths === 1 ? "" : "s"}`;
    } else {
      const years = Math.floor(totalMonths / 12);
      const remainingMonths = totalMonths % 12;
      return `${years} year${
        years === 1 ? "" : "s"
      } and ${remainingMonths} month${remainingMonths === 1 ? "" : "s"}`;
    }
  }
  const totalExperience = calculateTotalExperience(CoverLetter?.experiences);

  let ExperienceForPrompt = "";
  let SkillsForPrompt = "";
  let ProjectForPrompt = "";
  let ResearchForPrompt = "";
  let AchievementForPrompt = "";
  CoverLetter?.skills?.map((item) => (SkillsForPrompt += item + ", "));
  CoverLetter?.experiences?.map(
    (item) =>
      (ExperienceForPrompt +=
        item?.jobtype + ", " + item?.role + ", " + item?.company + ";")
  );

  CoverLetter?.projects?.map(
    (item) => (ProjectForPrompt += item?.title + ", " + item?.role + ", " + ";")
  );

  CoverLetter?.researchPapers?.map(
    (item) => (ResearchForPrompt += item?.title + ", " + item?.author + ";")
  );
  CoverLetter?.achievements?.map(
    (item) =>
      (AchievementForPrompt += item?.title + ", " + item?.description + ";")
  );
  const handleGenerateCoverLetter = async () => {
    setgenerateCoverLetter(false);
    setlodingForGenerate(true);

    let categoriesIncluded = [];
    if (CoverLetter?.skills?.length > 0) categoriesIncluded.push("skills");
    if (CoverLetter?.experiences?.length > 0)
      categoriesIncluded.push("experience");
    if (CoverLetter?.projects?.length > 0) categoriesIncluded.push("projects");
    if (CoverLetter?.researchPapers?.length > 0)
      categoriesIncluded.push("research papers");
    if (CoverLetter?.achievements?.length > 0)
      categoriesIncluded.push("achievements");

    // Prepare details for prompt
    const skillsText =
      CoverLetter?.skills?.length > 0
        ? `Skills: ${CoverLetter.skills.join(", ")}`
        : "";
    const experiencesText =
      CoverLetter?.experiences?.length > 0
        ? `Experiences: ${CoverLetter.experiences.join(", ")}`
        : "";
    const projectsText =
      CoverLetter?.projects?.length > 0
        ? `Projects: ${CoverLetter.projects.join(", ")}`
        : "";
    const researchPapersText =
      CoverLetter?.researchPapers?.length > 0
        ? `Research Papers: ${CoverLetter.researchPapers.join(", ")}`
        : "";
    const achievementsText =
      CoverLetter?.achievements?.length > 0
        ? `Achievements: ${CoverLetter.achievements.join(", ")}`
        : "";

    const recipientName = CoverLetter?.to?.name || "Hiring Manager";
    const totalExperience = CoverLetter?.totalExperience || "not specified";
    const fullName =
      CoverLetter?.from?.firstName +
      (CoverLetter?.from?.lastName ? " " + CoverLetter?.from?.lastName : "");

    const prompt = `I am writing to express my interest in the ${
      CoverLetter?.targetJob?.position
    } position at ${
      CoverLetter?.targetJob?.company
    }, as highlighted by my ${categoriesIncluded.join(
      ", "
    )}. Below are the specifics of my qualifications:
    
    ${skillsText}
    ${experiencesText}
    ${projectsText}
    ${researchPapersText}
    ${achievementsText}
  
    Recipient Name: ${recipientName}
    Total Experience: ${totalExperience}
  
    Please craft exactly two paragraphs that:
    1. Introduce me, reflecting my enthusiasm for the role and highlighting my relevant qualifications (${
      categoriesIncluded.join(", ") || "qualifications"
    }).
    2. Conclude with a strong call to action.
  
    Replace 'Dear Hiring Manager' with '${recipientName}' and replace '[Your Name]' with '${fullName}'.
  
    The tone should be professional and confident, tailored specifically to the job I am applying for.`;

    const payload = {
      question: prompt,
      temperature: 0.5,
    };

    try {
      const res = await ChatGPTAPI(payload);
      console.log(res);

      setCoverLetter({ ...CoverLetter, content: res.data });
      setgenerateCoverLetter(true);
      setlodingForGenerate(false);
    } catch (error) {
      setlodingForGenerate(false);
      setgenerateCoverLetter(false);
      console.error(error);
    }
  };

  return (
    <div className="w-100 d-flex justify-content-center py-5">
      {Loading ? (
        <MainLoader text="Generating AI Cover Letter" />
      ) : generateCoverLetter ? (
        <CoverTextField
          CoverLetter={CoverLetter}
          setCoverLetter={setCoverLetter}
          setgenerateCoverLetter={setgenerateCoverLetter}
        />
      ) : (
        <div className="w-75">
          <h3 className="text-2xl mb-3 primary-text font-semibold">
            Recipient Details
          </h3>

          <div className="d-flex flex-lg-row flex-column gap-3 gap-lg-5 mb-4">
            <BasicInput
              name="name"
              label="Recipient Name"
              type="text"
              placeholder="Recipient Name"
              required={true}
              handleChange={handleRecipientChange}
              value={CoverLetter?.to?.name}
            />
            <BasicInput
              name="position"
              label="Recipient Designation"
              type="text"
              placeholder="Eg  Viosa Learning"
              handleChange={handleRecipientChange}
              value={CoverLetter?.to?.position}
            />
          </div>
          <h3 className="text-2xl mb-3 primary-text font-semibold">
            Target Job
          </h3>
          <div className="d-flex mb-4  flex-lg-row flex-column gap-3 gap-lg-5">
            <BasicInput
              name="company"
              label="Company Name"
              type="text"
              required={true}
              placeholder="Eg  Viosa Learning"
              handleChange={handleTargetJobChange}
              value={CoverLetter?.targetJob?.company}
            />
            <BasicInput
              name="position"
              label="Job Title"
              required={true}
              type="text"
              placeholder="Eg  Software Developer"
              handleChange={handleTargetJobChange}
              value={CoverLetter?.targetJob?.position}
            />
          </div>
          <div className=" d-flex justify-content-between">
            <h3 className="text-2xl primary-text font-semibold">
              {!!selectedResume ? (
                <span className="d-flex align-items-center">
                  Resume Selected{" "}
                  <TiTick fontSize={34} className="ms-1 primary-icon" />
                </span>
              ) : (
                "Select Resume"
              )}
            </h3>
            <span
              className="text-sm font-semibold cursor-pointer primary-text"
              onClick={handleExpandResumeSection}
            >
              View Resume
              {expandResume ? (
                <IoIosArrowUp fontSize={24} className="ms-2" />
              ) : (
                <IoIosArrowDown fontSize={24} className="ms-2" />
              )}
            </span>
          </div>
          <div className="d-flex justify-content-end "></div>
          {expandResume && (
            <div className="d-flex justify-between gap-5 flex-wrap mt-5">
              {ResumeCardData?.length !== 0
                ? ResumeCardData?.map((data, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleSelectedResume(data);
                        setexpandResume(false);
                      }}
                      className={`rounded  ${
                        data?._id === selectedResume?._id
                          ? "resume-card-responsive-selected"
                          : ""
                      } position-relative resume-card-responsive`}
                    >
                      {data?._id === selectedResume?._id ? (
                        <span className=" resume-card-responsive-selected-icon">
                          <TiTick fontSize={26} />
                        </span>
                      ) : (
                        ""
                      )}
                      <ResumeCardCover
                        data={data}
                        //   handleDeleteResume={handleDeleteResume}
                      />
                    </div>
                  ))
                : "No Resume Present"}
            </div>
          )}
          {/* inClude in your cover letter */}
          {!!selectedResume && (
            <>
              <h3 className="text-2xl primary-text font-semibold mt-4">
                Include in your Resume{" "}
              </h3>
              <div className="mt-4 d-flex gap-lg-4 gap-3 flex-wrap">
                {[
                  "experiences",
                  "projects",
                  "skills",
                  "achievements",
                  "researchPapers",
                ].map(
                  (field) =>
                    selectedResume?.[field]?.length > 0 && (
                      <span
                        key={field}
                        className={`px-3 p-2 ${
                          CoverLetter[field]?.length > 0 &&
                          "selected-for-coverLetter"
                        } primary-border rounded cursor-pointer`}
                        onClick={() => handleCoverLetterResumeFields(field)}
                      >
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </span>
                    )
                )}
              </div>
            </>
          )}
          <div className="mt-5 d-flex justify-content-end gap-4">
            <button
              className="primary-btn px-3 p-2 rounded"
              onClick={handleGenerateCoverLetter}
              // onClick={() => setgenerateCoverLetter(true)}
            >
              {!!CoverLetter?.content ? "Regenerate" : "Generate"}

              {lodingForGenerate ? <Spinner size="sm" className="ms-2" /> : ""}
            </button>
            {!!CoverLetter?.content && (
              <button
                className="primary-btn px-3 p-2 rounded"
                onClick={() => setgenerateCoverLetter(true)}
              >
                View Cover Letter
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadFromResume;
