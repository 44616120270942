import { setResearchPapersFields } from "helper/redux/Slice/ResumeSlice";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import BasicTextArea from "New Ui/Common/Input Fields/BasicTextArea";
import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

function ResearchPaper({ researchArr, setresearchArr }) {
  const dispatch = useDispatch();
  const researchReduxData = useSelector((item) => item?.resume?.researchPapers);
  const [submitAttempted, setsubmitAttempted] = useState(false);

  // const handelChange = (e) => {
  //   const { name, value } = e.target;

  //   setcertificateObj({ ...certificateObj, [name]: value });
  // };
  const handleAddResearch = () => {
    setsubmitAttempted(true);
    const lastResearch = researchArr[researchArr.length - 1];
    const errors = validateResearch(lastResearch);
    if (Object.keys(errors).length === 0) {
      const newResearch = {
        title: "",
        author: "",
        date: "",
        url: "",
      };
      setresearchArr([...researchArr, newResearch]);
      dispatch(setResearchPapersFields(researchArr));
      setsubmitAttempted(false);
      toast.success("Research Paper added ");
    } else {
      toast.error("Please fill Title");
    }
  };
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...researchArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setresearchArr(newArr);
  };

  const handleRemoveResearch = (index) => {
    const newArr = [...researchArr];
    newArr.splice(index, 1);
    setresearchArr(newArr);
    if (index === 0) {
      setresearchArr([
        {
          title: "",
          author: "",
          date: "",
          url: "",
        },
      ]);
    }
  };
  const handleDateChange = (index, name, value) => {
    const newArr = [...researchArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setresearchArr(newArr);
  };

  const validateResearch = (research) => {
    const errors = {};
    if (!research.title) {
      errors.title = "Title is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  return (
    <>
      <Accordion.Item
        eventKey={4}
        className="mb-2  rounded w-100"
        style={{ border: "1px solid #602a94" }}
      >
        <Accordion.Header>
          <div className="d-flex justify-content-between w-100 pe-4">
            <span> Research Paper</span>
            <div></div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ visibility: "visible" }}>
          <div>
            {researchArr?.map((item, index) => {
              return (
                <>
                  <div className="d-flex mb-4">
                    <div
                      className="primary-border w-100 px-4 py-3 rounded"
                      style={{ borderWidth: "1px" }}
                    >
                      <div className="d-flex gap-5">
                        <BasicInput
                          name="title"
                          label="Title"
                          handleChange={handleChange}
                          index={index}
                          value={item?.title}
                          placeholder="Write title"
                          required={true}
                          submitAttempted={submitAttempted}
                        />
                        {/* <BasicInput
                          name="company"
                          label="Company Name (Optional)"
                          //   handleChange={handelChange}
                          value={item?.company}
                          placeholder=""
                        /> */}
                      </div>
                      <div className="d-flex gap-5">
                        <BasicInput
                          name="url"
                          label="URL"
                          handleChange={handleChange}
                          value={item?.url}
                          index={index}
                          placeholder="Write URL"
                        />
                      </div>
                      <div className="flex-row-responsive">
                        <BasicInput
                          name="author"
                          index={index}
                          handleChange={handleChange}
                          label="Author(s)"
                          value={item?.author}
                          placeholder="Author name"
                        />
                        <BasicDate
                          name="date"
                          label="Publication date"
                          handleChange={handleDateChange}
                          data={item?.date}
                          index={index}
                          placeholder=""
                        />
                        {/* <BasicDate
                  name="company"
                  label="Company Name"
                  value={ExperienceObj?.company}
                  placeholder="Eg Viosa Learning"
                />
                <BasicDate
                  name="name"
                  label="Full Name"
                  //   handleChange={handelChange}
                  value={ExperienceObj?.name}
                  placeholder="John Doe"
                /> */}
                      </div>
                    </div>
                    <RiDeleteBin5Line
                      onClick={() => {
                        handleShowDelete();
                        setdeleteIndex(index);
                      }}
                      fontSize={30}
                      className="secondary-text align-self-start  ms-3 cursor-pointer"
                    />
                  </div>
                </>
              );
            })}
          </div>

          <div className="d-flex justify-content-end pe-5 ">
            <button
              className="primary-btn rounded px-3 p-2 mt-1 d-flex gap-1 justify-content-center align-items-center"
              onClick={handleAddResearch}
            >
              <IoIosAdd fontSize={24} />
              Research Paper
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        centered
        className="rounded"
      >
        <Modal.Header style={{ background: "#f0ddff" }} closeButton>
          <h4 className="text-lg">Are you sure?</h4>
        </Modal.Header>
        <Modal.Body>Do you want to delete this Research Paper?</Modal.Body>
        <Modal.Footer>
          <button
            className="primary-btn px-3 p-2 rounded"
            onClick={handleCloseDelete}
          >
            Cancel
          </button>
          <button
            className="primary-btn  px-3 p-2 rounded"
            onClick={() => {
              toast.error("Research Paper Deleted !");
              handleRemoveResearch(deleteIndex);
              handleCloseDelete();
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ResearchPaper;
