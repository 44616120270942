import {
  setIsResumeEdit,
  setJobSpecificToggleRedux,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import BasicTextArea from "New Ui/Common/Input Fields/BasicTextArea";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
const JobSpecific = () => {
  const [jobSpecificToggle, setjobSpecificToggle] = useState(false);
  const [jobDescription, setjobDescription] = useState("");
  const dispatch = useDispatch();
  const handleChange = () => {
    setjobSpecificToggle(!jobSpecificToggle);
  };
  const handleChecked = () => {};
  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const ResumeReduxData = useSelector((item) => item.resume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  useEffect(() => {
    setjobSpecificToggle(ResumeReduxData?.jobSpecificToggle);
    setjobDescription(ResumeReduxData?.jobDescription);
  }, []);
  const handleSubmitAndNext = () => {
    // Job-Specific Flow
    if (jobSpecificToggle) {
      if (jobDescription === "") {
        toast.error("Please fill Job description");
        return; // Stop execution if job description is empty
      } else {
        // Save job-specific data and proceed to step 8
        dispatch(
          setJobSpecificToggleRedux({
            jobSpecificToggle,
            jobDescription,
          })
        );
        toast.success("Job description Saved");
      }
    }

    // Edit Flow
    if (ResumeReduxData?.isResumeEdit) {
      dispatch(setResumeStep(9)); // Go to step 9 for edit flow
      dispatch(setIsResumeEdit(false)); // Reset the edit state
      return; // Stop further execution
    }

    // Normal Flow (Generic Resume)
    dispatch(setResumeStep(9)); // Go to step 8 for normal flow
  };
  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target;
    console.log(value);
    setjobDescription(value);
  };

  return (
    <div className="">
      <h1 className="primary-text text-3xl font-semibold step-content-header mt-4">
        Job Specific
      </h1>
      <div className="mt-4 d-flex  flex-column  w-100 gap-x-14  resume-step-content">
        <div className="d-flex gap-3 align-items-center">
          <h2>Generic</h2>
          <Switch
            onChange={handleChange}
            onColor="#888888"
            checked={jobSpecificToggle}
            onHandleColor="#68349a"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />

          <h2>Job Specific</h2>
        </div>
        <div className="mt-4">
          {jobSpecificToggle && (
            <BasicTextArea
              label="job Description"
              rows={"12"}
              value={jobDescription}
              handleChange={handleDescriptionChange}
              placeholder="write your Job Description here"
            />
          )}
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-between resume-content-footer-btn pe-0">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>

        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          {!jobSpecificToggle ? "Skip & Review" : "Save & Review"}
        </button>
      </div>
    </div>
  );
};

export default JobSpecific;
