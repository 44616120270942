import ExperienceAccordian from "New Ui/User Journey/Steps to resume/Experience/ExperienceAccordian";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import UserSidebar from "New Ui/Common/UserSidebar";
import React, { useState, useEffect } from "react";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoIosAdd, IoIosArrowBack } from "react-icons/io";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setCoverExperienceFields } from "helper/redux/Slice/CoverSlice";
import {
  GetExperienceAiResponseData,
  setExperienceFields,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";

function Experience() {
  const dispatch = useDispatch();
  const ExperienceReduxData = useSelector((item) => item?.resume?.experiences);
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [activeIndex, setActiveIndex] = useState(0);
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const [ExperienceArr, setExperienceArr] = useState([
    {
      jobtype: "",
      role: "",
      company: "",
      location: "",
      start: "",
      end: "",
      isWorking: false,
      totalexperience: "",
      description: [],
    },
  ]);
  // console.log(ExperienceArr[0].start - ExperienceArr[0].end);
  useEffect(() => {
    if (ExperienceReduxData?.length > 0) {
      setExperienceArr(ExperienceReduxData);
    } else {
      setExperienceArr([
        {
          jobtype: "",
          role: "",
          company: "",
          location: "",
          start: "",
          end: "",
          isWorking: false,
          totalexperience: "",
          description: [],
        },
      ]);
    }
  }, []);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...ExperienceArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setExperienceArr(newArr);
  };

  const validateExperience = (experience) => {
    const errors = {};
    if (!experience.role) {
      errors.role = "Role is required";
    }
    if (!experience.company) {
      errors.company = "Company name is required";
    }
    if (!experience.start) {
      errors.start = "Start date is required";
    }
    if (!experience.jobtype) {
      errors.jobtype = "jobtype date is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };

  const handleAddExperience = (e) => {
    // for (let i = 0; i < ExperienceArr?.length; i++) {
    e.preventDefault();
    setsubmitAttempted(true);
    const lastExperience = ExperienceArr[ExperienceArr.length - 1];
    const errors = validateExperience(lastExperience);
    if (Object.keys(errors).length === 0) {
      const newExperience = {
        jobtype: "",
        role: "",
        company: "",
        location: "",
        start: "",
        end: "",
        isWorking: false,
        totalexperience: "",
        description: [],
      };
      setActiveIndex(ExperienceArr.length);
      setExperienceArr([...ExperienceArr, newExperience]);
      dispatch(setExperienceFields(ExperienceArr));
      toast.success("Experience added", {
        duration: 5000, // Display the toast for 4 seconds
      });
      setsubmitAttempted(false);
    } else {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
    }
  };

  const handleRemoveExperience = (index) => {
    const newArr = [...ExperienceArr];
    newArr.splice(index, 1);
    setExperienceArr(newArr);
    if (index === 0) {
      setExperienceArr([
        {
          jobtype: "",
          role: "",
          company: "",
          location: "",
          start: "",
          end: "",
          isWorking: false,
          totalexperience: "",
          description: [],
        },
      ]);
    }
  };
  const handleSubmitAndNext = () => {
    setsubmitAttempted(true);
    const lastExperience = ExperienceArr[ExperienceArr.length - 1];
    const errors = validateExperience(lastExperience);
    if (
      ExperienceArr.length == 1 &&
      ExperienceArr[0]?.role === "" &&
      ExperienceArr[0]?.company === "" &&
      ExperienceArr[0]?.start === "" &&
      ExperienceArr[0]?.jobtype === ""
    ) {
      toast.success("Experience Skipped", {
        duration: 5000, // Display the toast for 4 seconds
      });
      dispatch(setResumeStep(3)); // Update the step even if skipped
      dispatch(setExperienceFields([]));
    } else if (ExperienceArr.length >= 1 && Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
      // Do not update the resume step because there are errors
    } else if (Object.keys(errors).length === 0) {
      dispatch(setExperienceFields(ExperienceArr));
      toast.success("Experience Saved", {
        duration: 5000, // Display the toast for 4 seconds
      });
      if (ResumeReduxData?.isResumeEdit) {
        const experiencesWithoutDescriptions = ExperienceArr?.filter(
          (item) => item.description.length === 0
        );
        if (experiencesWithoutDescriptions?.length > 0) {
          postExperienceGptData();
        }
        dispatch(setResumeStep(9));
        dispatch(setIsResumeEdit(false));
      } else {
        dispatch(setResumeStep(3)); // Update the step after successful save
      }
    }
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  // for getting all pointers in one go

  const postExperienceGptData = async (index, experience) => {
    const experiencesWithoutDescriptions = ExperienceArr?.filter(
      (item) => item.description.length === 0
    );
    const experienceDetails = experiencesWithoutDescriptions
      ?.map(
        (exp) =>
          `Role: ${exp.role}, Company: ${exp.company}, Job Type: ${exp.jobType
          }, Location: ${exp.location}, Start: ${exp.start}, End: ${exp.end || "Present"
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_experience_details",
        description:
          "Format professional experiences into a JSON array where each object contains a 'description' field with exactly five bullet points.",
        parameters: {
          type: "object",
          properties: {
            experiences: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly three bullet points summarizing the experience.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point  highlighting a key responsibility, accomplishment, or skill demonstrated in the role.",
                    },
                    minItems: 5,
                    maxItems: 5,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["experiences"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each professional experience listed. Each experience should be summarized in exactly five bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must not exceed 50 words and have atleast 30 words. Please format the responses as RFC8259 compliant JSON objects where each object corresponds to an experience and contains a 'description' field with an array of five bullet points.

    Experience Details:
    ${experienceDetails}

    Please create a JSON array where each element is a JSON object for a specific experience. Each object should contain a 'description' field with an array of five bullet points. Ensure the number of JSON objects matches the number of experiences provided. For example:

    - If there is only one experience, the output should be a JSON array with one object.
    - If there are multiple experiences, the output should be a JSON array with multiple objects, each corresponding to an experience.

    Expected JSON output format:
    [
      {
        "description": [
          "",
          "",
          "",
          "",
          ""
        ]
      }
    ]

    Please ensure the output strictly follows this format and each experience is summarized in exactly five bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_experience_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetExperienceAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };

  return (
    <>
      <div className="">
        <h1 className="primary-text text-3xl font-semibold step-content-header mt-4">
          Add Experience
        </h1>
        <form
          action=""
          className="mt-4 d-flex  flex-column  w-100 gap-x-14  resume-step-content"
        >
          <ExperienceAccordian
            ExperienceArr={ExperienceArr}
            setExperienceArr={setExperienceArr}
            submitAttempted={submitAttempted}
            handleChange={handleChange}
            // activeIndex={activeIndex}
            // setActiveIndex={setActiveIndex}
            handleRemoveExperience={handleRemoveExperience}
          />
        </form>
      </div>

      <div className="mt-3 d-flex align-items-center justify-content-between resume-content-footer-btn pe-lg-5 pe-0 ">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>

        <div className="d-flex justify-content-end">
          <button
            className="secondary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center"
            onClick={handleAddExperience}
          >
            <IoIosAdd fontSize={24} />
            Experience
          </button>
        </div>
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          {ExperienceArr.length == 1 &&
            ExperienceArr[0]?.role === "" &&
            ExperienceArr[0]?.company === "" &&
            ExperienceArr[0]?.start === "" &&
            ExperienceArr[0]?.jobtype === ""
            ? "Skip & Next"
            : "Save & Next"}
        </button>
      </div>
    </>
  );
}

export default Experience;
