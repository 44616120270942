import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import ActivityAccordian from "./ActivityAccordian";
import toast from "react-hot-toast";
import {
  GetActivityAiResponseData,
  setInvolvementFields,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import { IoIosAdd } from "react-icons/io";

function Activity() {
  const dispatch = useDispatch();
  const ActivityReduxData = useSelector((state) => state.resume.involvement); // Adjust path according to your Redux state structure
  const ResumeReduxData = useSelector((state) => state.resume); // Adjust path according to your Redux state structure
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const [ActivityArr, setActivityArr] = useState([
    {
      origin: "",
      role: "",
      company: "",
      location: "",
      start: "",
      end: "",
      isWorking: false,
      description: "",
    },
  ]);
  useEffect(() => {
    if (ActivityReduxData?.length > 0) {
      setActivityArr(ActivityReduxData);
    } else {
      setActivityArr([
        {
          origin: "",
          role: "",
          company: "",
          location: "",
          start: "",
          end: "",
          isWorking: false,
          description: "",
        },
      ]);
    }
  }, [ActivityReduxData]);
  console.log("redux", ActivityReduxData);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...ActivityArr];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setActivityArr(newArr);
  };

  const validateActivity = (activity) => {
    const errors = {};
    if (!activity.origin) errors.origin = "Origin is required";
    if (!activity.role) errors.role = "role name is required";
    return errors;
  };

  const handleAddActivity = () => {
    setsubmitAttempted(true);

    const lastProject = ActivityArr[ActivityArr.length - 1];
    const errors = validateActivity(lastProject);

    if (Object.keys(errors).length === 0) {
      const newActivity = {
        origin: "",
        role: "",
        company: "",
        location: "",
        start: "",
        end: "",
        isWorking: false,
        description: "",
      };
      setActivityArr([...ActivityArr, newActivity]);
      dispatch(setInvolvementFields(ActivityArr));
      toast.success("activity added !", {
        duration: 5000, // Display the toast for 4 seconds
      });
      setsubmitAttempted(false);
    } else {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
    }
  };

  const handleRemoveActivity = (index) => {
    const newArr = [...ActivityArr];
    newArr.splice(index, 1);
    setActivityArr(newArr);
    if (index === 0) {
      setActivityArr([
        {
          origin: "",
          role: "",
          company: "",
          location: "",
          start: "",
          end: "",
          isWorking: false,
          description: "",
        },
      ]);
    }
  };
  console.log(ActivityArr);

  const handleSubmitAndNext = () => {
    const lastActivity = ActivityArr[ActivityArr.length - 1];
    const errors = validateActivity(lastActivity);
    setsubmitAttempted(true);

    if (
      ActivityArr?.length === 1 &&
      ActivityArr[0]?.origin === "" &&
      ActivityArr[0]?.role === ""
    ) {
      toast.success("Activity Skipped", {
        duration: 5000, // Display the toast for 4 seconds
      });
      dispatch(setResumeStep(6)); // Update the step even if skipped
      dispatch(setInvolvementFields([]));
    } else if (ActivityArr.length >= 1 && Object.keys(errors).length > 0) {
      toast.error("Please fill in all required fields!", {
        duration: 5000, // Display the toast for 4 seconds
      });
      // Do not update the resume step because there are errors
    } else if (Object.keys(errors).length === 0) {
      dispatch(setInvolvementFields(ActivityArr));
      toast.success("Activity Saved ", {
        duration: 5000, // Display the toast for 4 seconds
      });
      if (ResumeReduxData?.isResumeEdit) {
        const activityWithoutDescriptions = ActivityArr?.filter(
          (item) => item.description.length === 0
        );
        if (activityWithoutDescriptions?.length > 0) {
          postActivityGptData();
        }
        dispatch(setResumeStep(9));
        dispatch(setIsResumeEdit(false));
      } else {
        dispatch(setResumeStep(6)); // Update the step after successful save
      }
    }
  };

  // back button
  const stepResume = useSelector((item) => item.utils.stepResume);
  const handleBackBtn = () => {
    dispatch(setResumeStep(stepResume - 1));
  };

  ///

  const postActivityGptData = async (index, activity) => {
    const activityWithoutDescriptions = ActivityArr?.filter(
      (item) => item.description.length === 0
    );
    const activityDetails = activityWithoutDescriptions
      ?.map(
        (act) =>
          `Activity Type: ${act?.origin}, Role: ${act?.role}${act?.company ? `, Company: ${act?.company}` : ""
          }`
      )
      .join("; ");

    const function_descriptions = [
      {
        name: "format_activity_details",
        description:
          "Format activity section into a JSON array where each object contains a 'description' field with exactly 3 bullet points.",
        parameters: {
          type: "object",
          properties: {
            involvement: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  description: {
                    type: "array",
                    description:
                      "An array of exactly 3 bullet points summarizing the activity.",
                    items: {
                      type: "string",
                      description:
                        "A concise bullet point highlighting a key responsibility, accomplishment, or skill demonstrated in the role.",
                    },
                    minItems: 3,
                    maxItems: 3,
                  },
                },
                required: ["description"],
              },
            },
          },
          required: ["activities"],
        },
      },
    ];

    const payload = {
      question: `I need to generate concise bullet points for each activity listed. Each activity should be summarized in exactly 3 bullet points, highlighting key responsibilities, accomplishments, or skills demonstrated in the role. Each bullet point must be between 30-50 words. Please format the responses as RFC8259-compliant JSON objects, where each object corresponds to an activity and contains a 'description' field with an array of 3 bullet points.
  
          Activity Details:
          ${activityDetails}
  
          Please create a JSON array where each element is a JSON object for a specific activity. Each object should contain a 'description' field with an array of 3 bullet points. Ensure the number of JSON objects matches the number of activities provided. 
  
          Expected JSON output format:
          [
              {
                  "description": [
                      "Bullet point 1",
                      "Bullet point 2",
                      "Bullet point 3"
                  ]
              }
          ]
  
          Please ensure the output strictly follows this format, and each activity is summarized in exactly 3 bullet points.`,
      function: function_descriptions,
      temperature: 0.3,
      max_tokens: 1500,
      functionName: "format_activity_details",
    };

    try {
      // Dispatch the result to your state management (e.g., Redux)
      dispatch(GetActivityAiResponseData(payload));
    } catch (error) {
      console.error("Error fetching GPT data:", error);
    }
  };
  return (
    <>
      <div className="">
        <h1 className="primary-text text-3xl font-semibold step-content-header mt-4">
          Add Activity
        </h1>
        <form className="mt-4 d-flex flex-column w-100 gap-x-14 resume-step-content">
          <ActivityAccordian
            ActivityArr={ActivityArr}
            setActivityArr={setActivityArr}
            handleChange={handleChange}
            handleRemoveActivity={handleRemoveActivity}
            submitAttempted={submitAttempted}
          />
        </form>
      </div>
      <div className="d-flex mt-3 justify-content-between resume-content-footer-btn pe-lg-5 pe-0">
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={handleBackBtn}
        >
          Back
        </button>
        <button
          className="secondary-btn rounded px-3 p-2 d-flex gap-1 justify-content-center align-items-center"
          onClick={handleAddActivity}
        >
          <IoIosAdd fontSize={24} />
          Activity
        </button>
        <button
          className="primary-btn rounded px-3 p-2"
          onClick={() => handleSubmitAndNext()}
        >
          {" "}
          {ActivityArr?.length === 1 &&
            ActivityArr[0]?.origin === "" &&
            ActivityArr[0]?.role === ""
            ? "Skip & Next"
            : "Save & Next"}
        </button>
      </div>
    </>
  );
}

export default Activity;
