const SecondaryData = ["10th", "12th"];
const BachelorsData = [
  "Bachelor of Accountancy (BAcc)",
  "Bachelor of Architecture (BArch)",
  "Bachelor of Business Administration (BBA)",
  "Bachelor of Commerce (BComm)",
  "Bachelor of Civil Law (BCL)",
  "Bachelor of Divinity (BDiv)",
  "Bachelor of Economics (BEc)",
  "Bachelor of Education (BEd)",
  "Bachelor of Engineering (BEng)",
  "Bachelor of Fine Arts (BFA)",
  "Bachelor of Laws (LLB)",
  "Bachelor of Letters (BLitt)",
  "Bachelor of Music (BM)",
  "Bachelor of Arts (BA)",
  "Bachelor of Pharmacy (BPharm)",
  "Bachelor of Philosophy (BPhil)",
  "Bachelor of Science in Nursing (BSN)",
  "Bachelor of Social Work (BSW)",
  "Bachelor of Technology (BTech)",
  "Bachelor of Theology (BTh)",
  "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
  "Bachelor of Computer Application (BCA) ",
  "Bachelor in Agriculture ",
  "Bachelor of Environmental Science (BES)",
  "Bachelor of Public Health (BPH)",
  "Bachelor of Information Technology (BIT)",
  "Bachelor of Design (BDes)",
  "Bachelor of Communication (BComm)",
  "Bachelor of Veterinary Science (BVSc)",
  "Bachelor of Sports Management (BSM)",
  "Bachelor of Applied Science (BASc)",
  "Bachelor of International Relations (BIR)",
  "Bachelor of Media Studies (BMS)",
  "Bachelor of Health Science (BHSc)",
  "Bachelor of Film and Television Production (BFTP)",
  "Bachelor of Journalism and Mass Communication (BJMC)",
  "Bachelor of Hotel Management (BHM)",
  "Bachelor of Tourism Studies (BTS)",
  "Bachelor of Arts in Psychology (BA Psych)",
  "Bachelor of Science in Biotechnology (BSc Biotech)",
  "Bachelor of Fashion Technology (BFT)",
  "Bachelor of Urban Planning (BUP)",
  "Bachelor of Science in Information Technology (BSc IT)",
  "Bachelor of Science in Computer Science (BSc CS)",
  "Bachelor of Science in Software Engineering (BSc SE)",
  "Bachelor of Science in Information Systems (BSc IS)",
  "Bachelor of Science in Artificial Intelligence (BSc AI)",
  "Bachelor of Science in Data Science (BSc DS)",
  "Bachelor of Science in Cybersecurity (BSc Cyber)",
  "Bachelor of Science in Networking (BSc Networking)",
  "Bachelor of Science in Cloud Computing (BSc Cloud)",
  "Bachelor of Science in Multimedia and Animation (BSc Multimedia)",
  "Bachelor of Science in Robotics (BSc Robotics)",
  "Bachelor of Science in Game Development (BSc GD)",
];
const MastersData = [
  "Master of Business Administration (MBA)",
  "Master of Counselling (MCouns)",
  "Master of Divinity (MDiv)",
  "Master of Education (MEd)",
  "Master of Engineering (MEng)",
  "Master of Fine Arts (MFA)",
  "Master of Laws (LLM)",
  "Master of Letters (MLitt)",
  "Master of Medicine (MMed)",
  "Master of Philosophy (MPhil)",
  "Master of Public Administration (MPA)",
  "Master of Public Health (MPH)",
  "Master of Research (MRes)",
  "Master of Sacred Theology (STM)",
  "Master of Science in Nursing (MSN)",
  "Master of Social Work (MSW)",
  "Master of Studies (MSt)",
  "Master of Surgery (ChM or MS)",
  "Professional Science Masters (PSM)",
  "Master of Information Technology (MIT)",
  "Master of Computer Science (MCS)",
  "Master of Science in Information Technology (MSc IT)",
  "Master of Science in Computer Science (MSCS)",
  "Master of Science in Data Science (MSDS)",
  "Master of Science in Artificial Intelligence (MSAI)",
  "Master of Science in Cybersecurity (MS Cybersecurity)",
  "Master of Science in Software Engineering (MSc SE)",
  "Master of Science in Mechanical Engineering (MSc ME)",
  "Master of Science in Electrical Engineering (MSc EE)",
  "Master of Science in Civil Engineering (MSc CE)",
  "Master of Science in Chemical Engineering (MSc ChE)",
  "Master of Architecture (MArch)",
  "Master of Public Policy (MPP)",
  "Master of Health Administration (MHA)",
  "Master of Science in Finance (MSc Finance)",
  "Master of Arts in Teaching (MAT)",
  "Master of Urban Planning (MUP)",
  "Master of Social Sciences (MSS)",
  "Master of International Business (MIB)",
  "Master of Management (MM)",
  "Master of Marketing (MMktg)",
  "Master of Marketing (MMktg)",
  "Master of Computer Application (MCA)",
];

export { SecondaryData, BachelorsData, MastersData };
