import { Form, FloatingLabel, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import leftArrow from "assets/image/left.png";
import rightArrow from "assets/image/right.png";
import { AiOutlineRight } from "react-icons/ai";
import { AiButton } from "../AiButton/AiButton";
import { ChatGPTAPI, CutAiCredits } from "helper/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreateResume,
  setSkillChatGpt,
  setSkillFields,
} from "helper/redux/Slice/ResumeSlice";
import { setAiCredits, setdisableNext } from "helper/redux/Slice/UtilsSlice";
import { setCoverSkillFields } from "helper/redux/Slice/CoverSlice";
import { toast } from "react-hot-toast";
import GptModal from "./GptModal";

export const Skills = ({ handleResponsivePreviewShow, SaveAndNext }) => {
  const [activeSkillDropDown, setActiveSkillDropDown] = useState("x");
  const [activeDifficultyDropDown, setActiveDifficultyDropDown] =
    useState(false);
  const dispatch = useDispatch();
  const selectedStyle = {
    backgroundColor: "#b69df8",
    color: "white",
    border: "none",
  };

  const SkillData = useSelector((item) => item?.resume?.skills);
  const ResumeData = useSelector((item) => item?.resume);
  const [SkillList, setSkillList] = useState([]);
  const [EnteredSkill, setEnteredSkill] = useState("");
  const [loadSpinner, setloadSpinner] = useState(false);
  const [GptData, setGptData] = useState();
  const [DisableAiBtn, setDisableAiBtn] = useState(true);
  const [CurrSkill, setCurrSkill] = useState();

  const AiCredits = useSelector((item) => item?.user?.user?.aicredits);
  const [PremiumFeature, setPremiumFeature] = useState(false);

  const handlePremiumFeatureClose = () => {
    setPremiumFeature(false);
  };
  const PostChatGPTData = async () => {
    if (AiCredits < 2) {
      setPremiumFeature(true);
      return;
    }
    if (!!CurrSkill) {
      setloadSpinner(true);
      const temperature = Math.random() * (0.4 - 0.1) + 0.1;
      const payload = {
        question: `Give an array of 10 ATS compliant skills related to ${CurrSkill} without numbering and seperated by comma. Ensure it's concise & grammatically correct`,
        temperature: temperature.toFixed(1), // Limit to one decimal placePremiumFeature
      };
      try {
        const data = await ChatGPTAPI(AiCredits, payload);
        setSkillDataEntered(
          data?.data?.text?.split(",")?.map((item) => item.replaceAll("\n", ""))
        );
        dispatch(
          setSkillChatGpt(
            data?.data?.text
              ?.split(",")
              ?.map((item) => item.replaceAll("\n", ""))
          )
        );

        const aiCreditPayload = {
          credit: 3,
          used: "Skills",
        };
        toast.success("Your 3 AI credits has been used.");
        setloadSpinner(false);
        dispatch(setAiCredits(aiCreditPayload?.credit));
        await CutAiCredits(aiCreditPayload);
      } catch (error) {
        setloadSpinner(false);
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (!!CurrSkill) {
      setDisableAiBtn(false);
    } else {
      setDisableAiBtn(true);
    }
  }, [CurrSkill]);

  //skills entered
  const [SkillDataEntered, setSkillDataEntered] = useState([]);

  useEffect(() => {
    if (ResumeData?.SkillChatGpt?.length > 0) {
      setSkillDataEntered(ResumeData?.SkillChatGpt);
    }
    console.log("data", ResumeData.profession);
    setCurrSkill(ResumeData.profession);
  }, []);

  const DocType = useSelector((item) => item.types);

  const handleEnter = (e) => {
    e.preventDefault();
    if (!EnteredSkill) {
      return;
    }
    // if (ResumeData?.SkillChatGpt?.length > 0) {
    //   let data = [...ResumeData?.SkillChatGpt];
    //   setSkillDataEntered(data.unshift(EnteredSkill))
    // } else {
    //   if (SkillDataEntered?.length > 0) {
    //     setSkillDataEntered([...SkillDataEntered, EnteredSkill])
    //   } else {
    //     setSkillDataEntered([EnteredSkill])
    //   }
    // }

    setSkillDataEntered([EnteredSkill, ...SkillDataEntered]);
    dispatch(setSkillChatGpt([EnteredSkill, ...SkillDataEntered]));
    setEnteredSkill("");
  };

  const handleChange = (e) => {
    setEnteredSkill(e.target.value);
  };

  const handleSkillSelect = (e, data) => {
    e.preventDefault();
    if (!!data.name) {
      if (DocType.coverFor === "Experienced" && DocType.doctype === "cover") {
        dispatch(setCoverSkillFields(data));
        toast.success("Skill Saved Successfully");
      } else {
        dispatch(setSkillFields(data));
        toast.success("Skill Saved Successfully");
      }
      return;
    } else {
      toast.error("Please Fill Skills");
    }
  };
  const ResumeType = useSelector((item) => item.resume);

  //disable next btn
  useEffect(() => {
    if (SkillData?.length > 0) {
      dispatch(setdisableNext({ type: "skill", value: false }));
    } else {
      dispatch(setdisableNext({ type: "skill", value: true }));
    }
  }, [SkillData]);
  return (
    <div className="create-resume-main">
      <div className="d-flex justify-content-between">
        <div className="d-flex w-100 justify-content-between align-items-center my-1">
          <h3>Skills </h3>
          <div>
            <button
              onClick={handleResponsivePreviewShow}
              className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
            >
              Preview
            </button>
          </div>
        </div>
      </div>

      <div className="text-end pe-2 mb-2">
        <small>
          {DocType.doctype === "resume"
            ? !!ResumeType?.profession
              ? "Job Specific"
              : "Generic"
            : ""}
        </small>
      </div>

      <p>Search your skills for the desired job role</p>
      <Form className="form-main">
        <div className="row gx-3">
          <div className="col-lg-12">
            <FloatingLabel
              controlId="floatingPassword"
              label="Desired Job Role (e.g. Consultant,  Sales Executive)"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="start"
                value={CurrSkill}
                onChange={(e) => setCurrSkill(e.target.value)}
                placeholder="Type a Skill "
              />
            </FloatingLabel>
          </div>
        </div>

        <div className="add-skils-container">
          <div className="add-skils-heading">
            <p className="add-skills-heading-p">
              Select your skills from the suggestions given by AI below
            </p>
            <AiButton disabled={DisableAiBtn} SetAi={PostChatGPTData} />
          </div>
          {loadSpinner ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <div className="add-skils-skillContainer">
              {SkillDataEntered?.map((val, index) => {
                return (
                  <span
                    style={index === activeSkillDropDown ? selectedStyle : {}}
                    onClick={(e) => {
                      //   activeSkillDropDown === index
                      //     ? setActiveSkillDropDown("x")
                      //     : setActiveSkillDropDown(index);
                      //   setActiveDifficultyDropDown(false);
                      // }}
                      handleSkillSelect(e, {
                        name: val,
                        level: "Advance",
                        id: SkillData?.length,
                      });
                    }}
                  >
                    {val.replace(",", "")}
                    {activeSkillDropDown === index && (
                      <div className="skills-dropdownMenu-container">
                        <div className="difficulty-container">
                          <h5
                            onClick={(e) => {
                              setActiveDifficultyDropDown(
                                !activeDifficultyDropDown
                              );
                              e.stopPropagation();
                            }}
                            style={
                              activeDifficultyDropDown
                                ? {
                                    borderBottomLeftRadius: "0",
                                    borderBottomRightRadius: "0",
                                  }
                                : {}
                            }
                          >
                            Difficulty Level
                          </h5>

                          {activeDifficultyDropDown && (
                            <div
                              className="difficulty-dropdownMenu-container"
                              style={
                                activeDifficultyDropDown
                                  ? {
                                      borderTopLeftRadius: "0",
                                      borderTopRightRadius: "0",
                                    }
                                  : {}
                              }
                            >
                              <p
                                onClick={() =>
                                  handleSkillSelect({
                                    name: val,
                                    level: "Beginner",
                                    id: SkillData?.length,
                                  })
                                }
                              >
                                Beginner
                              </p>
                              <p
                                onClick={() =>
                                  handleSkillSelect({
                                    name: val,
                                    level: "Intermediate",
                                    id: SkillData?.length,
                                  })
                                }
                              >
                                Intermediate
                              </p>
                              <p
                                onClick={() =>
                                  handleSkillSelect({
                                    name: val,
                                    level: "Advance",
                                    id: SkillData?.length,
                                  })
                                }
                              >
                                Advance
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between flex-lg-row flex-column align-items-center skillAdd col-12 mb-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Enter skills manually"
            className=" col-lg-9 col-12 me-lg-4 me-0"
          >
            <Form.Control
              value={EnteredSkill}
              onChange={handleChange}
              type="text"
              placeholder="Others"
            />
          </FloatingLabel>
          <button
            className="mt-lg-0 mt-3"
            onClick={(e) =>
              handleSkillSelect(e, {
                name: EnteredSkill,
                level: "Advance",
                id: SkillData?.length,
              })
            }
          >
            Add Skill
          </button>
        </div>

        {/* <button className="save-experience" type="button" >Save This Skill List</button> */}
      </Form>
      <GptModal
        PremiumFeature={PremiumFeature}
        handlePremiumFeatureClose={handlePremiumFeatureClose}
      />
    </div>
  );
};
