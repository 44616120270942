import RT1non from "components/ResumeTemplate/T1/RT1non";
import RT2non from "components/ResumeTemplate/T2/RT2non";
import RT3non from "components/ResumeTemplate/T3/RT3non";
import RT4non from "components/ResumeTemplate/T4/RT4non";

import RT4editable from "components/ResumeTemplate/T1/RT1editable";
import RT7editable from "components/ResumeTemplate/T2/RT2editable";
import RT5editable from "components/ResumeTemplate/T3/RT3editable";
import RT6editable from "components/ResumeTemplate/T4/RT4editable";
import RT8editable from "components/ResumeTemplate/T5/RT5editable";
import RT9editable from "components/ResumeTemplate/T6/RT6editable";
import {
  setCreateResume,
  UpdateContactRedux,
  setTemplate,
  setContactRedux,
} from "helper/redux/Slice/ResumeSlice";
import Cookies from "js-cookie";
import { setTemplateNo } from "helper/redux/Slice/UtilsSlice";
import { GetTemplateNoByCollegeId } from "helper/api";
import { useNavigate } from "react-router";

import ResumeCard from "New Ui/Common/Cards/ResumeCard";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import ResumeTemplateJson from "../../../contants/ResumeTemplateJson";
import MainLoader from "New Ui/Common/Loader/MainLoader";
import RT2editable from "components/ResumeTemplate/T2/RT2editable";
import RT1editable from "components/ResumeTemplate/T1/RT1editable";

function SelectTemplate({ handleResumeStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingTemplate, setloadingTemplate] = useState(true);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const OtherTemplates = [
    { templateNo: 0, component: <RT1non />, templateEdit: <RT1editable /> },
    { templateNo: 1, component: <RT2non />, templateEdit: <RT2editable /> },
  ];
  let authData;

  if (!!localStorage.getItem("user2")) {
    authData = JSON.parse(localStorage.getItem("user2"));
  } else {
    authData = JSON.parse(Cookies.get("user"));
  }
  const collegeid = authData.collegeid._id;

  const handleSelectedTemplate = (templateNo, component) => {
    dispatch(setCreateResume({ type: "template", value: templateNo }));
    dispatch(UpdateContactRedux({ type: "email", value: authData?.email }));
    dispatch(
      UpdateContactRedux({ type: "number", value: authData?.phoneNumber })
    );
    const nameParts = authData?.name?.trim().split(" ") || [];
    const firstName = nameParts.slice(0, -1).join(" ") || nameParts[0] || ""; // Everything except last word
    const lastName = nameParts[nameParts.length - 1] || ""; // Last word

    dispatch(UpdateContactRedux({ type: "firstName", value: firstName }));
    dispatch(UpdateContactRedux({ type: "lastName", value: lastName }));
    dispatch(setTemplate({ component })); // Dispatch the selected template component
    handleResumeStep(3); // Move to the next step
  };
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setloadingTemplate(true);
        // const response = await axios.get(`/api/user-templates/${collegeid}`);
        const response = await GetTemplateNoByCollegeId(collegeid);
        const { templateNos } = response.data;
        const matchedTemplates = ResumeTemplateJson.filter((template) =>
          templateNos.includes(template.templateNo)
        );
        setFilteredTemplates(matchedTemplates);
        setloadingTemplate(false);
      } catch (error) {
        console.error("Error fetching college data:", error);
        setloadingTemplate(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleTemplateSelection = (templateNo) => {
    dispatch(setCreateResume({ type: "template", value: templateNo }));
  };
  return (
    <div className=" container-linerbg py-10 ">
      <div className="container   ">
        {loadingTemplate ? (
          <MainLoader text="Getting templates..." />
        ) : (
          // <div className="d-flex align-items-center justify-content-between h-100 w-100">
          // </div>
          <>
            <div className="">
              <h3 className="text-center text-4xl primary-text font-bold">
                Templates
              </h3>
              <h5 className="my-3 primary-text text-2xl font-semibold">
                College Template
              </h5>
              <div className="d-flex justify-start gap-5 flex-wrap">
                {filteredTemplates.map((item, index) => (
                  <ResumeCard
                    key={index}
                    component={item.template}
                    data={item}
                    handleSelectedTemplate={() =>
                      handleSelectedTemplate(item.templateNo, item.templateEdit)
                    }
                  />
                ))}
              </div>
            </div>
            <div className="mt-10">
              <h5 className="primary-text my-3 text-2xl font-semibold">
                Other Template
              </h5>
              <div className="d-flex justify-start flex-wrap gap-5">
                {OtherTemplates.map((item, index) => (
                  <ResumeCard
                    key={index}
                    component={item.component}
                    data={item}
                    handleSelectedTemplate={() =>
                      handleSelectedTemplate(item.templateNo, item.templateEdit)
                    }
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SelectTemplate;
