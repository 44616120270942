import { setCertificateFields } from "helper/redux/Slice/ResumeSlice";
import BasicDate from "New Ui/Common/Input Fields/BasicDate";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import BasicTextArea from "New Ui/Common/Input Fields/BasicTextArea";
import React, { useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

function Certificate({ certificateArr, setcertificateArr }) {
  const dispatch = useDispatch();
  const certificateReduxData = useSelector(
    (item) => item?.resume?.certification
  );
  const [submitAttempted, setsubmitAttempted] = useState(false);

  const handleAddCertificate = () => {
    setsubmitAttempted(true);
    const lastCeritificate = certificateArr[certificateArr.length - 1];
    const errors = validateCertificate(lastCeritificate);
    if (Object.keys(errors).length === 0) {
      const newCertificate = {
        name: "",
        org: "",
        date: "",
      };
      setcertificateArr([...certificateArr, newCertificate]);
      dispatch(setCertificateFields(certificateArr));
      toast.success("New Certificate added successfully!");
      setsubmitAttempted(false);
    } else {
      toast.error("Please fill Title");
    }
  };
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newArr = [...certificateArr];

    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    newArr[index] = { ...newArr[index], [name]: capitalizedValue };
    setcertificateArr(newArr);
  };

  const handleRemoveCertificate = (index) => {
    const newArr = [...certificateArr];
    newArr.splice(index, 1);
    setcertificateArr(newArr);
    if (index === 0) {
      setcertificateArr([
        {
          name: "",
          org: "",
          date: "",
        },
      ]);
    }
  };
  const handleDateChange = (index, name, value) => {
    const newArr = [...certificateArr];
    newArr[index] = { ...newArr[index], [name]: value };
    setcertificateArr(newArr);
  };

  const validateCertificate = (certificate) => {
    const errors = {};
    if (!certificate.name) {
      errors.name = "Name is required";
    }
    // Add more validations as needed for other fields

    return errors;
  };
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setdeleteIndex] = useState(null);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  return (
    <>
      <Accordion.Item
        eventKey={1}
        className="mb-2  rounded w-100"
        style={{ border: "1px solid #602a94" }}
      >
        <Accordion.Header>
          <div className="d-flex justify-content-between w-100 pe-4">
            <span> Certificate</span>
            <div></div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ visibility: "visible" }}>
          <div>
            {certificateArr?.map((item, index) => {
              return (
                <>
                  <div className="d-flex mb-4">
                    <div
                      className="primary-border w-100 px-4 py-3 rounded"
                      style={{ borderWidth: "1px" }}
                    >
                      <div className="d-flex gap-5 ">
                        <BasicInput
                          name="name"
                          label="Title"
                          handleChange={handleChange}
                          value={item?.name}
                          placeholder="Write title"
                          required={true}
                          index={index}
                          submitAttempted={submitAttempted}
                        />
                      </div>
                      <div className="flex-row-responsive ">
                        <BasicInput
                          name="org"
                          index={index}
                          label="Issued By"
                          handleChange={handleChange}
                          value={item?.org}
                          placeholder="Issued by"
                        />
                        <BasicDate
                          name="date"
                          label="Issued On"
                          index={index}
                          data={item?.date}
                          handleChange={handleDateChange}
                        />
                      </div>
                    </div>
                    <RiDeleteBin5Line
                      onClick={() => {
                        handleShowDelete();
                        setdeleteIndex(index);
                      }}
                      fontSize={30}
                      className="secondary-text align-self-start  ms-3 cursor-pointer"
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="d-flex justify-content-end pe-5">
            <button
              className="primary-btn rounded px-3 p-2 mt-1 d-flex gap-1 justify-content-center align-items-center"
              onClick={handleAddCertificate}
            >
              <IoIosAdd fontSize={24} />
              Certificate
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        centered
        className="rounded"
      >
        <Modal.Header style={{ background: "#f0ddff" }} closeButton>
          <h4 className="text-lg">Are you sure?</h4>
        </Modal.Header>
        <Modal.Body>Do you want to delete this Certificate?</Modal.Body>
        <Modal.Footer>
          <button
            className="primary-btn px-3 p-2 rounded"
            onClick={handleCloseDelete}
          >
            Cancel
          </button>
          <button
            className="primary-btn  px-3 p-2 rounded"
            onClick={() => {
              toast.error("Certificate Deleted !");
              handleRemoveCertificate(deleteIndex);
              handleCloseDelete();
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Certificate;
