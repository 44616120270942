import React from "react";
import CT1non from "components/CoverLetterTemplate/CT1/CT1non";
import CT2non from "components/CoverLetterTemplate/CT2/CT2non";
import CT1editable from "components/CoverLetterTemplate/CT1/CT1editable";
import CT2editable from "components/CoverLetterTemplate/CT2/CT2editable";
const CoverLetterTemplateJson = [
  {
    templateNo: 0,
    template: CT1non,
    templateEdit: CT1editable,
  },
  // {
  //   templateNo: 1,
  //   template: CT2non,
  //   templateEdit: CT2editable
  // },
];
export default CoverLetterTemplateJson;
