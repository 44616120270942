import axios from "axios";
import {
  setContactRedux,
  setIsResumeEdit,
} from "helper/redux/Slice/ResumeSlice";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import UserSidebar from "New Ui/Common/UserSidebar";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import "../commoncss.css";

function Contact() {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const ContactReduxData = useSelector((item) => item?.resume?.contact);
  const ResumeReduxData = useSelector((item) => item?.resume);
  const [submitAttempted, setsubmitAttempted] = useState(false);
  const [contactObj, setcontactObj] = useState({
    picture: "",
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    country: "",
    number: "",
  });
  const [uploadState, setUploadState] = useState({
    status: false,
    percent: 0,
  });
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setcontactObj({ ...contactObj, [name]: value });
    } else {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setcontactObj({ ...contactObj, [name]: capitalizedValue });
    }
  };
  const handleLocalchange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setcontactObj({ ...contactObj, [name]: capitalizedValue });
  };
  const handlefileRef = () => {
    fileRef.current.click();
  };
  const handleUploadPicture = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert bytes to MB

      if (!fileType.startsWith("image/")) {
        alert("Please upload a valid image file.");
        return;
      }
      if (fileSize > 5) {
        alert("File size should be less than 5MB.");
        return;
      }

      try {
        const formData = new FormData();
        formData.append("file", file);
        setUploadState({ ...uploadState, status: true });

        const rootUrl =
          process.env.NODE_ENV === "production"
            ? "https://uploader.viosa.in/api"
            : "https://uploader.viosa.in/api";

        const response = await axios.post(
          `${rootUrl}/v2/upload/other`,
          formData,
          {
            onUploadProgress: (progress) => {
              const percentComplete = Math.round(
                (progress.loaded * 100) / progress.total
              );
              setUploadState({ status: true, percent: percentComplete });
            },
          }
        );
        toast.success("Photo Uploaded", {
          duration: 5000, // Display the toast for 4 seconds
        });
        setcontactObj((prev) => ({ ...prev, picture: response.data.link }));
        setUploadState({ status: false, percent: 100 });
      } catch (error) {
        console.error(error);
        alert("Upload failed. Try again.");
        setUploadState({ status: false, percent: 0 });
      }
    }
  };
  const regex = /\S+@\S+\.\S+/;
  console.log(!regex.test(contactObj.email));
  const handleDeletePicture = () => {
    setcontactObj({ ...contactObj, picture: "" });
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value)
          return `${name === "firstName" ? "First Name" : "Last Name"
            }  is required`;
        break;
      case "number":
        if (!value) return "Phone number is required";
        if (!/^\d{10}$/.test(value)) return "Phone number must be 10 digits";
        break;
      case "email":
        if (!value) return "Email is required";
        if (!regex.test(value)) return "Email address is invalid";
        break;
      default:
        return "";
    }
    return "";
  };

  const handleSubmitAndNext = () => {
    setsubmitAttempted(true);
    const newErrors = {};
    const fieldOrder = [
      "title",
      "firstName",
      "lastName",
      "number",
      "email",
      "city",
      "country",
    ];
    for (let field of fieldOrder) {
      const error = validateField(field, contactObj[field]);
      if (error) {
        toast.error(error, {
          duration: 5000, // Display the toast for 4 seconds
        });
        return; // Stop checking after the first error
      }
    }
    // If no errors, proceed to the next step
    const data = {
      picture: contactObj?.picture,
      firstName: contactObj?.firstName,
      lastName: contactObj?.lastName,
      number: contactObj?.number,
      email: contactObj?.email,
      title: contactObj?.title,
      city: contactObj?.city,
      country: contactObj?.country,
    };

    toast.success("Contact Saved", {
      duration: 5000, // Display the toast for 4 seconds
    });
    dispatch(setContactRedux(data));
    if (ResumeReduxData?.isResumeEdit) {
      dispatch(setResumeStep(9));
      dispatch(setIsResumeEdit(false));
    } else {
      dispatch(setResumeStep(2));
    }
  };

  // useEffect
  useEffect(() => {
    setcontactObj(ContactReduxData);
  }, []);

  return (
    <>
      <h1 className="primary-text text-3xl font-semibold mt-4">Contact Info</h1>

      <div className="my-5 d-flex step-content-main flex-column  w-100 gap-x-14  ">
        <div className="w-100 d-flex gap-5 mb-4">
          <div className="d-flex flex-column align-self-end  w-100">
            <small className="text-sm primary-text mb-2">
              {!!contactObj?.picture && typeof contactObj.picture === "string"
                ? "Update your profile photo"
                : "Add a photo to your resume "}
            </small>
            <button
              className="primary-btn p-2 rounded d-flex align-items-center justify-content-center"
              onClick={handlefileRef}
            >
              {!!contactObj?.picture &&
                typeof contactObj.picture === "string" ? (
                <span className="me-2 text-base font-semibold">
                  Update Photo
                </span>
              ) : (
                <span className="me-2 text-base font-semibold">Add Photo</span>
              )}
              {uploadState?.status && (
                <Spinner size="sm" variant="white" className="" />
              )}
            </button>
            <input
              onChange={handleUploadPicture}
              type="file"
              ref={fileRef}
              className="d-none"
            />
          </div>
          <div className="w-100">
            {!!contactObj?.picture ? (
              typeof contactObj?.picture === "object" ? (
                <div>
                  <img
                    src={URL.createObjectURL(contactObj?.picture)}
                    alt=""
                    className="contact-picture rounded"
                  />
                </div>
              ) : (
                <div className="d-flex">
                  <img
                    src={contactObj?.picture}
                    alt=""
                    className="contact-picture rounded"
                  />
                  <RiDeleteBin5Line
                    fontSize={24}
                    onClick={handleDeletePicture}
                    className="secondary-text ms-3"
                  />
                </div>
              )
            ) : (
              <div
                className="contact-picture p-4 rounded"
                style={{ background: "#ebebeb" }}
              >
                <MdOutlinePhotoCameraFront
                  fontSize={46}
                  className="primary-text"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-row-responsive">
          <div className="flex-column-responsive ">
            {/* <div className="form-input w-lg-1/2 w-100 ">
              <label for="first_name" className="text-sm ">
                Title
              </label>
              <select
                name="title"
                className="w-100"
                onChange={handleLocalchange}
                value={contactObj?.title}
              >
                <option value="">Select </option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs">Mrs.</option>
                <option value="Ms">Ms.</option>
                <option value="Dr.">Dr.</option>
                <option value="Miss">Miss</option>
                <option value="Prof.">Prof.</option>
              </select>
            </div> */}
            {/* <div className="form-input">
              <label for="first_name" className="text-sm ">
                First Name
              </label>
              <input
                type="text"
                className="w-100"
                id="first_name"
                name="firstName"
                onChange={handleLocalchange}
                placeholder="John"
                value={contactObj?.firstName}
              />
            </div> */}
            <div className="w-100 mt-3">
              <BasicInput
                name="firstName"
                label="First Name"
                type="text"
                required={true}
                handleChange={handleChange}
                value={contactObj?.firstName}
                placeholder="John"
                submitAttempted={submitAttempted}
              />
            </div>
          </div>
          <div className="w-100 mt-3">
            <BasicInput
              name="lastName"
              label="Last Name"
              type="text"
              handleChange={handleChange}
              value={contactObj?.lastName}
              required={true}
              placeholder="Doe"
              submitAttempted={submitAttempted}
            />
          </div>
        </div>
        <div className="flex-row-responsive">
          <BasicInput
            name="number"
            label="Phone"
            type="number"
            placeholder="9898989898"
            required={true}
            handleChange={handleChange}
            submitAttempted={submitAttempted}
            value={contactObj?.number}
          />
          <BasicInput
            name="email"
            label="Email"
            type={"email"}
            placeholder="xyz@gmail.com"
            handleChange={handleChange}
            submitAttempted={submitAttempted}
            required={true}
            value={contactObj?.email}
          />
        </div>
        <div className="flex-row-responsive">
          <BasicInput
            name="city"
            label="City"
            placeholder="Noida"
            handleChange={handleChange}
            type={"text"}
            value={contactObj?.city}
          />
          <BasicInput
            name="country"
            label="Country"
            type={"text"}
            placeholder="India"
            handleChange={handleChange}
            value={contactObj?.country}
          />
        </div>
      </div>
      <div className=" d-flex  step-content-footer justify-content-end w-100 pb-4">
        <button
          className="primary-btn rounded px-3 p-2 "
          onClick={() => {
            // dispatch(setResumeStep(2));
            handleSubmitAndNext();
          }}
        >
          Save & Next
        </button>
      </div>
    </>
  );
}

export default Contact;
