import { setAllLinks, setHobbyFields } from "helper/redux/Slice/ResumeSlice";
import BasicInput from "New Ui/Common/Input Fields/BasicInput";
import BasicSelect from "New Ui/Common/Input Fields/BasicSelect";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoIosAdd } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch } from "react-redux";

function Hobby({ hobbyArr, sethobbyArr }) {
  const dispatch = useDispatch();
  const [hobby, sethobby] = useState("");
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    sethobby(capitalizedValue);
  };

  const addHobby = () => {
    if (hobby && !hobbyArr.includes(hobby)) {
      sethobbyArr((prev) => [...prev, hobby]);
      sethobby(""); // Reset the input after adding
      dispatch(setHobbyFields(hobbyArr));
      toast.success("Hobby added ");
    }
  };
  const handleKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addHobby(); // Call the addLanguage function
    }
  };
  const handelDeleteHobby = (index) => {
    sethobbyArr(hobbyArr.filter((item, currIndex) => currIndex !== index));
  };
  return (
    <>
      <Accordion.Item
        eventKey={6}
        className="mb-2  rounded w-100"
        style={{ border: "1px solid #602a94" }}
      >
        <Accordion.Header>
          <div className="d-flex justify-content-between w-100 pe-4">
            <span> Hobby</span>
            <div></div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{ visibility: "visible" }}>
          <div className="d-flex  flex-column ">
            <div className="d-flex flex-wrap gap-2 mb-3">
              {hobbyArr?.map((item, index) => {
                return (
                  <button
                    key={index}
                    className="skill-addBtn d-flex align-items-center  rounded px-2 p-1"
                  >
                    <small>{item}</small>
                    <TiDeleteOutline
                      fontSize={20}
                      className="ms-2"
                      onClick={() => handelDeleteHobby(index)}
                    />
                  </button>
                );
              })}
            </div>
            <BasicInput
              name="hobby"
              label="Hobby"
              value={hobby}
              handleChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Eg. Coding, Cricket "
            />
            <small>Note : Press enter or , to add hobby</small>

            <div className="d-flex justify-content-end ">
              <button
                className="primary-btn rounded px-3 p-2 mt-1 d-flex gap-1 justify-content-center align-items-center"
                onClick={addHobby}
              >
                <IoIosAdd fontSize={24} />
                Hobby
              </button>
            </div>
          </div>
        </Accordion.Body>{" "}
      </Accordion.Item>
    </>
  );
}

export default Hobby;
