import React, { useState } from "react";
import {
  MdAssignment,
  MdOutlineContactPage,
  MdOutlineSchool,
  MdWorkOutline,
} from "react-icons/md";
import "./usersidebar.css";
import { FaTools } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";

import { SiHyperskill } from "react-icons/si";
import { MdOutlineAssignment } from "react-icons/md";
import { GrVulnerability } from "react-icons/gr";
import { CiCircleMore } from "react-icons/ci";
import { MdAddchart } from "react-icons/md";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { AiOutlineAim } from "react-icons/ai";
import logo from "../../assets/image/logo.svg";
import logoSmall from "../../assets/image/logo-small.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineMenuOpen } from "react-icons/md";
import { useNavigate } from "react-router";
import { RiMenuFold4Line } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setResumeStep } from "helper/redux/Slice/UtilsSlice";
import { RiPhoneFindLine } from "react-icons/ri";

function UserSidebar() {
  const stepResume = useSelector((item) => item.utils.stepResume);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setsidebarOpen] = useState(true);

  const steps = [
    {
      id: "contact",
      icon: <MdOutlineContactPage fontSize={22} />,
      name: "Contact",
      step: 1,
    },
    {
      id: "experience",
      icon: <MdWorkOutline fontSize={22} />,
      name: "Experience",
      step: 2,
    },
    {
      id: "education",
      icon: <MdOutlineSchool fontSize={22} />,
      name: "Education",
      step: 3,
    },
    {
      id: "project",
      icon: <MdOutlineAssignment fontSize={22} />,
      name: "Project",
      step: 4,
    },
    {
      id: "activity",
      icon: <FiActivity fontSize={22} />,
      name: "Activity",
      step: 5,
    },
    {
      id: "skill",
      icon: <AiOutlineAim fontSize={22} />,
      name: "Skill",
      step: 6,
    },

    {
      id: "other",
      icon: <MdAddchart fontSize={22} />,
      name: "Others",
      step: 7,
    },
    {
      id: "jobSpecific",
      icon: <RiPhoneFindLine fontSize={22} />,
      name: "Job Specific",
      step: 8,
    },
    {
      id: "review",
      icon: <MdOutlineAssignmentTurnedIn fontSize={22} />,
      name: "Review",
      step: 9,
    },
  ];
  const handelSidebarOpen = () => {
    sidebarOpen ? setsidebarOpen(false) : setsidebarOpen(true);
  };

  const handleActiveStep = (step) => {
    dispatch(setResumeStep(step));
  };
  return (
    <nav
      className={`  ${
        sidebarOpen ? "w-60" : "w-24"
      }  nav-wrapper position-relative desktopview`}
    >
      <div
        onClick={() => navigate("/")}
        className={`py-3 px-3 primary-bg logo-container d-flex cursor-pointer ${
          sidebarOpen ? "justify-center" : "justify-center"
        }  align-items-center`}
      >
        {sidebarOpen ? (
          <img src={logo} alt="logo" />
        ) : (
          <img src={logoSmall} alt="logo" />
        )}
      </div>
      <div className="resume-steps-nav p-4">
        <div
          className="sidebar-open-btn"
          style={{ right: sidebarOpen ? "-13%" : "-28%" }}
          onClick={handelSidebarOpen}
        >
          {sidebarOpen ? (
            <MdArrowBackIosNew fontSize={22} />
          ) : (
            <MdArrowForwardIos fontSize={22} />
          )}
        </div>
        <div className="d-flex  align-items-center flex-column gap-y-7">
          {steps?.map((item, index) => {
            return (
              <>
                <div
                  key={item.id}
                  className="Common-sidebar-progress d-flex gap-4 w-100 align-items-center"
                  onClick={() => handleActiveStep(index + 1)}
                >
                  <div
                    className={`Common-sidebar-circles d-flex justify-content-center align-items-center ${
                      stepResume >= item?.step ? "activeStep" : ""
                    }`}
                  >
                    {index < steps?.length - 1 && (
                      <span
                        className={`Common-sidebar-line ${
                          stepResume >= item?.step ? "activeStep" : ""
                        }`}
                      ></span>
                    )}

                    {item.icon}
                  </div>
                  {sidebarOpen && (
                    <h5
                      className={` font-semibold ${
                        stepResume >= item?.step
                          ? "activeStepName"
                          : "activeStepName"
                      }`}
                    >
                      {item?.name}
                    </h5>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </nav>
  );
}

export default UserSidebar;
