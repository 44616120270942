import React from "react";
import { useSelector } from "react-redux";

const UseContactInfo = ({ data }) => {
  let contactValue = 0;
  let ResumeRedux;
  const resumeData = useSelector((state) => state.resume);
  if (!!data) {
    ResumeRedux = data;
  } else {
    ResumeRedux = resumeData;
  }
  const CoverRedux = useSelector((state) => state.cover);
  const types = useSelector((state) => state.types);

  if (types?.doctype === "resume") {
    if (!!ResumeRedux?.contact?.firstName) {
      contactValue += 12.5;
    }
    if (!!ResumeRedux?.contact?.lastName) {
      contactValue += 12.5;
    }
    if (!!ResumeRedux?.contact?.email) {
      contactValue += 25;
    }
    if (!!ResumeRedux?.contact?.number) {
      contactValue += 25;
    }
    if (!!ResumeRedux?.contact?.country) {
      contactValue += 12.5;
    }
    if (!!ResumeRedux?.contact?.city) {
      contactValue += 12.5;
    }
  } else if (types?.doctype === "cover") {
    if (!!CoverRedux?.to?.name) {
      contactValue += 20;
    }
    if (!!CoverRedux?.to?.position) {
      contactValue += 20;
    }
    if (!!CoverRedux?.to?.address) {
      contactValue += 20;
    }
    if (!!CoverRedux?.to?.email) {
      contactValue += 20;
    }
    if (!!CoverRedux?.to?.companyName) {
      contactValue += 20;
    }
  }
  return contactValue;
};

export default UseContactInfo;
