import axios from "axios";
import { AiButton } from "components/AiButton/AiButton";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";

// const API = axios.create({
//   baseURL: "https://api.viosa.in/resumatic",
//   // baseURL: "https://viosa-resume-backend.onrender.com",
//   // baseURL: "https://localhost:4000",
//   // baseURL: "http://localhost:3200/resumatic",
// });
const API = axios.create({
  // baseURL: "https://resumatic-api.viosa.in",
  // baseURL: "https://api.viosa.in/resumatic",
  baseURL: process.env.REACT_APP_API_URL_RESUMATIC,
});

API.interceptors.request.use((req) => {
  if (Cookies.get("token")) {
    req.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
  }
  return req;
});

const BasicAPI = axios.create({
  // baseURL: "https://api-staging.viosa.in",
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:3200",
});

export const LoginAPI = (formData) => API.post("/auth/login", formData);
export const SignUpAPI = (formData) => API.post("/auth/signup", formData);
export const getProfile = (id) => API.get(`/user/getProfile/${id}`);
export const updateProfile = (id, formData) =>
  API.put(`/user/edit/${id}`, formData);
export const changePassword = (id, formData) =>
  API.patch(`/user/newpassword/${id}`, formData);

//CRUD resume
export const GetTrailResume = () => API.get(`/resume/trail/`);
export const GetAiResume = () => API.get(`/resume/ai/`);
export const GetAutomatedResume = () => API.get(`/resume/automated/`);
export const GetTrailResumeById = (id) => API.get(`/resume/trail/${id}`);
export const GetAiResumeById = (id) => API.get(`/resume/ai/${id}`);
export const GetAutomatedResumeById = (id) =>
  API.get(`/resume/automated/${id}`);

//posting
export const PostTrailResume = (data) => API.post(`/resume/trail/`, data);
export const PostAutomatedResume = (data) =>
  API.post(`/resume/automated/`, data);
export const PostAiResume = (data) => API.post(`/resume/ai/`, data);

//updating
export const UpdateTrailResume = (data) => API.patch(`/resume/trail/`, data);
export const UpdateAutomatedResume = (data) =>
  API.patch(`/resume/automated/`, data);
export const UpdateAiResume = (data) => API.patch(`/resume/ai/`, data);

// delete
export const DeleteTrailResume = (data) =>
  API.delete(`/resume/trail?resumeId=${data?.id}`);
export const DeleteAiResume = (data) =>
  API.delete(`/resume/ai?resumeId=${data?.id}`);
export const DeleteAutomatedResume = (data) =>
  API.delete(`/resume/automated?resumeId=${data?.id}`);
// export const DeleteResume = (data) => API.delete(`/resume/trail/`, data)

//cover letter
export const UpdateFresherCoverLetter = (data) =>
  API.patch(`/cover/fresher/`, data);
export const UpdateExperienceCoverLetter = (data) =>
  API.patch(`/cover/exp/`, data);

export const DeleteFresherCoverLetter = (data) =>
  API.delete(`/cover/fresher?coverId=${data?.coverId}`);
export const DeleteExperienceCoverLetter = (data) =>
  API.delete(`/cover/exp?coverId=${data?.coverId}`);
export const GetCoverLetter = () => API.get(`/cover/exp/`);

export const GetExperienceCoverLetterById = (id) => API.get(`/cover/exp/${id}`);
export const GetFresherCoverLetterById = (id) =>
  API.get(`/cover/fresher/${id}`);

export const PostCoverLetterFresher = (data) =>
  API.post(`/cover/fresher/`, data);
export const PostCoverLetterExperience = (data) =>
  API.post(`/cover/exp/`, data);
// export const ChatGPTAPI = (data) => axios.post(`https://chatgpt3-oav0.onrender.com/chat`, data)

export const ChatGPTAPI = (payload) => BasicAPI.post(`/chat`, payload);

export const GetPracticeVideos = () => API.get(`/pract/practices`);
export const GetWishListVideos = () => API.get(`/pract/favpractices`);
export const GetPracticeVideosById = (id) => API.get(`/pract/practices/${id}`);
export const PostWishListVideos = (data) =>
  API.post(`/pract/favpractices`, data);
export const DeleteWishListVideos = (data) =>
  API.post(`/pract/removepractices`, data);

//razorpay
export const GetAllPlan = () => API.get(`/pay/getPlans`);

export const CreateOrderId = (data) => API.post(`/pay/createOrderId`, data);
export const PostOrderId = (data) => API.post(`/pay/purchaseMembership`, data);

///credits
export const GetAllCreditLogs = () => API.get(`/user/getAILogs`);
export const CutAiCredits = (data) => API.post(`/user/cutAICredits`, data);

//templates
export const GetTemplateNoByCollegeId = (collegeId) =>
  API.get(`/resume/user-templates/${collegeId}`);
export const generatePDF = async (data) => {
  return API.post(`/pdf/generate-pdf`, data);
};
