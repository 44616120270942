import React from "react";

function ResumeOrCoverLetter({ handleResumeStep }) {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-purple-100 container-linerbg">
        <h1 className=" font-semibold text-5xl mb-8 primary-text">
          What do you want to create?
        </h1>
        <div className="flex gap-10 mt-4">
          <button
            className=" primary-btn  font-semibold text-xl px-20 py-3 rounded"
            onClick={() => handleResumeStep(2)}
          >
            Resume
          </button>
          <button
            className=" primary-btn font-semibold text-xl  px-20 py-3 rounded"
            onClick={() => handleResumeStep(2)}
          >
            Cover Letter
          </button>
        </div>
      </div>
    </>
  );
}

export default ResumeOrCoverLetter;
