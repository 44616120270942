import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChatGPTAPI, getProfile } from "helper/api";

export const GettingAiCredits = createAsyncThunk(
  "cover/GettingAiCredits",
  async (payload) => {
    try {
      const response = await getProfile(payload); // call your async API function
      return response.data?.data; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);

export const GettingSkillSetJobSpecific = createAsyncThunk(
  "cover/GettingSkillSetJobSpecific",
  async (payload) => {
    try {
      const payloadData = {
        question: `Please provide a list of ATS-compliant keywords suitable for the job profile of a ${payload?.profession}. Ensure the keywords are optimized to pass through Applicant Tracking Systems (ATS) effectively`,
      };
      const response = await ChatGPTAPI(payload?.aiCredit, payloadData);
      console.log(response);

      return response.data.text; // return the data from the API response
    } catch (error) {
      throw Error(error.message); // throw an error if API call fails
    }
  }
);
const initialState = {
  premium: false,
  templateNo: 0,
  stepCover: 1,
  baseStep: 1,
  stepResume: 1,
  editChatgptDescription: false,
  TemplatePreview: false,
  sidebarExpand: false,
  AICreditsLoadingState: true,
  AICredits: 0,
  finishupOption: 1,
  reviewEdit: false,
  jobSpecificSkill: [],
  jobSpecificOrGeneric: "Generic",

  disableNext: {
    contact: true,
    experience: true,
    project: true,
    education: true,
    certificate: true,
    voluntary: true,
    skill: true,
    hobby: true,
    languagesKnown: true,
    summary: true,
    finishup: false,
  },
};

const UtilsSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setdisableNext: (state, action) => {
      state.disableNext[action.payload.type] = action.payload.value;
    },
    setEditChatgptDescription: (state, action) => {
      state.editChatgptDescription = action.payload;
    },

    setPremiumFeatureRedux: (state, action) => {
      state.premium = action.payload;
    },
    setTemplatePreview: (state, action) => {
      state.TemplatePreview = action.payload;
    },
    setTemplateNo: (state, action) => {
      state.templateNo = action.payload;
    },
    setAiCredits: (state, action) => {
      state.AICredits = state.AICredits - action.payload;
    },
    setSidebarExpand: (state, action) => {
      state.sidebarExpand = action.payload;
    },
    setCoverStep: (state, action) => {
      state.stepCover = action.payload;
    },
    setBaseStep: (state, action) => {
      state.baseStep = action.payload;
    },
    setResumeStep: (state, action) => {
      state.stepResume = action.payload;
    },
    setfinishUpOption: (state, action) => {
      state.finishupOption = action.payload;
    },
    setJobSpecificSkill: (state, action) => {
      state.jobSpecificSkill = action.payload;
    },
    setjobSpecificOrGeneric: (state, action) => {
      state.jobSpecificOrGeneric = action.payload;
    },
    setReviewEdit: (state, action) => {
      state.reviewEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GettingAiCredits?.fulfilled, (state, action) => {
        state.AICredits = action?.payload?.aicredits;
        state.AICreditsLoadingState = false;
      })
      .addCase(GettingAiCredits?.rejected, (state, action) => {
        state.AICreditsLoadingState = false;
      })
      .addCase(GettingSkillSetJobSpecific?.fulfilled, (state, action) => {
        let mainSkillArray = extractSkillsWithSymbols(action?.payload);
        if (mainSkillArray?.length === 0) {
          mainSkillArray = extractSkillsWithCommas(action.payload);
        }
        console.log(mainSkillArray);
        state.jobSpecificSkill = mainSkillArray;
      });
  },
});

function extractSkillsWithSymbols(string) {
  const regex = /[•0-9]+\s?([^:\n]+)/g;
  const matches = string.match(regex);

  if (matches) {
    let skills = matches.map((match) => match.replace(/[•0-9]+\s?/, "").trim());
    skills = skills.map((item, index) =>
      item.includes(". ")
        ? item.replace(". ", "")
        : item.includes(`${index}.`)
        ? item.replace(`${index}.`, "")
        : item
    );
    return skills;
  }

  return [];
}
function extractSkillsWithCommas(string) {
  const skillsArray = string
    .replace(/(Keywords:|Development)/g, "") // Remove unnecessary keywords
    .split(/[,;]+/) // Split by comma or semicolon
    .map((skill) => skill.trim());

  return skillsArray;
}
export const {
  setdisableNext,
  setjobSpecificOrGeneric,
  setJobSpecificSkill,
  setReviewEdit,
  setCoverStep,
  setResumeStep,
  setPremiumFeatureRedux,
  setfinishUpOption,
  setTemplatePreview,
  setTemplateNo,
  setAiCredits,
  setBaseStep,
  setSidebarExpand,
  setEditChatgptDescription,
} = UtilsSlice.actions;
export default UtilsSlice.reducer;
