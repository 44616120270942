import React, { useState, useEffect, useRef } from "react";

function BasicSearchInput({
  index,
  handleChange,
  type,
  label,
  name,
  value,
  placeholder,
  onKeyDown,
  suggestions,
  handleSearchChange,
  disabled,
  submitAttempted,
  required,
}) {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const wrapperRef = useRef(null); // Ref to track clicks outside
  const isValid = !required || value.trim() !== "";

  useEffect(() => {
    // Update filtered suggestions when the input value changes
    if (value && showSuggestions) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setShowSuggestions(false);
    }
  }, [value, suggestions, showSuggestions]);

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    // Attach event listener when suggestions are shown
    if (showSuggestions) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSuggestions]);

  const handleSuggestionClick = (suggestion) => {
    handleSearchChange(index, name, suggestion); // Update the value in the parent component
    setShowSuggestions(false); // Hide suggestions after selection
  };

  const handleInputChange = (e) => {
    handleChange(index, e); // Call parent's change handler
    if (!e.target.value) {
      setShowSuggestions(false); // Hide suggestions if input is empty
    } else {
      setShowSuggestions(true); // Show suggestions if input is not empty
    }
  };

  return (
    <div className="d-flex flex-column form-input mb-3 w-100" ref={wrapperRef}>
      <label htmlFor={`input-${index}-${name}`} className="text-sm">
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <div className="position-relative">
        <input
          type={type || "text"}
          className="form-control"
          id={`input-${index}-${name}`}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={value}
          disabled={disabled}
          onKeyDown={onKeyDown}
          name={name}
          autoComplete="off"
          style={{
            border:
              submitAttempted && !isValid
                ? "1.5px solid red"
                : "1.5px solid #602a94",
          }}
        />
        {showSuggestions && (
          <ul className="list-group search-Suggestion">
            {filteredSuggestions.map((suggestion, idx) => (
              <li
                key={idx}
                className="list-group-item"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default BasicSearchInput;
