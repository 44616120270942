import { Form, FloatingLabel, Accordion } from "react-bootstrap";
import { CollapseInput } from "./CollapseInput";

export const Content = ({ handleResponsivePreviewShow, SaveAndNext }) => {
  return (
    <div className="create-resume-main">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <h3>Content</h3>
        <button
          onClick={handleResponsivePreviewShow}
          className="align-self-start p-1 responsive-preview-btn d-inline-block d-lg-none"
        >
          Preview
        </button>
      </div>
      <p>
        {/* Et pellentesque scelerisque risus blandit quam tempus. Pellentesque
        sodales nec convallis sit ac. */}
      </p>
      <CollapseInput
        SaveAndNext={SaveAndNext}
        title="Generate opening paragraph"
      />
    </div>
  );
};
