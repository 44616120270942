import React from "react";
import AiLoader from "../../../assets/image/AiLoader.svg";

function MainLoader({ text }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center h-100 "
      style={{ minHeight: "80vh" }}
    >
      <img src={AiLoader} alt="" style={{ height: "50px", width: "50px" }} />
      <p>{!!text ? text : "Generating AI Resume..."}</p>
    </div>
  );
}

export default MainLoader;
